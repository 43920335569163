import React from 'react';
import { Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from 'components';
import { Fragments } from '../../';

export enum SpeakerAvatarSize {
	LARGE,
	MEDIUM
}

export type Props = {
	speaker: Fragments.SpeakerFieldsFragment;
	size?: SpeakerAvatarSize;
};

const useStyles = makeStyles((theme) => ({
	speakerDescription: {},
	speakerAcademicName: { fontWeight: 'bold' },
	container: { display: 'flex', marginTop: theme.spacing(1) },
	details: {
		marginLeft: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center'
	},
	largeAvatar: { width: theme.spacing(8), height: theme.spacing(8) }
}));

/**
 * @deprecated use Speaker component instead
 **/
const SpeakerAvatar = ({ size, speaker }: Props) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<Avatar
				src={speaker.avatarSrcUrl ?? ''}
				className={size === SpeakerAvatarSize.LARGE ? classes.largeAvatar : ''}
			/>
			<div className={classes.details}>
				<Typography
					className={classes.speakerAcademicName}
					variant={size === SpeakerAvatarSize.LARGE ? 'h6' : 'body2'}
					title={speaker.name}
				/>
				{speaker.description && (
					<Typography
						variant={size === SpeakerAvatarSize.LARGE ? 'subtitle2' : 'caption'}
						className={classes.speakerDescription}
						title={speaker.description}
					/>
				)}
			</div>
		</div>
	);
};

export default SpeakerAvatar;
