import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		container: {
			color: theme.palette.common.white,
			backgroundColor: theme.palette.secondary.main,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: theme.spacing(1),
			borderRadius: 8
		}
	}),
	{ name: 'Countdown' }
);

export default useStyles;
