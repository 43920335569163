/**
 * TODO: arsinclair: remove this function when packages are merged.
 * @deprecated
 */
const getBasePath = (): {
	basePath: string;
	localePath: string;
} => {
	if (typeof window === 'undefined') {
		return {
			basePath: '',
			localePath: ''
		};
	}

	const pathParts = window.location.pathname.split('/');
	// for catalog package
	let localePath = pathParts[1] === 'fr-CH' ? '/' + pathParts[1] : '';
	if (localePath.length < 1) {
		// for admin
		localePath = pathParts[2] === 'fr-CH' ? '/' + pathParts[2] : '';
	}
	let basePath = '';

	if (pathParts[1] === 'admin') {
		basePath = `/${pathParts[1]}${localePath}`;
	} else if (localePath.length > 0) {
		basePath = `${localePath}`;
	}

	return {
		basePath,
		localePath
	};
};

export default getBasePath;
