import { ThemeTypes } from '../..';
import { makeStyles } from '@mui/styles';

const headerHeight = (theme: ThemeTypes.Theme) => theme.spacing(7);

const useStyles = makeStyles(
	(theme) => ({
		actionButton: {
			'&$fullscreen': {
				minWidth: 0,
				padding: theme.spacing(0)
			}
		},
		titleContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		title: {
			fontWeight: 600,
			fontSize: 20,
			lineHeight: '30px'
		},
		headerContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			padding: theme.spacing(2),
			alignItems: 'center',
			borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
			position: 'relative'
		},
		dialogTitle: { width: '100%', padding: 0 },
		dialogContent: {
			...theme.border({ radius: 12, width: 0 }),
			height: `calc(100% - ${headerHeight(theme)}px)`,
			overflowY: 'auto',
			padding: theme.spacing(2)
		},
		closeIcon: {
			width: theme.spacing(4),
			height: theme.spacing(4),
			padding: theme.spacing(0.5),
			marginRight: theme.spacing(2),
			color: theme.palette.primary.main,
			cursor: 'pointer'
		},
		dialogPaper: {
			height: '100%',
			margin: 0,
			maxHeight: 'unset',
			'& > div': {
				height: '100%',
				display: 'flex',
				flexDirection: 'column'
			},
			[theme.breakpoints.up('md')]: {
				width: '100%'
			}
		},
		dialogPaperFullHeight: {
			[theme.breakpoints.up('md')]: {
				borderRadius: 6,
				borderBottomRightRadius: 0,
				borderBottomLeftRadius: 0
			}
		},
		dialogScrollPaper: {
			[theme.breakpoints.up('md')]: {
				height: '81%',
				margin: 'auto',
				width: '80vw',
				maxWidth: theme.breakpoints.values.xl
			}
		},
		dialogScrollPaperFullHeight: {
			[theme.breakpoints.up('md')]: {
				height: '90%',
				margin: 'auto',
				marginBottom: 0
			}
		},
		dialogScrollPaperAutoHeight: {
			[theme.breakpoints.up('md')]: {
				height: 'auto',
				margin: 'auto'
			}
		},
		dialogRoot: {
			[theme.breakpoints.up('md')]: {
				display: 'flex'
			}
		},
		dialogActions: {
			[theme.breakpoints.up('md')]: {
				...theme.border({ kind: 'top', color: theme.palette.body.light })
			}
		},
		fullscreen: {}
	}),
	// TODO needed for button
	{ name: 'FullScreenDialog', index: 1 }
);

export default useStyles;
