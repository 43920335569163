import { FormikError } from 'components';
import { setLocale } from 'yup';

type Params = Record<string, string>;
type LocaleData = Record<string, Record<string, ((params: Params) => FormikError) | string>>;

const setYupLocale = () => {
	const localeData: LocaleData = {
		mixed: {
			default: 'common.error.invalid-field',
			required: 'common.required',
			typeError: 'common.error.mixed.type-error'
		},
		number: {
			positive: 'common.number-positive',
			max: ({ max }: Params) => ({ localeId: 'common.error.number.max', values: { max } })
		},
		array: {
			min: ({ min }: Params) => ({ localeId: 'common.error.array.min', values: { min } })
		}
	};

	setLocale(localeData);
};
export default setYupLocale;
