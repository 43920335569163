import { countries } from 'components';
import { getLanguageFromLocale } from '../utils';

const formatCountry = (
	id: string,
	props: {
		locale: Locale;
	}
): string => {
	const { locale } = props;
	const country = countries.find((item) => item.id === id);
	if (!country) {
		throw new Error(`Country with id "${id}" was not found`);
	}
	const language = getLanguageFromLocale(locale);
	const message = country.translations[language];

	if (!message) {
		throw new Error(`Missing translation for country "${id}" and locale "${locale}"`);
	}

	return message as string;
};

export default formatCountry;
