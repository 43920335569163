import Dayjs, { Dayjs as DayjsType } from 'dayjs';
import { DateFormat, DateRangeFormat, flattenDateFormat } from 'components';
import { dayjsWithTimezone } from '../utils';

export const datesDelimiter = '—';

// Date format docs: https://fomfsite.atlassian.net/wiki/spaces/NR/pages/2739732481/2022-01-25+date+format
const formatDateRange = (
	_startDate: DayjsType,
	_endDate: DayjsType,
	outputFormat?: DateRangeFormat
): string => {
	const startDate = dayjsWithTimezone(_startDate);
	const endDate = dayjsWithTimezone(_endDate);
	const datesSameYear = startDate.isSame(endDate, 'year');
	const datesSameMonth = datesSameYear && startDate.isSame(endDate, 'month');
	const datesSameDay = datesSameMonth && startDate.isSame(endDate, 'day');

	const currentLocale = Dayjs.locale() as DayJSLocale;
	const localisedFormat = outputFormat
		? flattenDateFormat(outputFormat, currentLocale)
		: outputFormat;

	const dayMonthFormat =
		outputFormat === DateRangeFormat.monthNumber ? 'DD.[&nbsp;]MM.' : 'DD.[&nbsp;]MMM';
	const weekdayMonthYearFormat =
		localisedFormat ?? flattenDateFormat(DateRangeFormat.full, currentLocale);
	const dayMonthYearFormat = 'DD.[&nbsp;]MMM[&nbsp;]YYYY';

	if (datesSameDay) {
		const dayDate = Dayjs(startDate).format(weekdayMonthYearFormat);
		let time = '';
		switch (localisedFormat) {
			case flattenDateFormat(DateRangeFormat.timeDuration, currentLocale): {
				time = Dayjs.duration(Math.abs(startDate.diff(endDate))).humanize();
				break;
			}
			case flattenDateFormat(DateRangeFormat.constantDate, currentLocale):
				return startDate.format(DateFormat.date);
			case flattenDateFormat(DateRangeFormat.date, currentLocale):
				break;
			default: {
				const startTime = startDate.format(DateFormat.time);
				const endTime = endDate.format(DateFormat.time);
				time = startTime !== endTime ? ` ${startTime} - ${endTime}` : '';
			}
		}
		return `${dayDate}${time}`;
	}
	if (outputFormat === DateRangeFormat.constantDate) {
		return `${startDate.format('DD.MM.')} – ${endDate.format('DD.MM.YYYY')}`;
	}

	const dateLabel =
		(datesSameDay && startDate.format(weekdayMonthYearFormat)) ||
		(datesSameMonth &&
			`${startDate.format(dayMonthFormat)}&nbsp;${datesDelimiter}&nbsp;${endDate.format(
				dayMonthYearFormat
			)}`) ||
		(datesSameYear &&
			`${startDate.format(dayMonthFormat)} ${datesDelimiter} ${endDate.format(
				weekdayMonthYearFormat
			)}`) ||
		`${startDate.format(weekdayMonthYearFormat)} ${datesDelimiter} ${endDate.format(
			weekdayMonthYearFormat
		)}`;

	return dateLabel;
};

export default formatDateRange;
