import React from 'react';
import { Dialog as MUIDialog, DialogProps } from '@mui/material';

import { Icon } from '..';
import useStyles from './Dialog.styles';

type Props = Omit<DialogProps, 'classes'> & { hideCloseButton?: boolean } & {
	classes?: Partial<DialogProps['classes'] & ReturnType<typeof useStyles>>;
};

const Dialog = (props: Props) => {
	const { children, onClose, hideCloseButton } = props;
	const classes = useStyles(props);
	const handleClose = () => {
		onClose && onClose({}, 'backdropClick');
	};
	return (
		<MUIDialog maxWidth="xs" {...props}>
			{onClose && !hideCloseButton && (
				<Icon onClick={handleClose} className={classes.icon} icon="close" />
			)}
			<div className={classes.content}>{children}</div>
		</MUIDialog>
	);
};

export default Dialog;
