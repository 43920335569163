import classnames from 'classnames';
import React from 'react';

import { CircularProgress, CircularProgressProps, Box } from '@mui/material';
import useStyles from './Loader.styles';

interface Props extends CircularProgressProps {
	/**
	 * If true, the loader is placed under a container and centered both vertical and horizontally.
	 * @default false
	 */
	fullScreen?: boolean;
	className?: string;
}

const CustomLoader = (props: Props) => {
	const { fullScreen = false, className, ...others } = props;
	const classes = useStyles();
	if (fullScreen) {
		return (
			<Box className={classes.container}>
				<CircularProgress {...others} />
			</Box>
		);
	}
	return <CircularProgress {...others} className={classnames(className, classes.inline)} />;
};

export type { Props };
export default CustomLoader;
