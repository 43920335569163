import { Box } from '@mui/material';
import classnames from 'classnames';
import { Icon, isIconName, Radio, Typography } from 'components';
import React from 'react';
import { UserPaymentMethodCardProps } from './UserPaymentMethodCard.types';
import useStyles from './UserPaymentMethodCard.styles';
import { Types } from '../../';

const UserPaymentMethodCard = (props: UserPaymentMethodCardProps) => {
	const { userPaymentMethod, className, 'data-test': dataTest, onDefaultChange } = props;
	const classes = useStyles(props);

	const cardProps = (() => {
		switch (userPaymentMethod.type) {
			case Types.UserPaymentMethodType.SepaDebit:
				return {
					brandName: 'sepa',
					iconName: 'sepa',
					iconClass: classes.sepa,
					number: (
						<>
							<Typography title={userPaymentMethod.country} />
							<Typography title="****" />
							<Typography title="****" />
							<Typography title="****" />
							<Typography title={`**${userPaymentMethod.last4?.slice(0, 2)}`} />
							<Typography title={userPaymentMethod.last4?.slice(2)} />
						</>
					)
				} as const;
			case Types.UserPaymentMethodType.CreditCard:
				return {
					brandName: userPaymentMethod.brand,
					iconName: isIconName(userPaymentMethod.brand) ? userPaymentMethod.brand : null,
					number: (
						<>
							<Typography title="****" />
							<Typography title="****" />
							<Typography title="****" />
							<Typography title={userPaymentMethod.last4} />
						</>
					)
				} as const;
			case Types.UserPaymentMethodType.StripePayPal:
				return {
					brandName: 'PayPal',
					iconName: 'paypal',
					number: null
				} as const;
			default:
				return { number: null };
		}
	})();

	return (
		<Box
			className={classnames(
				classes.root,
				{ [classes.clickable]: !!onDefaultChange },
				className
			)}
			onClick={onDefaultChange}
		>
			<Box className={classes.header}>
				{onDefaultChange && (
					<Radio checked={!!userPaymentMethod.default} className={classes.radio} />
				)}
				<Typography title={cardProps.brandName} className={classes.brand} />
			</Box>
			<Box className={classes.body} data-test={dataTest}>
				{cardProps.number}
			</Box>
			<Box className={classes.footer}>
				<Box />
				{cardProps.iconName && (
					<Icon
						icon={cardProps.iconName}
						className={classnames(classes.icon, cardProps.iconClass)}
					/>
				)}
			</Box>
		</Box>
	);
};

export default UserPaymentMethodCard;
