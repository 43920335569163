import React, { useState } from 'react';
import {
	Accordion as MUIAccordion,
	AccordionSummary as MUIAccordionSummary,
	AccordionDetails as MUIAccordionDetails,
	Box
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { Icon, Typography } from '..';
import { AccordionsProps } from './Accordions.types';

const AccordionItem = withStyles(
	(theme) => ({
		root: {
			...theme.border({ kind: 'bottom', color: theme.palette.body.light, radius: 0 }),
			margin: 0,
			padding: theme.spacing(1, 0),
			boxShadow: 'none',
			background: 'none',
			transition: theme.transitions.create('padding'),
			'&::before': {
				display: 'none'
			},
			'&:first-child': {
				margin: 0,
				borderRadius: 0
			},
			'&:last-child': {
				margin: 0,
				borderRadius: 0
			},
			'&$expanded': {
				margin: 0,
				padding: theme.spacing(2, 0)
			},
			'&$expanded:first-child': {
				padding: theme.spacing(1, 0, 2)
			}
		},
		expanded: {},
		disabled: {},
		rounded: {}
	}),
	{ name: 'AccordionItem' }
)(MUIAccordion);

const AccordionSummary = withStyles(
	(theme) => ({
		root: {
			...theme.border({ width: 0, radius: 6 }),
			backgroundColor: 'transparent',
			margin: theme.spacing(0, -1),
			minHeight: 0,
			padding: theme.spacing(1.5),
			'&$expanded': {
				backgroundColor: theme.palette.secondary[200],
				minHeight: 0
			}
		},
		content: {
			margin: 0,
			'&$expanded': {
				margin: 0
			}
		},
		expanded: {},
		disabled: {},
		expandIconWrapper: {
			transition: theme.transitions.create(['color, transform']),

			width: 24,
			height: 24, // line-height of the title text
			margin: 0,
			padding: 0,

			'& > div, & svg': {
				width: '100%',
				height: '100%'
			},
			'&$expanded': {
				color: theme.palette.primary.main
			}
		},
		focused: {},
		focusVisible: {}
	}),
	{ name: 'AccordionSummary' }
)(MUIAccordionSummary);

const AccordionDetails = withStyles(
	(theme) => ({
		root: {
			padding: theme.spacing(2, 0.5, 0)
		}
	}),
	{ name: 'AccordionDetails' }
)(MUIAccordionDetails);

const Accordions = (props: AccordionsProps) => {
	const {
		items,
		accordionItemClasses,
		accordionSummaryClasses,
		defaultExpandedItem = 'panel-0'
	} = props;
	const [expanded, setExpanded] = useState<string | false>(defaultExpandedItem);

	const handleChange =
		(panel: string) => (event: React.ChangeEvent<unknown>, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};

	return (
		<Box>
			{items.map(({ title, titleLocaleId, description }, index) => {
				const key = `panel-${index}`;
				return (
					<AccordionItem
						expanded={expanded === key}
						onChange={handleChange(key)}
						key={key}
						classes={accordionItemClasses}
					>
						{(Boolean(title) || Boolean(titleLocaleId)) && (
							<AccordionSummary
								expandIcon={<Icon icon="caretDown" />}
								aria-controls={`${key}-content`}
								id={`${key}-header`}
								classes={accordionSummaryClasses}
							>
								{typeof title === 'string' || typeof titleLocaleId === 'string' ? (
									<Typography
										title={title}
										localeId={titleLocaleId}
										variant="linkSmall"
										fontFamily="Poppins"
										color="primary"
									/>
								) : (
									title
								)}
							</AccordionSummary>
						)}
						{Boolean(description) && (
							<AccordionDetails>
								{typeof description === 'string' ? (
									<Typography
										title={description}
										variant="textXSmall"
										color="body"
										colorVariant="dark"
									/>
								) : (
									description
								)}
							</AccordionDetails>
						)}
					</AccordionItem>
				);
			})}
		</Box>
	);
};

export default Accordions;
