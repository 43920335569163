import React from 'react';
import { Box } from '@mui/material';
import classnames from 'classnames';
import dayjs from 'dayjs';

import { MonthProps } from './Month.types';
import useStyles from './Month.styles';
import { getActiveColumnIndex, getAllDaysByRow } from './Month.utils';

import { DateFormat, Icon, Typography } from '../../';
import DayItem from './DayItem';

const Month = (props: MonthProps) => {
	const {
		year,
		month,
		currentDate,
		monthPosition,
		onMonthChange,
		weekStartDay,
		className,
		isNextMonth
	} = props;

	const monthString = month >= 9 ? `${month + 1}` : `0${month + 1}`;
	const monthStart = dayjs(`01/${monthString}/${year}`, DateFormat.date);
	// 0-6, 0 - Sunday, 1 - Monday, ..., 6 - Saturday
	const monthStartDayRaw = monthStart.day();
	// normalized value based on which week day should be first
	const monthStartDay = monthStartDayRaw - weekStartDay;
	const monthStartOffsetCount = monthStartDay < 0 ? 6 + monthStartDay + 1 : monthStartDay;

	const monthStartOffsetDays = new Array(monthStartOffsetCount)
		.fill(null)
		.map((item, index) => monthStart.subtract(monthStartOffsetCount - index, 'day'));
	const monthDays = new Array(monthStart.daysInMonth())
		.fill(null)
		.map((item, index) => monthStart.add(index, 'day'));
	const allDays = [...monthStartOffsetDays, ...monthDays];
	const allDaysByRow = getAllDaysByRow(allDays);
	const activeColumnIndex = getActiveColumnIndex(allDaysByRow, currentDate);
	const dayTitleItems = new Array<null>(7).fill(null);

	const showMonthBackArrow = monthPosition === 'single' || monthPosition === 'start';
	const showMonthNextArrow = monthPosition === 'single' || monthPosition === 'end';

	const classes = useStyles(props);
	return (
		<Box className={classnames(classes.root, className)}>
			<Box className={classes.titleContainer}>
				<Icon
					icon="arrowLeft"
					className={classnames(classes.arrow, classes.arrowBack, {
						[classes.hide]: !showMonthBackArrow
					})}
					onClick={() => onMonthChange(false)}
				/>
				<Typography
					title={monthStart.format(DateFormat.monthAndYear)}
					variant="linkSmall"
					color="titleActive"
					className={classes.title}
				/>
				<Icon
					icon="arrowRight"
					className={classnames(classes.arrow, classes.arrowNext, {
						[classes.hide]: !showMonthNextArrow
					})}
					onClick={() => onMonthChange(true)}
				/>
			</Box>
			<Box className={classes.grid}>
				{dayTitleItems.map((item, index) => (
					<Typography
						className={classnames(classes.columnTitle, {
							[classes.current]: index === activeColumnIndex
						})}
						variant="textXSmall"
						style={{ gridRow: 1, gridColumn: index + 1 }}
						key={index}
					>
						{monthStart.subtract(monthStartDay - index, 'days').format('ddd')}
					</Typography>
				))}
				{allDaysByRow.map((row, rowIndex) =>
					row.map((day, dayIndex) => (
						<DayItem
							{...props}
							day={day}
							rowIndex={rowIndex}
							dayIndex={dayIndex}
							allDays={allDays}
							monthStartDay={monthStartDay}
							key={`${year}-${month}-${dayIndex}`}
							dayMonth={month}
							isNextMonth={isNextMonth}
						/>
					))
				)}
			</Box>
		</Box>
	);
};

export default Month;
