const formatTemplateTranslation = (
	template?: string,
	values?: Record<string, string | undefined>
) => {
	return template?.replace(/{(\w*)}/g, (_m, key) => {
		let result;
		if (values && key in values && values[key]) {
			result = values[key];
		}
		return result ?? '';
	});
};

export default formatTemplateTranslation;
