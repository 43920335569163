import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		icon: {
			flexShrink: 0,
			height: theme.spacing(2),
			width: theme.spacing(2),
			display: 'flex'
		},
		rounded: {
			backgroundColor: theme.palette.line.main,
			height: theme.spacing(3),
			width: theme.spacing(3),
			...theme.border({ radius: 16, width: 0 })
		}
	}),
	{ name: 'Icon' }
);

export default useStyles;
