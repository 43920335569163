/* eslint-disable react/prop-types */
import React from 'react';
import { SelectableOption } from '../utils/componentsTypes';
import { RenderInputParams, TextFieldInputProps } from './Autocomplete.types';
import { useStyles } from './Autocomplete.styles';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import { TextField } from '../TextField';

export const getOptionLabelDefault = (option: SelectableOption) =>
	option.label || option.name || option.localeId || option.id;
export const getOptionSelectedDefault = (option: SelectableOption, value: SelectableOption) =>
	option.id === value.id;

export const createRenderTextField =
	(props: TextFieldInputProps, classes: ReturnType<typeof useStyles>) =>
	(params: RenderInputParams) => {
		const { error, inputProps: propsInputProps, noWrapInput, ...restProps } = props;
		const { inputProps: paramsInputProps, ...restParams } = params;
		const { autoComplete, value, ...restInputProps } =
			paramsInputProps as InputBaseComponentProps;

		return (
			<TextField
				className={error ? classes.inputWithError : ''}
				error={error}
				isAutocomplete
				nativeAutocomplete={false}
				value={value || ''}
				inputProps={{
					...propsInputProps,
					...restInputProps
				}}
				noWrapInput={typeof noWrapInput === 'boolean' ? noWrapInput : true}
				{...restProps}
				{...restParams}
			/>
		);
	};
