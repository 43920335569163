import { Button } from 'components';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { LOCALE_TO_DOMAIN, SUPPORT_EMAIL_ADDRESS } from '../constants';
import { MessageParams } from '../types';

type ErrorFunctionReturn = [string, { fieldText: ReactNode }];

export const genericErrorMessage = (domain: Domain): MessageParams => {
	const email = SUPPORT_EMAIL_ADDRESS[domain];
	return {
		titleLocaleId: 'common.error.generic.title',
		messageLocaleId: 'common.error.generic.description',
		messageValues: { link: `<a href="mailto:${email}">${email}</a>` },
		actionButton: <Button localeId="common.return-to-homepage" href="/" />
	};
};

const requiredFieldErrorParams = (fieldName: string): ErrorFunctionReturn => [
	'common.required',
	{ fieldText: <FormattedMessage id={`common.${fieldName}`} /> }
];

const invalidFieldErrorParams = (fieldName: string): ErrorFunctionReturn => [
	'common.error.invalid-field',
	{ fieldText: <FormattedMessage id={`common.${fieldName}`} /> }
];

const JsonMessagesArray = (jsonMessagesArray: Array<string>) => (
	<>
		{jsonMessagesArray.map((item) => (
			<div style={{ marginBottom: 8 }} key={item}>
				{item}
			</div>
		))}
	</>
);

const serverErrorToLocaleIdMap: Record<
	string,
	string | ((domain: Domain) => MessageParams) | ErrorFunctionReturn | string | undefined
> = {
	'userservice.importuser.error.user-already-exists': 'common.error.user-already-exist',
	'userservice.registration.error.user-already-exists': 'common.error.user-already-exist',
	'userservice.registration.error.invalid-email-format': 'common.error.invalid-email-format',
	'userservice.registration.error.invalid-password': 'common.error.invalid-password',
	'userservice.registration.error.invalid-privacypolicy': 'common.error.invalid-privacypolicy',
	'error.firstname-required': requiredFieldErrorParams('first-name'),
	'error.lastname-required': requiredFieldErrorParams('last-name'),
	'error.gender-required': requiredFieldErrorParams('gender'),
	'error.invalid-email-format': 'common.error.invalid-email-format',
	'error.occupationtype-required': requiredFieldErrorParams('occupation-type'),
	'error.fieldofactivity-required': requiredFieldErrorParams('field-of-activity'),
	'error.country-required': requiredFieldErrorParams('country'),
	'error.specialties-required': requiredFieldErrorParams('field-of-activity'),
	'error.invalid-specialties': invalidFieldErrorParams('specialities'),
	'error.invalid-createdat': invalidFieldErrorParams('created-at'),
	'error.invalid-updatedat': invalidFieldErrorParams('updated-at'),
	'error.invalid-lastlogin': invalidFieldErrorParams('last-login'),
	'error.invalid-country': invalidFieldErrorParams('country'),
	'error.invalid-email-change-request': (domain: Domain) => genericErrorMessage(domain),
	'userservice.activateuser.error.activation-notfound': (domain: Domain) =>
		genericErrorMessage(domain),
	'user-service.reset-password.error.link-expired': 'common.error.reset-password-link-expired',
	'userservice.resetpasslink.error.user-notfound': 'common.error.user-not-found',
	'userservice.changepassword.error.invalid-username-password':
		'common.error.invalid-credentials',
	'error.user-notfound': 'common.error.user-not-found',
	'error.generic': (domain: Domain) => genericErrorMessage(domain),
	'error.invalid-promocode-application': 'common.error.invalid-promocode-application',
	'error.booking.not-synced-with-ub': 'common.error.booking-syncing-try-later',
	'error.booking.sync-error-for-pushing-to-ub': 'common.error.booking-syncing-error',
	'error.booking-is-not-paid-yet': 'common.error.booking-is-not-paid-yet',
	'error.invalid-event': 'common.error.invalid-event',
	'error.invalid-user-occupation-type-participant-type':
		'common.error.invalid-user-occupation-type-participant-type',
	'error.action-not-allowed-for-on-hold-user': 'common.error.action-not-allowed-for-on-hold-user'
} as const;

/**
 * Return error message which is chosen from all variants depending on priority:
 * 1. Parsed JSON array
 * 2. `messageKey` translated with corresponding term
 * 3. `message` from error if `messageKey` exists but no corresponding term is assigned
 * 4. Generic error message in all other cases
 * @param error
 */
const getMessageParamsFromError = (
	error: ApolloError | null | undefined,
	locale: Locale
): MessageParams => {
	const domain = LOCALE_TO_DOMAIN[locale];
	if (!error) {
		return genericErrorMessage(domain);
	}

	const graphQLErrors = error.graphQLErrors || [];

	const errorWithJsonMessage = isErrorMessageJson(error)
		? error
		: graphQLErrors.find(isErrorMessageJson);
	const jsonMessage = errorWithJsonMessage && JSON.parse(errorWithJsonMessage.message);
	const jsonMessagesArray = Array.isArray(jsonMessage) && jsonMessage;

	const graphQLError: GraphQLError | undefined = (graphQLErrors && graphQLErrors[0]) || undefined;
	const graphqlMessageKey = graphQLError?.extensions?.messageKey;
	const graphqlMessage = graphQLError?.message;

	const message = error.message;

	console.error({
		graphqlMessage,
		graphqlMessageKey,
		message,
		jsonMessagesArray
	});

	if (jsonMessagesArray) {
		return {
			message: JsonMessagesArray(jsonMessage),
			jsonMessagesArray
		};
	}

	if (!graphqlMessageKey) {
		return genericErrorMessage(domain);
	}

	const serverError = serverErrorToLocaleIdMap[graphqlMessageKey];

	if (!serverError) {
		// server error message written in English will not help our users much so we show generic error
		console.error({ graphqlMessageKey, graphqlMessage });
		return genericErrorMessage(domain);
	}

	if (typeof serverError === 'function') {
		const messageParams = serverError(domain);
		return messageParams;
	} else if (typeof serverError === 'object') {
		return { messageLocaleId: serverError[0], messageValues: serverError[1] };
	}

	return { messageLocaleId: serverError };
};

const isErrorMessageJson = (error: ApolloError | GraphQLError): boolean => {
	try {
		const parsed = JSON.parse(error.message);
		return typeof parsed === 'object';
	} catch (e) {
		return false;
	}
};

export { getMessageParamsFromError };
