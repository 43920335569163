import { FetchResult } from '@apollo/client/link/core';

import { ApolloError } from '@apollo/client';
import { getMessageParamsFromError } from '../content/server-errors';
import { AlertContextType, useAlert } from '../contexts';
import { MessageParams } from '../types';
import useSnackbar, { SnackbarProviderContext } from './useSnackbar';
import { useIntl } from 'react-intl';

type BaseMutationType = (options?: Record<string, unknown>) => Promise<FetchResult>;

export const execMutation = async <MutationType extends BaseMutationType>(
	mutation: MutationType,
	mutationOptions: Parameters<MutationType>[0],
	alertContext: AlertContextType,
	snackbarContext: SnackbarProviderContext,
	locale: Locale,
	successMessage?: MessageParams,
	errorMessage?: MessageParams,
	notificationType: 'alert' | 'snackbar' = 'snackbar'
): Promise<ReturnType<MutationType>> => {
	const { openAlert } = alertContext;
	const { enqueueSnackbar, closeSnackbar } = snackbarContext;
	const open = notificationType === 'alert' ? openAlert : enqueueSnackbar;

	const loadingSnackbarKey = enqueueSnackbar({
		variant: 'info',
		persist: true,
		messageLocaleId: 'common.loading-ellipsis'
	});

	try {
		const response = await mutation(mutationOptions);

		closeSnackbar(loadingSnackbarKey);

		if (successMessage) {
			open({ variant: 'success', ...successMessage });
		}

		return response;
	} catch (e) {
		closeSnackbar(loadingSnackbarKey);
		const error = e as ApolloError;
		const errorMessageParams = {
			variant: 'error' as const,
			...(errorMessage || getMessageParamsFromError(error, locale))
		};
		if (errorMessageParams.titleLocaleId === 'common.error.generic.title') {
			openAlert(errorMessageParams);
		} else {
			open(errorMessageParams);
		}

		return Promise.resolve({ errors: error.graphQLErrors });
	}
};

const useExecMutation = () => {
	const alertContext = useAlert();
	const snackbarContext = useSnackbar();
	const { locale } = useIntl();
	return <MutationType extends BaseMutationType>(
		mutation: MutationType,
		mutationOptions: Parameters<MutationType>[0],
		successMessage?: MessageParams,
		errorMessage?: MessageParams,
		notificationType: 'alert' | 'snackbar' = 'snackbar'
	) =>
		execMutation(
			mutation,
			mutationOptions,
			alertContext,
			snackbarContext,
			locale,
			successMessage,
			errorMessage,
			notificationType
		);
};

export type ExecMutation = ReturnType<typeof useExecMutation>;

export default useExecMutation;
