const convertHtmlToPlainText = (htmlText: string) => {
	if (typeof window === 'undefined') {
		// For simplicity, we just remove HTML tags
		return htmlText.replace(/<[^>]*>/g, '');
	} else {
		const tempDivElement = document.createElement('div');
		tempDivElement.innerHTML = htmlText;
		return tempDivElement.textContent || tempDivElement.innerText || '';
	}
};

export default convertHtmlToPlainText;
