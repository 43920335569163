import { Box } from '@mui/material';
import React from 'react';
import classNames from 'classnames';

import Typography from '../Typography/Typography';
import useStyles from './ButtonsSwitch.styles';
import type { ButtonsSwitchProps } from './ButtonsSwitch.types';

const ButtonsSwitch = (props: ButtonsSwitchProps) => {
	const { className, options, selection, onSelectionChange, fontFamily, underlineFullWidth } =
		props;
	const classes = useStyles(props);

	return (
		<div className={classNames(classes.container, className)}>
			{options.map((option) => {
				const selected = selection === option.id;
				const handleSelectionChange = () =>
					onSelectionChange && onSelectionChange(option.id);
				return (
					<Box
						className={classNames(classes.option, {
							[classes.selected]: selected
						})}
						onClick={handleSelectionChange}
						key={option.id}
					>
						<Typography
							className={classNames(classes.optionText, {
								[classes.underlineFullWidth]: underlineFullWidth
							})}
							localeId={option.localeId}
							title={option.label}
							variant={selected ? 'linkXSmall' : 'textXSmall'}
							fontFamily={fontFamily}
						/>
					</Box>
				);
			})}
		</div>
	);
};

export default ButtonsSwitch;
