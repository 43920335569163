import classnames from 'classnames';
import React from 'react';
import { UserAddress } from '../../';
import AddressForm from './AddressForm';
import useStyles from './EditPersonalInfo.styles';
import { UserAddressItemProps } from './UserAddressItem.types';

const UserAddressItem = (props: UserAddressItemProps) => {
	const { isActive, address, user, onEdit, onDelete, closeAddressForm, addressFormik } = props;
	const classes = useStyles();
	return (
		<>
			<UserAddress
				user={user}
				address={address}
				compact
				className={classnames(classes.spacing, classes.addressItem, {
					[classes.active]: isActive
				})}
				typographyVariant="textXSmall"
				onEdit={!isActive ? onEdit : undefined}
				onDelete={onDelete}
			/>
			{isActive && (
				<AddressForm
					addressFormik={addressFormik}
					onClose={closeAddressForm}
					addressId={address.id}
					className={classes.spacing}
				/>
			)}
		</>
	);
};

export default UserAddressItem;
