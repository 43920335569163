import makeStyles from '@mui/styles/makeStyles';
import { BulletPointsProps, BulletPointsClasses } from './BulletPoints.types';
import type { ThemeTypes } from 'components';

const useStyles = makeStyles<
	ThemeTypes.Theme,
	Partial<BulletPointsProps>,
	Partial<BulletPointsClasses>
>((theme) => {
	return {
		itemContainer: {
			marginTop: theme.spacing(2)
		}
	};
});

export default useStyles;
