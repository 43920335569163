import * as React from 'react';
import { Box } from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Icon, Typography } from 'components';

import useStyles from './BrandIcon.styles';
import type { BrandIconProps, BrandIconOnlyProps } from './BrandIcon.types';
import { EventTransformers, formatters, ContentTransformers, Types } from '../..';
import classnames from 'classnames';

export const BrandIconOnly = (props: BrandIconOnlyProps) => {
	const { brand, contentType, color = 'primary', className: classNameProp } = props;
	const classes = useStyles(props);
	const className = classnames(classes.image, classes[color], classNameProp);

	if (contentType === Types.ContentType.Webinar) {
		return <Icon icon="webUp" className={className} />;
	}

	switch (brand) {
		case Types.ProductBrand.UpdateRefresher:
		case Types.ProductBrand.Refresher:
		case Types.ProductBrand.Fortbildungstage:
		case Types.ProductBrand.StudyConferenceUpdate:
			return <Icon icon="refresher" className={className} />;
		case Types.ProductBrand.Wuex:
		case Types.ProductBrand.Wuif:
		case Types.ProductBrand.Wu660:
			return <Icon icon="webUp" className={className} />;
		case Types.ProductBrand.Summedup:
			return <Icon icon="summedUp" className={className} />;
		case Types.ProductBrand.Skill:
			return <Icon icon="skills" className={className} />;
		case Types.ProductBrand.Guideline:
			return <Icon icon="guidelines" className={className} />;
		case Types.ProductBrand.Syrot:
			return <Icon icon="addon" className={className} />;
		case Types.ProductBrand.Syfat:
			return <Icon icon="syfat" className={className} />;
		default:
			return null;
	}
};

const getEventFormatIcon = (
	classes: ClassNameMap<string>,
	eventFormat?: Types.EventFormat | null,
	color: BrandIconProps['color'] = 'primary'
) => {
	const className = classnames(classes.image, classes[color]);
	if (
		eventFormat === Types.EventFormat.UpdateRefresher ||
		eventFormat === Types.EventFormat.Refresher
	) {
		return <Icon icon="refresher" className={className} />;
	} else if (eventFormat === Types.EventFormat.Webup) {
		return <Icon icon="webUp" className={className} />;
	}
	return null;
};

const BrandIcon = (props: BrandIconProps) => {
	const { brand, color = 'secondary', eventFormat, typographyProps, contentType } = props;
	const classes = useStyles(props);
	const { messages } = useIntl();
	return (
		<Box className={classes.container}>
			{(brand || contentType) && <BrandIconOnly {...props} />}
			{eventFormat && getEventFormatIcon(classes, eventFormat, color)}
			{contentType && (
				<Typography
					className={classes.brandName}
					title={formatters.formatEnum(contentType, {
						options: ContentTransformers.contentType,
						messages
					})}
					fontFamily="Poppins"
					{...typographyProps}
				/>
			)}
			{(brand || eventFormat) && (
				<Typography
					className={classnames(classes.brandName, {
						[classes.noColor]: color === 'noColor'
					})}
					title={
						<>
							{brand &&
								formatters.formatEnum(brand, {
									options: ContentTransformers.brand,
									messages
								})}
							{eventFormat &&
								formatters.formatEnum(eventFormat, {
									options: EventTransformers.evenFormat,
									messages
								})}
						</>
					}
					fontFamily="Poppins"
					{...typographyProps}
				/>
			)}
		</Box>
	);
};

export default BrandIcon;
