import { FocusEvent } from 'react';
import { ConfigType, Dayjs } from 'dayjs';
import { TextFieldProps } from '../';
import IMask from 'imask';

type PickedDate = Dayjs | null | undefined;
type PickedDateType = 'single' | 'start' | 'end';
type RangeDate = { start: PickedDate; end: PickedDate };
type SingleDate = { single: PickedDate };
type PickedRangeDate = SingleDate | RangeDate;

type SingleDateValue = ConfigType;
type RangeDateValue = { start: ConfigType; end: ConfigType };

export enum PickerVariant {
	SINGLE,
	RANGE
}

type DateMaskType = IMask.InputMask<IMask.AnyMaskedOptions>;

type SinglePickerProps = {
	value: SingleDateValue;
	onChange: (pickedDate: string) => void;
	variant: PickerVariant.SINGLE;
};

type RangePickerProps = {
	value: RangeDateValue;
	onChange: (pickedDate: { start: string; end: string }) => void;
	variant: PickerVariant.RANGE;
};

type DateRangePickerProps = {
	startDate?: Dayjs;
	minDate?: Dayjs;
	maxDate?: Dayjs;
	onBlur?: (e: FocusEvent) => void;
} & (SinglePickerProps | RangePickerProps) &
	Omit<TextFieldProps, 'value' | 'onChange'>;

export type {
	DateRangePickerProps,
	PickedDate,
	PickedDateType,
	PickedRangeDate,
	SinglePickerProps,
	RangePickerProps,
	DateMaskType,
	SingleDate,
	RangeDate,
	SingleDateValue,
	RangeDateValue
};
