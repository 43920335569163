import { ThemeTypes } from '../';

export const isTypographyVariant = <T extends string>(
	variant: T
): variant is T & ThemeTypes.AllTypography =>
	variant
		? [
				'h1',
				'h2',
				'h3',
				'h4',
				'h5',
				'h6',
				'subtitle1',
				'subtitle2',
				'body1',
				'body2',
				'caption',
				'displayLarge',
				'displayLargeBold',
				'displayMedium',
				'displayMediumBold',
				'displaySmall',
				'displaySmallBold',
				'subtitleLarge',
				'bodyLarge',
				'overline'
			].includes(variant)
		: false;
