export const contentMock = {
	dominantColor: null,
	provider: {
		companyPage: {
			slug: 'AstraZeneca',
			publishStatus: 'PUBLISHED'
		},
		id: '04c50247-5856-4930-afa2-8ba45e98fff9',
		name: 'AstraZeneca GmbH',
		country: 'DEU',
		logoUrl: 'https://storage.googleapis.com/bk-fms-eu/public/partner/logo/00107642.png'
	},
	specialities: [],
	speakers: [],
	topics: [],
	parent: {
		id: 'c32aab6b-a69d-438b-9552-26eb857dac61',
		slug: 'praxis-update-therapie-des-her2-mutierten-nsclc-webup-im-fokus-120324',
		brand: 'WUIF',
		endDateTime: '2024-03-12T18:00:00.000Z',
		externalId: '6833',
		contentId: '16165',
		contentType: 'WEBINAR',
		startDateTime: '2024-03-12T17:00:00.000Z',
		willBeBroadcasted: true,
		willBeOnDemand: true,
		isFree: true,
		isCommercial: false,
		isAvailableOnHomepage: null,
		isAvailableOnMediathek: true,
		isAvailableWithoutEfn: false,
		participationOption: 'LIVE_STREAM',
		capacityStatus: null,
		locale: 'de-DE',
		description: {
			'de-DE':
				'<p>Bei diesem WebUp stellen Ihnen <strong>Herr Professor Dr. med Eckart Laack</strong> (Leitung der Hämato-Onkologie Hamburg, Prof. Laack und Partner) und <strong>Herr</strong> <strong>Professor Dr. med Wolfgang Brückl </strong>(Leiter der pneumologischen Onkologie, Klinikum Nürnberg) neue Therapieoptionen beim HER2-mutierten, fortgeschrittenen nicht-kleinzelligen Lungenkarzinom vor, und unterlegen dies mit ihren eigenen ersten Erfahrungen. </p><p style="text-align: start"><span style="color: rgb(97, 97, 97)">Die Teilnahme am WebUp ist&nbsp;</span><strong><span style="color: rgb(97, 97, 97)">kostenlos!</span></strong></p><p style="text-align: start"></p>'
		},
		descriptionTitle: {},
		title: 'Praxis-Update: Therapie des HER2-mutierten NSCLC',
		city: {
			'de-DE': 'WebUp',
			'de-AT': 'WebUp',
			'de-CH': 'WebUp',
			'fr-CH': 'WebUp',
			'en-GB': 'WebUp',
			'en-AU': 'WebUp'
		},
		order: null,
		headerUrl: 'https://www.fomf.de/sites/default/files/2024-01/1.png',
		isSponsored: true,
		rating: null,
		provider: {
			id: '04c50247-5856-4930-afa2-8ba45e98fff9',
			name: 'AstraZeneca GmbH',
			country: 'DEU',
			logoUrl: 'https://storage.googleapis.com/bk-fms-eu/public/partner/logo/00107642.png',
			companyPage: {
				slug: 'AstraZeneca',
				publishStatus: 'PUBLISHED'
			}
		},
		department: {
			id: 'efed2481-a5f1-466c-aea5-29d9720907e6',
			name: null,
			logoUrl: null
		},
		contentProgress: {
			completed: null,
			contentId: '16165',
			time: null,
			trackedDateTime: null
		},
		contentUnits: [],
		membershipSettings: {
			availableForMembers: true
		},
		detailsPageSettings: {
			eventBookable: false,
			redirectThroughExhibition: false
		},
		agendaSettings: {
			defaultDescription: {
				'de-DE':
					'<p><span style="color: rgb(97, 97, 97)">18:00 Uhr </span></p><p><strong><span style="color: rgb(97, 97, 97)">Begrüßung und Einführung </span></strong></p><p><span style="color: rgb(97, 97, 97)">18:00 – 18:15 Uhr&nbsp;</span></p><p><strong><em><span style="color: rgb(97, 97, 97)">Vortrag: HER2-Alterationen beim NSCLC</span></em><span style="color: rgb(97, 97, 97)">&nbsp;- </span></strong><span style="color: rgb(97, 97, 97)">Prof. Dr. med Eckart Laack&nbsp;</span></p><p><span style="color: rgb(97, 97, 97)">18:15 – 18:45 Uhr&nbsp;</span></p><p><strong><em><span style="color: rgb(97, 97, 97)">Vortrag: Neuer zielgerichteter Therapieansatz beim HER2-mutierten, fortgeschrittenen NSCLC – Erfahrungen aus der klinischen Praxis</span></em><span style="color: rgb(97, 97, 97)">&nbsp;- </span></strong><span style="color: rgb(97, 97, 97)">Prof. Dr. med Wolfgang Brückl&nbsp;</span></p><p><span style="color: rgb(97, 97, 97)">18:45 – 19:00 Uhr&nbsp;</span></p><p><strong><span style="color: rgb(97, 97, 97)">Diskussion und Ausblick</span></strong></p><p><span style="color: rgb(97, 97, 97)">mit Prof. Dr. med Wolfgang Brückl&nbsp;&amp; Prof Dr. med Eckart Laack&nbsp;</span></p><p></p><p></p><p></p><p>In freundlicher Zusammenarbeit mit</p><p></p><p><img src="https://www.fomf.de/sites/default/files/2024-01/Allianz%20Logo_klein_0.png"></p><p style="text-align: start"><span style="color: rgb(97, 97, 97)">Wir bestätigen, dass&nbsp;die Referenten potentielle Interessenkonflikte gegenüber den Teilnehmern offenlegen. Diese&nbsp;WebUp Sendung wird durch&nbsp;</span><strong><span style="color: rgb(97, 97, 97)">AstraZeneca GmbH </span></strong><span style="color: rgb(97, 97, 97)">und</span><strong><span style="color: rgb(97, 97, 97)"> Daiichi Sankyo Deutschland GmbH</span></strong><span style="color: rgb(97, 97, 97)">&nbsp;unterstützt.<br/>Diese Veranstaltung richtet sich ausschließlich an Ärzt:Innen.</span></p>'
			},
			showAgenda: true,
			showProgramOverview: false,
			showSpeakers: true
		},
		accreditationSettings: {
			description: {
				'de-DE': ''
			},
			showScheme: false,
			showSection: false
		},
		seoSettings: {
			description: {
				'de-DE':
					'Holen Sie sich ein Praxis Update der Therapie des HER2-mutierten NSCLC und nehmen kostenlos an unserem WebUp teil!'
			},
			image: ' https://www.fomf.de/sites/default/files/2024-01/1.png',
			indexSetting: 'NOT_INDEXED',
			title: {
				'de-DE': 'WebUp «Therapie des HER2-mutierten NSCLC» am 12.03.'
			}
		},
		sponsorships: [
			{
				contentId: 'c32aab6b-a69d-438b-9552-26eb857dac61',
				description: {},
				sponsorCompany: {
					id: '04c50247-5856-4930-afa2-8ba45e98fff9',
					name: 'AstraZeneca GmbH',
					logoUrl:
						'https://storage.googleapis.com/bk-fms-eu/public/partner/logo/00107642.png',
					websites: {
						'de-DE': 'https://www.fomf.de/partner-fortbildungsforum/AstraZeneca/all',
						'de-CH': 'https://www.fomf.de/partner-fortbildungsforum/AstraZeneca/all',
						'de-AT': 'https://www.fomf.de/partner-fortbildungsforum/AstraZeneca/all'
					},
					companyPage: {
						slug: 'AstraZeneca',
						publishStatus: 'PUBLISHED'
					}
				},
				sponsorDepartment: null,
				id: 'efed2481-a5f1-466c-aea5-29d9720907e6',
				sponsorCompanyId: '04c50247-5856-4930-afa2-8ba45e98fff9',
				sponsorshipAmount: null,
				sponsorshipType: 'PLATIN',
				virtualExhibition: null,
				symposiumIds: [],
				entireEventSponsor: true,
				participantRestriction: false,
				symposiums: [],
				content: {
					id: 'c32aab6b-a69d-438b-9552-26eb857dac61',
					brand: 'WUIF',
					startDateTime: '2024-03-12T17:00:00.000Z',
					endDateTime: '2024-03-12T18:00:00.000Z',
					contentId: '16165',
					contentType: 'WEBINAR',
					locale: 'de-DE',
					title: 'Praxis-Update: Therapie des HER2-mutierten NSCLC',
					city: {
						'de-DE': 'WebUp',
						'de-AT': 'WebUp',
						'de-CH': 'WebUp',
						'fr-CH': 'WebUp',
						'en-GB': 'WebUp',
						'en-AU': 'WebUp'
					},
					headerUrl: 'https://www.fomf.de/sites/default/files/2024-01/1.png',
					willBeBroadcasted: true,
					rating: null,
					product: {
						name: {
							'de-DE': 'Pneumologie',
							'de-AT': 'Pneumologie',
							'de-CH': 'Pneumologie',
							'fr-CH': 'Pneumologie',
							'en-GB': 'Respiratory Medicine',
							'en-AU': 'Respiratory Medicine'
						},
						description: null,
						specialityIconUrl:
							'https://bk-public-prod.storage.googleapis.com/public/static/speciality/Pneumologie.svg',
						code: 'PNEU',
						id: '88d875b6-66df-448c-b609-14596630c63c',
						country: 'DEU',
						isScientificLeaderEnabled: null,
						descriptionTitle: null,
						targetGroupIds: [],
						benefitIds: [],
						iconPrimaryColor: '#3A9BC3'
					},
					source: null,
					speakers: [],
					externalId: '6833'
				}
			},
			{
				contentId: 'c32aab6b-a69d-438b-9552-26eb857dac61',
				description: {},
				sponsorCompany: {
					id: '2baa1a79-6383-4268-a390-dda17a561cdd',
					name: 'Daiichi Sankyo Deutschland GmbH',
					logoUrl:
						'https://storage.googleapis.com/bk-fms-eu/public/partner/logo/00081943.png',
					websites: {
						'de-DE': 'https://www.fomf.de/partner-fortbildungsforum/Daiichi-Sankyo/all',
						'de-CH': 'https://www.fomf.de/partner-fortbildungsforum/Daiichi-Sankyo/all',
						'de-AT': 'https://www.fomf.de/partner-fortbildungsforum/Daiichi-Sankyo/all'
					},
					companyPage: {
						slug: 'Daiichi-Sankyo',
						publishStatus: 'PUBLISHED'
					}
				},
				sponsorDepartment: null,
				id: '9287fc54-24cd-42c4-8340-49bd9b573856',
				sponsorCompanyId: '2baa1a79-6383-4268-a390-dda17a561cdd',
				sponsorshipAmount: null,
				sponsorshipType: 'PLATIN',
				virtualExhibition: null,
				symposiumIds: [],
				entireEventSponsor: true,
				participantRestriction: false,
				symposiums: [],
				content: {
					id: 'c32aab6b-a69d-438b-9552-26eb857dac61',
					brand: 'WUIF',
					startDateTime: '2024-03-12T17:00:00.000Z',
					endDateTime: '2024-03-12T18:00:00.000Z',
					contentId: '16165',
					contentType: 'WEBINAR',
					locale: 'de-DE',
					title: 'Praxis-Update: Therapie des HER2-mutierten NSCLC',
					city: {
						'de-DE': 'WebUp',
						'de-AT': 'WebUp',
						'de-CH': 'WebUp',
						'fr-CH': 'WebUp',
						'en-GB': 'WebUp',
						'en-AU': 'WebUp'
					},
					headerUrl: 'https://www.fomf.de/sites/default/files/2024-01/1.png',
					willBeBroadcasted: true,
					rating: null,
					product: {
						name: {
							'de-DE': 'Pneumologie',
							'de-AT': 'Pneumologie',
							'de-CH': 'Pneumologie',
							'fr-CH': 'Pneumologie',
							'en-GB': 'Respiratory Medicine',
							'en-AU': 'Respiratory Medicine'
						},
						description: null,
						specialityIconUrl:
							'https://bk-public-prod.storage.googleapis.com/public/static/speciality/Pneumologie.svg',
						code: 'PNEU',
						id: '88d875b6-66df-448c-b609-14596630c63c',
						country: 'DEU',
						isScientificLeaderEnabled: null,
						descriptionTitle: null,
						targetGroupIds: [],
						benefitIds: [],
						iconPrimaryColor: '#3A9BC3'
					},
					source: null,
					speakers: [],
					externalId: '6833'
				}
			}
		],
		partnerships: [],
		credits: [],
		speakers: [],
		parent: null,
		replacementDate: null,
		replacementTitle: null,
		source: null
	},
	scientificLeaders: [],
	programSchedules: [],
	attachments: [],
	department: {
		id: 'efed2481-a5f1-466c-aea5-29d9720907e6',
		name: null,
		logoUrl: null
	},
	eventCost: null,
	subtitle: null,
	id: '13f1f633-3f9e-4c64-b740-e32c8508af5c',
	slug: null,
	brand: 'WUIF',
	endDateTime: '2024-03-12T18:00:00.000Z',
	externalId: '6833-8',
	contentId: '17569',
	contentType: 'LECTURE',
	startDateTime: '2024-03-12T17:45:00.000Z',
	willBeBroadcasted: null,
	willBeOnDemand: true,
	isFree: false,
	isCommercial: false,
	isAvailableOnHomepage: true,
	isAvailableOnMediathek: true,
	isAvailableWithoutEfn: false,
	participationOption: null,
	capacityStatus: null,
	locale: 'de-DE',
	description: null,
	descriptionTitle: null,
	title: 'Diskussion und Ausblick ',
	city: {
		'de-DE': 'WebUp',
		'de-AT': 'WebUp',
		'de-CH': 'WebUp',
		'fr-CH': 'WebUp',
		'en-GB': 'WebUp',
		'en-AU': 'WebUp'
	},
	order: 8,
	isSponsored: true,
	rating: null,
	contentProgress: {
		completed: null,
		contentId: '17569',
		time: null,
		trackedDateTime: null
	},
	contentUnits: [],
	membershipSettings: {
		availableForMembers: true
	},
	detailsPageSettings: null,
	agendaSettings: null,
	accreditationSettings: null,
	seoSettings: null,
	sponsorships: [
		{
			contentId: 'c32aab6b-a69d-438b-9552-26eb857dac61',
			description: {},
			sponsorCompany: {
				id: '04c50247-5856-4930-afa2-8ba45e98fff9',
				name: 'AstraZeneca GmbH',
				logoUrl:
					'https://storage.googleapis.com/bk-fms-eu/public/partner/logo/00107642.png',
				websites: {
					'de-DE': 'https://www.fomf.de/partner-fortbildungsforum/AstraZeneca/all',
					'de-CH': 'https://www.fomf.de/partner-fortbildungsforum/AstraZeneca/all',
					'de-AT': 'https://www.fomf.de/partner-fortbildungsforum/AstraZeneca/all'
				},
				companyPage: {
					slug: 'AstraZeneca',
					publishStatus: 'PUBLISHED'
				}
			},
			sponsorDepartment: null,
			id: 'efed2481-a5f1-466c-aea5-29d9720907e6',
			sponsorCompanyId: '04c50247-5856-4930-afa2-8ba45e98fff9',
			sponsorshipAmount: null,
			sponsorshipType: 'PLATIN',
			virtualExhibition: null,
			symposiumIds: [],
			entireEventSponsor: true,
			participantRestriction: false,
			symposiums: [],
			content: {
				id: 'c32aab6b-a69d-438b-9552-26eb857dac61',
				brand: 'WUIF',
				startDateTime: '2024-03-12T17:00:00.000Z',
				endDateTime: '2024-03-12T18:00:00.000Z',
				contentId: '16165',
				contentType: 'WEBINAR',
				locale: 'de-DE',
				title: 'Praxis-Update: Therapie des HER2-mutierten NSCLC',
				city: {
					'de-DE': 'WebUp',
					'de-AT': 'WebUp',
					'de-CH': 'WebUp',
					'fr-CH': 'WebUp',
					'en-GB': 'WebUp',
					'en-AU': 'WebUp'
				},
				headerUrl: 'https://www.fomf.de/sites/default/files/2024-01/1.png',
				willBeBroadcasted: true,
				rating: null,
				product: {
					name: {
						'de-DE': 'Pneumologie',
						'de-AT': 'Pneumologie',
						'de-CH': 'Pneumologie',
						'fr-CH': 'Pneumologie',
						'en-GB': 'Respiratory Medicine',
						'en-AU': 'Respiratory Medicine'
					},
					description: null,
					specialityIconUrl:
						'https://bk-public-prod.storage.googleapis.com/public/static/speciality/Pneumologie.svg',
					code: 'PNEU',
					id: '88d875b6-66df-448c-b609-14596630c63c',
					country: 'DEU',
					isScientificLeaderEnabled: null,
					descriptionTitle: null,
					targetGroupIds: [],
					benefitIds: [],
					iconPrimaryColor: '#3A9BC3'
				},
				source: null,
				speakers: [],
				externalId: '6833'
			}
		},
		{
			contentId: 'c32aab6b-a69d-438b-9552-26eb857dac61',
			description: {},
			sponsorCompany: {
				id: '2baa1a79-6383-4268-a390-dda17a561cdd',
				name: 'Daiichi Sankyo Deutschland GmbH',
				logoUrl:
					'https://storage.googleapis.com/bk-fms-eu/public/partner/logo/00081943.png',
				websites: {
					'de-DE': 'https://www.fomf.de/partner-fortbildungsforum/Daiichi-Sankyo/all',
					'de-CH': 'https://www.fomf.de/partner-fortbildungsforum/Daiichi-Sankyo/all',
					'de-AT': 'https://www.fomf.de/partner-fortbildungsforum/Daiichi-Sankyo/all'
				},
				companyPage: {
					slug: 'Daiichi-Sankyo',
					publishStatus: 'PUBLISHED'
				}
			},
			sponsorDepartment: null,
			id: '9287fc54-24cd-42c4-8340-49bd9b573856',
			sponsorCompanyId: '2baa1a79-6383-4268-a390-dda17a561cdd',
			sponsorshipAmount: null,
			sponsorshipType: 'PLATIN',
			virtualExhibition: null,
			symposiumIds: [],
			entireEventSponsor: true,
			participantRestriction: false,
			symposiums: [],
			content: {
				id: 'c32aab6b-a69d-438b-9552-26eb857dac61',
				brand: 'WUIF',
				startDateTime: '2024-03-12T17:00:00.000Z',
				endDateTime: '2024-03-12T18:00:00.000Z',
				contentId: '16165',
				contentType: 'WEBINAR',
				locale: 'de-DE',
				title: 'Praxis-Update: Therapie des HER2-mutierten NSCLC',
				city: {
					'de-DE': 'WebUp',
					'de-AT': 'WebUp',
					'de-CH': 'WebUp',
					'fr-CH': 'WebUp',
					'en-GB': 'WebUp',
					'en-AU': 'WebUp'
				},
				headerUrl: 'https://www.fomf.de/sites/default/files/2024-01/1.png',
				willBeBroadcasted: true,
				rating: null,
				product: {
					name: {
						'de-DE': 'Pneumologie',
						'de-AT': 'Pneumologie',
						'de-CH': 'Pneumologie',
						'fr-CH': 'Pneumologie',
						'en-GB': 'Respiratory Medicine',
						'en-AU': 'Respiratory Medicine'
					},
					description: null,
					specialityIconUrl:
						'https://bk-public-prod.storage.googleapis.com/public/static/speciality/Pneumologie.svg',
					code: 'PNEU',
					id: '88d875b6-66df-448c-b609-14596630c63c',
					country: 'DEU',
					isScientificLeaderEnabled: null,
					descriptionTitle: null,
					targetGroupIds: [],
					benefitIds: [],
					iconPrimaryColor: '#3A9BC3'
				},
				source: null,
				speakers: [],
				externalId: '6833'
			}
		}
	],
	partnerships: [],
	benefits: [],
	targetGroups: [],
	product: null,
	credits: [],
	replacementDate: null,
	replacementTitle: null,
	source: {
		thumbnail:
			'https://i.vimeocdn.com/video/1819027168-f1631801f1742d428ee186e5f89c955190ad6ddf3de78e66787bfd82b47c707a-d',
		duration: 852,
		file: null,
		url: 'https://vimeo.com/925383451'
	}
};
