import React from 'react';

import classNames from 'classnames';
import { Icon, mergeClasses, Typography } from 'components';

import useStyles from './BulletPoint.styles';
import { BulletPointProps } from './BulletPoint.types';
import { Box } from '@mui/material';

const BulletPoint = (props: BulletPointProps) => {
	const classes = mergeClasses(useStyles(props), props.classes);
	const {
		color = 'success',
		iconColor,
		icon,
		variant = 'textSmall',
		localeId,
		localeValues,
		text,
		colorVariant
	} = props;

	if (!text && !localeId) {
		return null;
	}
	return (
		<div className={classes.container}>
			{typeof icon === 'string' ? (
				<Icon
					className={classNames(classes.icon, iconColor && classes[iconColor])}
					icon={icon}
				/>
			) : (
				<Box className={classes.icon} />
			)}
			<Typography
				variant={variant}
				localeId={localeId ? localeId : ''}
				localeValues={localeValues}
				title={text}
				color={color}
				colorVariant={colorVariant}
				className={classes.text}
			/>
		</div>
	);
};

export default BulletPoint;
