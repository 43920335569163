import React from 'react';
import { Box } from '@mui/material';

import { Fragments, Speaker } from '../..';
import { SpeakerProps } from '../Speaker/Speaker.types';

export type SpeakersProps = Omit<SpeakerProps, 'speaker'> & {
	speakers: Array<Fragments.SpeakerFieldsFragment> | null | undefined;
	flexDirection?: 'row' | 'column';
	className?: string;
	hideDescription?: boolean;
};

/**
 * This component is used to display a list of speakers.
 * It accepts the props of the Speaker component to customize the single speaker.
 */
const Speakers = (props: SpeakersProps) => {
	const { speakers, className, flexDirection = 'column', ...others } = props;

	if (!speakers || speakers.length === 0) {
		return null;
	}
	return (
		<Box display="flex" flexDirection={flexDirection} className={className}>
			{speakers.map((speaker, index) => {
				const handleRenderText = (speaker: SpeakerProps['speaker']) => {
					const suffix =
						flexDirection === 'row' && index < speakers.length - 1 ? ',' : '';
					return (
						<span>
							{speaker?.title || ''} {speaker?.name || ''}
							{suffix}&nbsp;
						</span>
					);
				};
				return (
					<Speaker
						key={speaker.id}
						speaker={speaker}
						renderText={handleRenderText}
						{...others}
					/>
				);
			})}
		</Box>
	);
};

export default Speakers;
