import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes } from '../';

const backgroundReadonly = (theme: ThemeTypes.Theme) => theme.palette.accent.main;
const backgroundDisabled = (theme: ThemeTypes.Theme) => theme.palette.background.default;
const backgroundActive = (theme: ThemeTypes.Theme) => theme.palette.offWhite.main;
const colorInactive = (theme: ThemeTypes.Theme) => theme.palette.label.main;
const colorActive = (theme: ThemeTypes.Theme) => theme.palette.primary.main;
const colorActiveDark = (theme: ThemeTypes.Theme) => theme.palette.titleActive.main;

const useStyles = makeStyles(
	(theme) => ({
		root: {},
		readOnly: {
			'& $inputBaseRoot, &:hover $inputBaseRoot, & $inputBaseRoot.Mui-focused': {
				background: backgroundReadonly(theme)
			},
			'& $inputBaseRoot $notchedOutline, &:hover $inputBaseRoot $notchedOutline, & $inputBaseRoot.Mui-focused $notchedOutline':
				{
					borderColor: backgroundReadonly(theme)
				},

			'& $outlinedLabel.Mui-focused': {
				color: colorInactive(theme)
			}
		},
		disabled: {
			opacity: 1,
			'& $inputBaseRoot, &:hover $inputBaseRoot, & $inputBaseRoot.Mui-focused': {
				background: backgroundDisabled(theme)
			},
			'& $inputBaseRoot $notchedOutline, &:hover $inputBaseRoot $notchedOutline, & $inputBaseRoot.Mui-focused $notchedOutline':
				{
					borderColor: backgroundDisabled(theme)
				},

			'& $outlinedLabel.Mui-focused': {
				color: colorInactive(theme)
			}
		},
		input: {
			padding: '27px 14px 5px 24px',
			height: 'auto',
			...theme.typography.textSmall,

			'&$withStartAdornment': {
				paddingLeft: 8
			},

			'&$small': {
				height: '100%',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				padding: 0,
				paddingLeft: theme.spacing(1.5)
			},

			'&$autocomplete': {
				// important here because we can't override some mui styles without it
				padding: '15px 15px 0 !important'
			},

			'&:not(:focus)': {
				'&::placeholder': {
					visibility: 'hidden'
				}
			}
		},
		inputBaseRoot: {
			color: colorActiveDark(theme),

			'& .MuiMenuItem-root': {
				'&:hover': {
					background: 'none'
				}
			},

			'&:hover': {
				backgroundColor: backgroundActive(theme),

				'& $notchedOutline': {
					borderColor: colorActive(theme)
				}
			},

			'&.Mui-focused': {
				backgroundColor: backgroundActive(theme),

				'& $notchedOutline': {
					borderColor: colorActiveDark(theme),
					borderWidth: 1
				}
			},

			'&.Mui-error': {
				'&, &:hover, &.Mui-focused': {
					'& $notchedOutline': {
						borderColor: theme.palette.error.main
					}
				}
			},

			'&$success': {
				'&, &:hover, &.Mui-focused': {
					'& $notchedOutline': {
						borderColor: theme.palette.success.main
					}
				}
			},

			'&$noWrapInput': {
				flexWrap: 'nowrap'
			}
		},
		outlinedLabel: {
			color: colorInactive(theme),
			...theme.typography.textSmall,
			transform: 'translate(24px, 18px)',

			'&.Mui-focused': {
				color: colorActive(theme)
			},

			'&.MuiInputLabel-shrink': {
				...theme.typography.textXSmall,
				transform: 'translate(24px, 5px)',

				'&$withStartAdornment': {
					transform: 'translate(46px, 5px)',

					'&$emptyValue:not(.Mui-focused)': {
						...theme.typography.textSmall,
						transform: 'translate(46px, 18px)'
					}
				}
			},

			'&$success': {
				color: theme.palette.success.main
			}
		},
		icon: {
			width: 24,
			height: 24,
			color: colorInactive(theme)
		},
		startIcon: {
			marginLeft: 0
		},
		closeIcon: {
			'&:hover': {
				color: colorActive(theme)
			},
			display: 'none'
		},
		endIcon: {},
		endIconVisible: {
			display: 'block',
			cursor: 'pointer'
		},
		adornment: {
			// zIndex: 1
		},
		startAdornment: {
			margin: 0,
			minWidth: 24,
			minHeight: 24,
			flexShrink: 0,
			justifyContent: 'flex-start',
			flexWrap: 'wrap',
			maxWidth: '80%',
			height: 'auto',
			maxHeight: 'none',
			cursor: 'default'
		},
		selectIconToRight: {
			right: 12
		},
		notchedOutline: {
			borderColor: colorInactive(theme),

			'&>legend': {
				maxWidth: 0
			}
		},
		helperText: {
			'&$success': {
				color: theme.palette.success.main
			}
		},
		withStartAdornment: {},
		emptyValue: {},
		small: {},
		success: {},
		noWrapInput: {},
		autocomplete: {}
	}),
	{ name: 'TextField', index: 1 }
);

export default useStyles;
