import env from '../env';
import web, { setTypewriterOptions } from './web/segment';

import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { Integrations } from '@segment/analytics-core';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import sortedUniqBy from 'lodash/sortedUniqBy';
import { getSHA256Hash } from '../utils/getSHA256Hash';

const thirdPartyLoadTimeout = 3000;

type ConsentCategory = {
	status: 'on' | 'unset';
	required?: boolean;
};

type Consent = {
	categories: {
		[key: string]: ConsentCategory;
	};
	status: 'unset';
	vendors: Record<string, unknown>; // You can further specify the type of vendors if needed
};

type Meta = {
	bannerId: string;
	bannerVersion: string;
	consentId: string;
	dateCreated: null | string; // Assuming date is a string, adjust if needed
	dateExpires: null | string; // Assuming date is a string, adjust if needed
	dateUpdated: null | string; // Assuming date is a string, adjust if needed
	siteId: string;
	version: string;
};

type ConsentResultType = {
	meta: Meta;
	consent: Consent;
};

type DestinationPreferencesType = {
	Marketing: boolean;
	Analytics: boolean;
	Functional: boolean;
	'Heatmaps & Recordings': boolean;
	Surveys: boolean;
	Advertising: boolean;
	'Feature Flagging': boolean;
};

interface Destination {
	id: string;
	name: string;
	creationName: string;
	description: string;
	website: string;
	category: string;
}

let analytics: AnalyticsBrowser;

const envValue = env.ENV;
const FUNCTIONAL_ID = 1;
const ANALYSE_ID = 2;
const MARKETING_ID = 3;

const GOOGLE_ANALYTICS_4_WEB_INTEGRATION = 'Google Analytics 4 Web';

const destinationPreferences: DestinationPreferencesType = {
	Analytics: false,
	Marketing: false,
	Functional: false,
	'Heatmaps & Recordings': false,
	Surveys: false,
	Advertising: false,
	'Feature Flagging': false
};

function extractDestinationPreferences(result: ConsentResultType): void {
	const ANALYTICS_ENABLED = result.consent.categories[ANALYSE_ID]?.status === 'on' || false;
	const MARKETING_ENABLED = result.consent.categories[MARKETING_ID]?.status === 'on' || false;
	const FUNCTIONAL_ENABLED = result.consent.categories[FUNCTIONAL_ID]?.status === 'on' || false;

	destinationPreferences.Analytics = ANALYTICS_ENABLED;
	destinationPreferences.Marketing = MARKETING_ENABLED;
	destinationPreferences.Functional = FUNCTIONAL_ENABLED;
	destinationPreferences['Heatmaps & Recordings'] = ANALYTICS_ENABLED;
	destinationPreferences.Surveys = ANALYTICS_ENABLED;
	destinationPreferences.Advertising = MARKETING_ENABLED;
	destinationPreferences['Feature Flagging'] = ANALYTICS_ENABLED;
}

export const setGoogleAnalyticsConsent = (
	mode: 'default' | 'update',
	value: Record<
		'ad_storage' | 'ad_user_data' | 'ad_personalization' | 'analytics_storage',
		'granted' | 'denied'
	>
) => {
	window.dataLayer = window.dataLayer || [];
	window.gtag =
		window.gtag ||
		function () {
			// eslint-disable-next-line prefer-rest-params
			window.dataLayer?.push(arguments as unknown as Record<string, unknown>);
		};

	window.gtag('consent', mode, value);
};

async function fetchDestinationForWriteKey(
	cdnHost: string,
	writeKey: string
): Promise<Array<Destination>> {
	const res = await fetch(`https://${cdnHost}/v1/projects/${writeKey}/integrations`);

	if (!res.ok) {
		throw new Error(
			`Failed to fetch integrations for write key ${writeKey}: HTTP ${res.status} ${res.statusText}`
		);
	}

	const destinations = await res.json();

	// Rename creationName to id to abstract the weird data model
	for (const destination of destinations) {
		// Because of the legacy Fullstory integration the creationName for this integration is the `name`
		if (destination.name === 'Fullstory') {
			destination.id = destination.name;
		} else {
			destination.id = destination.creationName;
		}
		delete destination.creationName;
	}

	return destinations;
}

async function fetchDestinations(
	cdnHost: string,
	writeKeys: Array<string>
): Promise<Array<Destination>> {
	const destinationsRequests: Array<Promise<Array<Destination>>> = [];
	for (const writeKey of writeKeys) {
		destinationsRequests.push(fetchDestinationForWriteKey(cdnHost, writeKey));
	}

	let destinations = flatten(await Promise.all(destinationsRequests));
	// Remove the dummy Repeater destination
	destinations = destinations.filter((d) => d.id !== 'Repeater');
	destinations = sortBy(destinations, ['id']);
	destinations = sortedUniqBy(destinations, 'id');
	return destinations;
}

function loadAnalytics(props: {
	writeKey: string;
	integrations: Integrations;
	locale: string;
	isLoggedIn: boolean;
	email: string | undefined;
}) {
	const { writeKey, integrations, locale, isLoggedIn, email } = props;
	analytics = AnalyticsBrowser.load({ writeKey }, { integrations });

	if (envValue !== 'prod') {
		analytics.addSourceMiddleware(({ payload, next }) => {
			const { event: eventTitle, properties, type } = payload.obj;
			const products = properties?.products || [];
			const eventSubTitle = products[0]?.product_list_name;
			// eslint-disable-next-line no-console
			console.debug(
				'--- SEGMENT TRACKING ---\n',
				[type + ':', eventTitle, eventSubTitle].filter(Boolean).join(' '),
				'\n',
				properties,
				'\n',
				payload
			);
			next(payload);
		});
	}

	// passing common props to event properties
	analytics.addSourceMiddleware(async ({ payload, next }) => {
		if (!payload.obj.properties) {
			payload.obj.properties = {};
		}

		const { properties } = payload.obj;
		properties.locale = locale;
		properties.isLoggedIn = isLoggedIn;

		if (email) {
			properties.hashed_e = await getSHA256Hash(email);
		}

		next(payload);
	});

	/*
	 * Some destinations like Facebook requires different field names, but there is no way to set it up in destination settings.
	 * So we will duplicate such fields in code.
	 */
	analytics.addSourceMiddleware(({ payload, next }) => {
		if (!payload.obj.properties) {
			payload.obj.properties = {};
		}

		const { properties } = payload.obj;

		if (properties.category) {
			properties.content_category = properties.category;
		}

		if (properties.products) {
			properties.products.forEach(
				(product: { category?: string; content_category?: string }) => {
					if (product.category) {
						product.content_category = product.category;
					}
				}
			);
		}

		next(payload);
	});

	// Pass the analytics instance to the typewriter ecommerce tracking
	setTypewriterOptions({ analytics });
}

export function loadAnalyticsWithConsent(props: {
	locale: Locale;
	isLoggedIn: boolean;
	email: string | undefined;
}) {
	const { locale, isLoggedIn, email } = props;
	const writeKey = env.SEGMENT_WRITE_KEY_BY_LOCALE(locale) ?? '';

	if (!writeKey) {
		console.error('Error: Segment write key is not provided or is empty');
		return;
	}

	if (envValue != 'prod') {
		loadAnalytics({
			writeKey,
			integrations: { All: true, 'Segment.io': true },
			locale,
			isLoggedIn,
			email
		});
		return;
	}

	const checkWindowAndCact = () => {
		if (window && typeof window.cact === 'function') {
			clearInterval(intervalId); // Remove the interval once window and cact are available
			const doLoad = (result: ConsentResultType) => {
				extractDestinationPreferences(result);
				fetchDestinations('cdn.segment.com', [writeKey]).then(
					(destinations: Array<Destination>) => {
						const integrations: Integrations = { All: false, 'Segment.io': true };
						let isAnythingEnabled = false;

						for (const destination of destinations) {
							// Was a preference explicitly set on this destination?
							const destinationPreferenceEnabled: boolean =
								destinationPreferences[
									destination.category as keyof DestinationPreferencesType
								];
							if (typeof destinationPreferenceEnabled === 'undefined') {
								console.error(
									`Segment Error: Destination preference mapping is not set for ${destination.name}`
								);
							}
							const isEnabled = Boolean(destinationPreferenceEnabled);
							if (isEnabled) {
								isAnythingEnabled = true;
							}
							integrations[destination.id] = isEnabled;

							if (destination.id === GOOGLE_ANALYTICS_4_WEB_INTEGRATION) {
								setGoogleAnalyticsConsent('update', {
									ad_storage: destinationPreferences.Marketing
										? 'granted'
										: 'denied',
									ad_user_data: destinationPreferences.Marketing
										? 'granted'
										: 'denied',
									ad_personalization: destinationPreferences.Marketing
										? 'granted'
										: 'denied',
									analytics_storage: destinationPreferences.Analytics
										? 'granted'
										: 'denied'
								});
							}
						}

						if (isAnythingEnabled) {
							loadAnalytics({
								writeKey,
								integrations,
								locale,
								isLoggedIn,
								email
							});
						}
					}
				);
			};

			window.cact('consent.get', doLoad);
			window.cact('consent.onUpdate', doLoad);
		}
	};

	const intervalId = setInterval(checkWindowAndCact, 100);
	// Set a timeout to stop checking after a maximum of 3 seconds
	setTimeout(() => {
		clearInterval(intervalId);
	}, thirdPartyLoadTimeout);
}

/**
 * Function useful to get the analytics instance.
 *
 * @returns the analytics instance that contains all the segment analytics functions and the
 * ecommerce tracking object with all the typed helpers.
 */

let analyticsPromise: Promise<AnalyticsBrowser | undefined>;
export type SegmentAnalytics = { segment: Analytics; web: typeof web };
export async function getAnalytics(): Promise<SegmentAnalytics | undefined> {
	let retries = 0;
	const maxRetries = 30;

	analyticsPromise = new Promise((resolve) => {
		const checkAnalyticsLoaded = () => {
			if (analytics) {
				resolve(analytics);
			} else if (retries < maxRetries) {
				retries++;
				setTimeout(checkAnalyticsLoaded, 100);
			} else {
				resolve(undefined);
			}
		};

		checkAnalyticsLoaded();
	});

	return analyticsPromise
		.then((loadedAnalytics) => loadedAnalytics)
		.then((analytics) => (analytics ? { segment: analytics[0], web } : undefined));
}
