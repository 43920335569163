import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		container: {
			backgroundColor: theme.palette.body.light
		},
		rectText: {
			borderRadius: 2
		}
	}),
	{ name: 'Skeleton' }
);

export default useStyles;
