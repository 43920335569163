import React from 'react';
import { Tab as MUITab } from '@mui/material';

import { TabProps } from './Tab.types';
import { Typography } from '../index';

const Tab = (props: TabProps) => {
	const { localeId, label, value, ...rest } = props;
	return (
		<MUITab
			label={
				<Typography
					localeId={!localeId && !label && value ? value : localeId}
					title={label}
					variant="linkXSmall"
				/>
			}
			value={value}
			{...rest}
		/>
	);
};

export default Tab;
