import * as yup from 'yup';
import { UserValidation } from '../..';

export const getPersonalInfoValidationSchema = () =>
	yup.object({
		...UserValidation.gender(),
		...UserValidation.academicDegree(),
		firstName: UserValidation.name(),
		lastName: UserValidation.name(),
		street: yup.string().required(),
		apartmentNumber: yup.string().required(),
		postalCode: yup.string().required(),
		city: yup.string().required(),
		country: yup.string().required()
	});
