import { Types } from '..';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';

type Address = Types.AddressR | Types.AddressFormik | null | undefined;

const areAddressesEqual = (address1: Address, address2: Address): boolean =>
	_isEqual(
		_omit(address1, 'id', '__typename', 'toDelete'),
		_omit(address2, 'id', '__typename', 'toDelete')
	);

export default areAddressesEqual;
