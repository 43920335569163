import React from 'react';
import { Loader } from 'components';

import { useUserContext } from './contexts';

import { ChangeURL, hydra, setStateInCookies, useChangeURL, USER_URLS, useRouter } from '..';
import { useIntl } from 'react-intl';

interface RedirectAnonymous {
	tenantId?: string | null;
	changeURL: ChangeURL;
	redirectAnonymousToSignUp?: boolean | undefined;
	nonce?: string;
	locale: Locale;
}
export const redirectAnonymous = ({
	locale,
	redirectAnonymousToSignUp,
	nonce,
	changeURL,
	tenantId = ''
}: RedirectAnonymous) => {
	const nonceToUse = nonce ? nonce : setStateInCookies({});
	if (redirectAnonymousToSignUp) {
		changeURL(USER_URLS.registration({ nonce: nonceToUse }), 'catalog');
	} else {
		changeURL(hydra.getAuthURL({ nonce: nonceToUse, tenantId: tenantId || undefined, locale }));
	}
};

/**
 * High Order Component for providing User Id when logged in
 * @param Component - Component to wrap
 * @param onError - Callback to call if user is not logged in
 *
 * @returns Enhanced Component with `userId` in props
 */
const withUser = <ComponentProps extends { userId: string; redirectAnonymousToSignUp?: boolean }>(
	Component: React.ComponentType<ComponentProps>,
	onError: ((tenantId?: string) => void) | undefined
): React.ComponentType<Omit<ComponentProps, 'userId'>> => {
	return (props: Record<string, unknown>) => {
		const componentProps = props as ComponentProps;
		const { isLoading, isError, user } = useUserContext();
		const router = useRouter();
		const { locale } = useIntl();
		const tenantId = String(router ? router.query.tenantId : '');
		const changeURL = useChangeURL();

		if (isLoading || (router && !router.isReady)) {
			return <Loader fullScreen />;
		}

		if (isError) {
			onError =
				onError ||
				((tenantId: string | undefined) =>
					redirectAnonymous({
						tenantId,
						changeURL,
						redirectAnonymousToSignUp: componentProps.redirectAnonymousToSignUp,
						locale
					}));
			onError(tenantId);

			return <Loader fullScreen />;
		}

		const userId = user?.id;

		return <Component {...componentProps} userId={userId} />;
	};
};

export default withUser;
