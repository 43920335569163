import React, { useEffect, useState, useRef } from 'react';
import { List, ListItem } from '@mui/material';

import { Button } from '..';
import useStyles from './VerticalList.styles';
import { VerticalListProps } from './VerticalList.types';
import Loader from '../Loader/Loader';

import { useInfiniteScroll } from '../../../common/src';

const VerticalList = <T extends { id: string }>(props: VerticalListProps<T>) => {
	const {
		generateItemKey,
		headerComponent = null,
		items: itemsProps,
		renderItem,
		offset: offsetProp,
		pageSize: pageSizeProp,
		onOffsetChange,
		totalCount: totalCountProp,
		mode = 'uncontrolled',
		variant,
		loading,
		listRef
	} = props;
	const classes = useStyles(props);
	const [items, setItems] = useState<Array<T>>(mode === 'controlled' ? itemsProps : []);

	// if variant === 'body-scroll' we will use some event which is not supported by react
	// then we need to use useRef to be able to access to the current values of offset,
	// pageSize and totalCount
	// https://stackoverflow.com/a/55265764/2443849
	const offset = useRef(offsetProp);
	const pageSize = useRef(pageSizeProp);
	const totalCount = useRef(totalCountProp);

	const handleFetchMore = async () => {
		if (onOffsetChange) {
			onOffsetChange(offset.current + pageSize.current);
		}
	};

	useEffect(() => {
		if (mode === 'controlled') {
			setItems(itemsProps);
		} else {
			setItems([
				...items,
				/**
				 * WARNING: The following filter is useful for avoiding duplicates. Note that we are looping
				 * the array multiple times because the "every" is a nested iteration for each item. This could
				 * cause performance issues if this component is used on mobile version with hundreds of items.
				 */
				...itemsProps.filter((item) =>
					items.every((currentItem) => currentItem.id !== item.id)
				)
			]);
		}
	}, [itemsProps]);

	const handleRenderItem = (item: T, index: number) => {
		return (
			<ListItem
				className={classes.item}
				key={generateItemKey ? generateItemKey(item) : item.id}
			>
				{renderItem(item, index)}
			</ListItem>
		);
	};

	useEffect(() => {
		offset.current = offsetProp;
	}, [offsetProp]);

	useEffect(() => {
		pageSize.current = pageSizeProp;
	}, [pageSizeProp]);

	useEffect(() => {
		totalCount.current = totalCountProp;
	}, [totalCountProp]);

	const moreItemsAvailable = totalCount.current > offset.current + pageSize.current;
	const { isFetching } = useInfiniteScroll({
		moreItemsAvailable,
		onFetchMore: handleFetchMore,
		onFetchMoreArgs: {}
	});

	const isLoading = loading || isFetching;

	return (
		<List className={classes.list} ref={listRef}>
			{headerComponent}
			{items.map(handleRenderItem)}
			{moreItemsAvailable && variant === 'load-more-button' && !isLoading && (
				<Button
					variant="outlined"
					onClick={handleFetchMore}
					className={classes.loadMoreButton}
					typographyProps={{ variant: 'textXSmall', color: 'primary' }}
					localeId="components.vertical-list.load-more-button.text"
				/>
			)}
			{((moreItemsAvailable && variant !== 'load-more-button') || isLoading) && (
				<Loader className={classes.loader} />
			)}
		</List>
	);
};

export default VerticalList;
