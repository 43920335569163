/* eslint-disable no-console */

const bindToConsole = (
	consoleMethod: (...args: Array<unknown>) => void,
	polyfill: (...args: Array<unknown>) => void
) => {
	return consoleMethod ? consoleMethod.bind(console) : polyfill;
};

const logging = (() => {
	let prefix = '';

	const consoleLog = (...args: Array<unknown>) => {
		console.log(prefix, ...args);
	};

	const consoleError = (...args: Array<unknown>) => {
		console.error(prefix, ...args);
	};

	const consoleGroup = (...args: Array<unknown>) => {
		consoleLog(...args);
		prefix += '> ';
	};

	const consoleGroupEnd = () => {
		prefix = prefix.slice(0, -2);
	};

	return {
		log: consoleLog,
		error: consoleError,
		group: bindToConsole(console.group, consoleGroup),
		groupCollapsed: bindToConsole(console.groupCollapsed, consoleGroup),
		groupEnd: bindToConsole(console.groupEnd, consoleGroupEnd)
	};
})();

export default logging;
