import { BOOKING_URLS, OGDict, env } from 'common';
import { IntlShape } from 'react-intl';

const PathnameOG = {
	home: '/',
	livestream: '/livestream',
	mediathekSumedUp: '/mediathek/summedup',
	mediathekWebup: '/mediathek/webup',
	memberships: BOOKING_URLS.memberships
};

type PathnameOG = (typeof PathnameOG)[keyof typeof PathnameOG];

const getOgDictionary = (intl: IntlShape): Record<PathnameOG, OGDict> => {
	const { formatMessage } = intl;
	const homeTitle = formatMessage({ id: 'catalog.seo-og.home.title' });
	const homeDescription = formatMessage({ id: 'catalog.seo-og.home.description' });
	const homeImage = formatMessage({ id: 'catalog.og.home.image' });

	const livestreamTitle = formatMessage({ id: 'catalog.seo-og.livestream.title' });
	const livestreamDescription = formatMessage({ id: 'catalog.seo-og.livestream.description' });
	const livestreamImage = formatMessage({ id: 'catalog.seo-og.livestream.image' });

	const summedUpTitle = formatMessage({ id: 'catalog.seo-og.summedup.title' });
	const summedUpDescription = formatMessage({ id: 'catalog.seo-og.summedup.description' });
	const summedUpImage = formatMessage({ id: 'catalog.seo-og.summedup.image' });

	const webupTitle = formatMessage({ id: 'catalog.seo-og.webup.title' });
	const webupDescription = formatMessage({ id: 'catalog.seo-og.webup.description' });
	const webupImage = formatMessage({ id: 'catalog.seo-og.webup.image' });

	const bookingMembershipsTitle = formatMessage({
		id: 'booking.seo-og.booking-memberships.title'
	});
	const bookingMembershipsDescription = formatMessage({
		id: 'booking.seo-og.booking-memberships.description'
	});
	const bookingMembershipsImage = formatMessage({ id: 'booking.og.booking-memberships.image' });

	if (
		!homeTitle ||
		!homeDescription ||
		!livestreamTitle ||
		!livestreamDescription ||
		!summedUpTitle ||
		!summedUpDescription ||
		!webupTitle ||
		!webupDescription ||
		!bookingMembershipsTitle ||
		!bookingMembershipsDescription
	) {
		throw new Error('Open Graph information is missing');
	}

	return {
		[PathnameOG.home]: {
			title: homeTitle,
			description: homeDescription,
			image: homeImage,
			url: env.ENV === 'staging' ? 'https://staging.fomf.de' : 'https://fomf.de'
		},
		[PathnameOG.livestream]: {
			title: livestreamTitle,
			description: livestreamDescription,
			image: livestreamImage,
			url: env.ENV === 'staging' ? 'https://staging.fomf.de' : 'https://fomf.de'
		},
		[PathnameOG.mediathekSumedUp]: {
			title: summedUpTitle,
			description: summedUpDescription,
			image: summedUpImage,
			url: env.ENV === 'staging' ? 'https://staging.fomf.de' : 'https://fomf.de'
		},
		[PathnameOG.mediathekWebup]: {
			title: webupTitle,
			description: webupDescription,
			image: webupImage,
			url: env.ENV === 'staging' ? 'https://staging.fomf.de' : 'https://fomf.de'
		},
		[PathnameOG.memberships]: {
			title: bookingMembershipsTitle,
			description: bookingMembershipsDescription,
			image: bookingMembershipsImage,
			url:
				env.ENV === 'staging'
					? `https://staging.fomf.de${BOOKING_URLS.memberships}`
					: `https://fomf.de${BOOKING_URLS.memberships}`
		}
	};
};

export default getOgDictionary;
