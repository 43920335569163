import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import useStyles from './TitleTooltip.styles';
import { TitleTooltipProps } from './TitleTooltip.types';
import _debounce from 'lodash/debounce';

const TitleTooltip = (props: TitleTooltipProps) => {
	const { title, children } = props;
	const classes = useStyles();
	const textRef = useRef<HTMLDivElement>(null);
	const [isTruncated, setIsTruncated] = useState(true);

	useEffect(() => {
		const checkIfTruncated = () => {
			const element = textRef?.current?.firstChild as HTMLElement;
			if (element) {
				const tolerance = 2;
				const { clientHeight, scrollHeight } = element;

				if (scrollHeight - clientHeight > tolerance) {
					setIsTruncated(true);
				} else {
					setIsTruncated(false);
				}
			}
		};

		const debouncedCheckIfTruncated = _debounce(checkIfTruncated, 300);

		checkIfTruncated();
		window.addEventListener('resize', debouncedCheckIfTruncated);

		return () => {
			window.removeEventListener('resize', debouncedCheckIfTruncated);
		};
	}, [textRef]);

	if (!title || !isTruncated) {
		return <>{children}</>;
	}

	return (
		<Tooltip
			classes={{
				tooltip: classes.tooltip,
				popper: classes.popper
			}}
			PopperProps={{
				modifiers: [
					{
						name: 'offset',
						options: { offset: [0, -10] }
					}
				]
			}}
			placement="top"
			title={title}
		>
			<div ref={textRef}>{children}</div>
		</Tooltip>
	);
};

export default TitleTooltip;
