import debugLog from '../utils/debugLog';
import setStateInCookies from './setStateInCookies';
import { CookiesState } from '.';
import getState from './getState';

const getStateByNonce = (nonce: string): CookiesState[string] | null | undefined => {
	const state = getState();
	if (!state) {
		return null;
	}
	try {
		const foundedNonceKey = Object.keys(state).find((nonceKey) => nonceKey === nonce);
		if (foundedNonceKey && state[foundedNonceKey]) {
			setStateInCookies(null, nonce); // to delete the nonce
			return state[foundedNonceKey];
		}
	} catch (e) {
		debugLog(e, 'error');
		return null;
	}
	return null;
};

export default getStateByNonce;
