import { gql } from '@apollo/client';
import { env, Fragments } from '../..';

export const SendResetPasswordLink = gql`
	mutation SendResetPasswordLink($tenantId: ID = "${env.TENANT_ID}", $userEmail: String!, $country: String, $loginChallenge: String) {
		sendResetPasswordLink(tenantId: $tenantId, userEmail: $userEmail, country: $country, loginChallenge: $loginChallenge) {
			emailSent
		}
	}
`;

export const changePassword = gql`
	mutation changePassword(
		$changePasswordRequest: ChangePasswordRequest!
		$userId: ID!
		$tenantId: ID = "${env.TENANT_ID}"
	) {
		changePassword(
			changePasswordRequest: $changePasswordRequest
			userId: $userId
			tenantId: $tenantId
		) {
			passwordUpdated
		}
	}
`;

export const updateUserDetailsForUser = gql`
	${Fragments.UserFields}
	mutation updateUserDetailsForUser(
		$updateUserRequest: UpdateUserRequest!
		$userId: ID!
		$tenantId: ID = "${env.TENANT_ID}"
	) {
		updateUserDetailsForUser(
			tenantId: $tenantId
			updateUserRequest: $updateUserRequest
			userId: $userId
		) {
			...UserFields
		}
	}
`;

export const capturePayPalPayment = gql`
	mutation capturePayPalPayment(
		$tenantId: ID = "${env.TENANT_ID}"
		$clientReference: String!
	) {
		capturePayPalPayment(
			tenantId: $tenantId
			clientReference: $clientReference
		) {
			paymentCaptured
		}
	}
`;

export const createUserPaymentMethod = gql`
	${Fragments.UserPaymentMethodFields}
	mutation createUserPaymentMethod(
        $tenantId: ID = "${env.TENANT_ID}"
		$userId: ID!
		$userPaymentMethodRequest: UserPaymentMethodRequest!
    ) {
		createUserPaymentMethod(tenantId: $tenantId, userId: $userId, userPaymentMethodRequest: $userPaymentMethodRequest) {
			...UserPaymentMethodFields
		}
	}
`;

export const setDefaultUserPaymentMethod = gql`
	mutation setDefaultUserPaymentMethod(
        $tenantId: ID = "${env.TENANT_ID}"
		$userId: ID!
		$paymentMethodReference: String!
		$country: String!
    ) {
		setDefaultUserPaymentMethod(tenantId: $tenantId, userId: $userId, paymentMethodReference: $paymentMethodReference, country: $country) {
			default
			reference
		}
	}
`;

export const createSetupIntent = gql`
	mutation createSetupIntent(
		$tenantId: ID = "${env.TENANT_ID}"
        $userId: ID!
        $country: String!
	) {
		createSetupIntent(userId: $userId, tenantId: $tenantId, country: $country) {
			clientSecret
		}
	}
`;

export const updateUserMembershipRenewalStatus = gql`
	mutation updateUserMembershipRenewalStatus(
		$renewalStatus: UserMembershipRenewalStatus!
		$userMembershipId: ID!
	) {
		updateUserMembershipRenewalStatus(
			renewalStatus: $renewalStatus
			userMembershipId: $userMembershipId
		) {
			id
			renewalStatus
		}
	}
`;

export const transitionTrialMembershipManually = gql`
	mutation transitionTrialMembershipManually(
        $tenantId: ID = "${env.TENANT_ID}"
        $userId: ID!
	) {
		transitionTrialMembershipManually(
			tenantId: $tenantId
			userId: $userId
		) {
			transitioned
		}
	}
`;
