import { Box } from '@mui/material';
import classnames from 'classnames';
import { Icon, isIconName, Typography } from 'components';
import React from 'react';
import { UserPaymentMethodProps } from './UserPaymentMethod.types';
import useStyles from './UserPaymentMethod.styles';
import { Types } from '../../';

const UserPaymentMethod = (props: UserPaymentMethodProps) => {
	const { userPaymentMethod, className, 'data-test': dataTest } = props;
	const classes = useStyles(props);

	const cardProps = (() => {
		switch (userPaymentMethod.type) {
			case Types.UserPaymentMethodType.SepaDebit:
				return {
					iconName: 'sepa',
					iconClass: classes.sepa,
					number: (
						<>
							<Typography title={userPaymentMethod.country} mr={0.5} />
							<Typography
								title={`**${userPaymentMethod.last4?.slice(0, 2)}`}
								mr={0.5}
							/>
							<Typography title={userPaymentMethod.last4?.slice(2)} />
						</>
					)
				} as const;
			case Types.UserPaymentMethodType.CreditCard:
				return {
					iconName: isIconName(userPaymentMethod.brand) ? userPaymentMethod.brand : null,
					number: (
						<>
							<Typography title="****" />
							<Typography title={userPaymentMethod.last4} />
						</>
					)
				} as const;
			case Types.UserPaymentMethodType.StripePayPal:
				return {
					iconName: 'paypal',
					number: null
				} as const;
			default:
				return {};
		}
	})();

	return (
		<Box className={classnames(classes.root, className)}>
			<Box className={classes.title}>
				{cardProps.iconName && (
					<Icon
						icon={cardProps.iconName}
						className={classnames(classes.icon, cardProps.iconClass)}
					/>
				)}
				<Box data-test={dataTest} className={classes.number}>
					{cardProps.number}
				</Box>
			</Box>
		</Box>
	);
};

export default UserPaymentMethod;
