import { useEffect } from 'react';
import { useHydrationContext } from '../../contexts';

type Props = {
	to: string | null;
};

export const Redirect = ({ to }: Props) => {
	const { isHydrated } = useHydrationContext();

	useEffect(() => {
		if (isHydrated && to) {
			window.location.href = to;
		}
	}, [isHydrated, to]);

	return null;
};
