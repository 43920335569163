const debugLog = (
	text?: { toString: () => string } | unknown,
	logType: 'debug' | 'error' = 'debug'
) => {
	try {
		const textStr =
			text && typeof text === 'object' && 'toString' in text ? text.toString() : text;
		const logger = logType === 'debug' ? console.debug : console.error;

		if (process.env.NEXT_PUBLIC_DEBUG || process.env.REACT_APP_DEBUG) {
			logger(textStr);
		}
	} catch (e) {
		console.warn('There was an error while logging an error');
		console.error(e);
	}
};

export default debugLog;
