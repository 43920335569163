import debugLog from '../utils/debugLog';
import { ApiResponse, BaseResponse } from './http.types';

const parseResponse = async <T extends BaseResponse>(
	fetchResponse: Response
): Promise<ApiResponse<T>> => {
	const { status: statusCode, ok: success } = fetchResponse;
	debugLog(`Response status code: "${statusCode}"`, success ? 'debug' : 'error');

	let data: T = undefined as T;
	let error = fetchResponse.statusText;
	try {
		if (fetchResponse.headers.get('Content-Type') === 'application/json') {
			data = (await fetchResponse.json()) as T;
			error = data?.error as string;
		}
	} catch (e) {
		console.error('HTTP response parse error', e);
	}

	if (success) {
		return {
			statusCode,
			fetchResponse,
			...data
		};
	}
	debugLog(error, 'error');

	return Promise.reject({
		statusCode,
		error,
		fetchResponse,
		...data
	});
};

export default parseResponse;
