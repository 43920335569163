import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		horizontalListContainer: {
			display: 'flex',
			alignItems: 'center',
			width: '100%'
		},
		listRefContainer: {
			width: '100%'
		},
		list: {
			display: 'flex',
			flexDirection: 'row',
			overflowY: 'hidden',
			overflowX: 'auto',
			width: '100%',
			scrollSnapType: 'x mandatory',
			[theme.breakpoints.up('xs')]: {
				scrollPaddingLeft: '16px'
			},
			[theme.breakpoints.up('sm')]: {
				scrollPaddingLeft: '80px'
			},
			[theme.breakpoints.up('lg')]: {
				overflowX: 'hidden'
			},
			'&::-webkit-scrollbar': {
				display: 'none'
			}
		},
		iconContainer: {
			cursor: 'pointer',
			width: theme.spacing(4),
			flexShrink: 0
		},
		icon: {
			color: theme.palette.label.main,
			height: theme.spacing(4),
			width: theme.spacing(4)
		},
		item: {
			flex: 1,
			display: 'block',
			padding: 0,
			scrollSnapStop: 'always',
			scrollSnapAlign: 'start'
		}
	}),
	{
		name: 'HorizontalList',
		index: 10 // To set a higher the CSS priority over the Icon width and height styles
	}
);

export default useStyles;
