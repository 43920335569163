import { UserValidation, UserTransformers, Fragments } from '../../';

const isProfileComplete = (profile: Fragments.UserFieldsFragment, locale: Locale) => {
	const validationSchema = UserValidation.getCompleteProfileValidationSchema();
	const transformedProfile = UserTransformers.transformUserForFormik(profile, locale);

	try {
		return validationSchema.isValidSync(transformedProfile);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.log('Profile validation error:', e);
		return false;
	}
};

export default isProfileComplete;
