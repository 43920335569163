import React from 'react';
import IntlTel from './intlTel';

type Props = {
	value: string;
	onChange: (args: {
		value: string;
		validate: () => string;
		setCountry: (country: string) => void;
	}) => void;
};

export const IntlTelInput = React.forwardRef<HTMLInputElement, Props>((props, outerInputRef) => {
	const { value, onChange, ...other } = props;
	const inputRef = React.useRef<HTMLInputElement>(null);
	const [intlTel, setIntlTel] = React.useState<IntlTel>();

	React.useEffect(() => {
		const init = async () => {
			if (inputRef.current !== null) {
				outerInputRef &&
					typeof outerInputRef === 'function' &&
					outerInputRef(inputRef.current);
				const intlTel = new IntlTel(inputRef.current, (newValue) =>
					onChange({
						value,
						...newValue
					})
				);
				await intlTel.init();
				setIntlTel(intlTel);
			}
		};

		init();

		return () => {
			intlTel && intlTel.destroy();
			setIntlTel(undefined);
		};
	}, []);

	React.useEffect(() => {
		// here we set number from store only if it valid
		// so plugin will work well
		if (window.intlTelInputUtils && intlTel?.iti && value !== intlTel.iti.getNumber()) {
			const isValueValid = window.intlTelInputUtils.isValidNumber(
				value,
				intlTel.iti.getSelectedCountryData().iso2
			);
			if (isValueValid) {
				intlTel.iti.setNumber(value);
			} else {
				intlTel.iti.setNumber('');
			}
		}
	}, [value, intlTel]);

	return <input {...other} ref={inputRef} />;
});
