import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes } from '../';
import { SectionsMenuProps, SectionsMenuClasses } from './SectionsMenu.types';

const useStyles = makeStyles<
	ThemeTypes.Theme,
	Partial<SectionsMenuProps<string>>,
	Partial<SectionsMenuClasses>
>((theme) => ({
	menu: {
		zIndex: 1,
		minHeight: 'unset',
		...theme.border({ radius: 4, width: 0 }),
		marginRight: 0,
		minWidth: 'unset',
		height: 40
	},
	mobileFullWidthTab: {
		width: '50%',
		[theme.breakpoints.up('sm')]: {
			width: 'auto'
		}
	},
	indicator: {
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
		backgroundColor: 'white',
		'&::after': {
			content: '""',
			height: 2,
			marginTop: 'auto',
			maxWidth: 26,
			width: '100%',
			backgroundColor: theme.palette.secondary.main
		}
	},
	menus: {
		backgroundColor: theme.palette.grey['100'],
		...theme.border({ radius: 6, width: 0 }),
		padding: 2,
		height: 44,
		minHeight: 'unset'
	},
	mobileFullWidthMenus: {
		width: '100%',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			width: 'auto'
		}
	},
	container: {
		display: 'flex',
		alignItems: 'flex-start'
	}
}));

export default useStyles;
