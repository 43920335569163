import React from 'react';
import { Box } from '@mui/material';
import { Typography } from 'components';
import classnames from 'classnames';

import useStyles from './MembershipTitle.styles';
import { MembershipTitleProps } from './MembershipTitle.types';
import { useIntl } from 'react-intl';
import { formatTranslation } from '../../formatters';

const MembershipTitle = (props: MembershipTitleProps) => {
	const { membershipType, name, className, ...rest } = props;
	const classes = useStyles();
	const { locale } = useIntl();
	return (
		<Box className={classnames(classes.membershipTitle, className)} {...rest}>
			<Typography
				className={classes.title}
				title={name ? formatTranslation(name, { locale }) : null}
			/>
			<Typography title={membershipType} className={classes.membershipType} />
		</Box>
	);
};

export default MembershipTitle;
