import { Types } from '../index';

export const getRandomUUID = (): Types.UUID => {
	if (typeof window === 'undefined') {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const { randomUUID } = require('crypto');
		const uuid = randomUUID() as Types.UUID;
		return uuid;
	} else {
		const uuid = window.crypto.randomUUID() as Types.UUID;
		return uuid;
	}
};
