import { getCookie } from '../';
import debugLog from '../utils/debugLog';
import { CookiesState, COOKIES_STATE_KEY } from '.';

const getState = (): CookiesState | null => {
	const cookie = getCookie(COOKIES_STATE_KEY);
	if (cookie?.length) {
		try {
			return JSON.parse(cookie) || null;
		} catch (e) {
			debugLog(e, 'error');
			return null;
		}
	}
	return null;
};

export default getState;
