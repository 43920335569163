import { Dayjs } from 'dayjs';

import { MonthProps } from './Month.types';
import { PickedDate } from '../DateRangePicker.types';
import { isSinglePicked } from '../DateRangePicker.utils';

export const isDatePicked = (props: {
	day: Dayjs;
	pickedSingleDate: PickedDate | undefined;
	pickedRangeStartDate: PickedDate | undefined;
	pickedRangeEndDate: PickedDate | undefined;
	isDayForPreviousMonth: boolean;
	isNextMonth: boolean | undefined;
}): 'single' | 'start' | 'end' | false => {
	const {
		day,
		pickedRangeEndDate,
		pickedRangeStartDate,
		pickedSingleDate,
		isDayForPreviousMonth,
		isNextMonth
	} = props;
	if (!pickedRangeStartDate && !pickedRangeEndDate && !pickedSingleDate) {
		return false;
	}

	if (
		isDayForPreviousMonth &&
		isNextMonth &&
		pickedRangeStartDate &&
		day.isSame(pickedRangeStartDate, 'month')
	) {
		return false;
	}

	if (
		isDayForPreviousMonth &&
		isNextMonth &&
		pickedRangeEndDate &&
		day.isSame(pickedRangeEndDate, 'month')
	) {
		return false;
	}

	if (pickedSingleDate && day.isSame(pickedSingleDate)) {
		return 'single';
	}

	if (pickedRangeStartDate && day.isSame(pickedRangeStartDate)) {
		return 'start';
	}

	if (pickedRangeEndDate && day.endOf('day').isSame(pickedRangeEndDate)) {
		return 'end';
	}

	return false;
};

export const isDateInRange = (props: {
	day: Dayjs;
	pickedRangeStartDate: PickedDate | undefined;
	pickedRangeEndDate: PickedDate | undefined;
	isDayForPreviousMonth: boolean;
	isNextMonth: boolean | undefined;
}): boolean => {
	const { day, pickedRangeEndDate, pickedRangeStartDate, isDayForPreviousMonth, isNextMonth } =
		props;

	if (!pickedRangeStartDate || !pickedRangeEndDate) {
		return false;
	}

	if (isDayForPreviousMonth && isNextMonth && day.isSame(pickedRangeStartDate)) {
		return true;
	}

	if (isDayForPreviousMonth && isNextMonth && day.isSame(pickedRangeEndDate, 'month')) {
		return false;
	}

	if (day.isAfter(pickedRangeStartDate) && day.isBefore(pickedRangeEndDate)) {
		return true;
	}

	return false;
};

export const handleOnDayClick = (
	props: { day: Dayjs } & Pick<MonthProps, 'pickedDate' | 'onPickedDateChange'>
) => {
	const { day, onPickedDateChange, pickedDate } = props;

	if (isSinglePicked(pickedDate)) {
		if (day.isSame(pickedDate.single)) {
			onPickedDateChange({ single: null });
		} else {
			onPickedDateChange({ single: day });
		}
		return;
	}

	if (day.isSame(pickedDate.start)) {
		onPickedDateChange({ start: null, end: null });
		return;
	}

	if (!pickedDate.start || day.isBefore(pickedDate.start) || !!pickedDate.end) {
		onPickedDateChange({ start: day, end: null });
		return;
	}

	onPickedDateChange({ end: day.endOf('day') });
};

export const getAllDaysByRow = (allDays: Array<Dayjs>): Array<Array<Dayjs>> =>
	allDays.reduce<Array<Array<Dayjs>>>((acc, item, index) => {
		const rowIndex = Math.floor(index / 7);
		const row = acc[rowIndex];
		if (row) {
			row.push(item);
		} else {
			acc[rowIndex] = [item];
		}

		return acc;
	}, []);

export const getActiveColumnIndex = (
	allDaysByRow: Array<Array<Dayjs>>,
	currentDate: Dayjs
): number =>
	allDaysByRow.reduce<number>((acc, row) => {
		const activeColumnIndex = row.reduce<number>((acc, day, columnIndex) => {
			if (day.isSame(currentDate)) {
				return columnIndex;
			}
			return acc;
		}, -1);

		return activeColumnIndex !== -1 ? activeColumnIndex : acc;
	}, -1);
