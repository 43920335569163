import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes } from '../../';
import { DayProps, DayClasses } from './Day.types';

const useStyles = makeStyles<ThemeTypes.Theme, DayProps, DayClasses>(
	(theme) => ({
		root: {
			width: 36,
			height: 36,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			color: theme.palette.titleActive.main,
			borderRadius: 4,
			transition: theme.transitions.create(['color', 'background-color']),
			cursor: 'pointer'
		},
		current: {
			fontWeight: 700
		},
		disabled: {
			color: theme.palette.label.main,
			pointerEvents: 'none'
		},
		insideRange: {
			background: theme.palette.background.default,
			borderRadius: 0
		},
		insideRangeRoundedLeft: {
			borderRadius: '4px 0 0 4px'
		},
		insideRangeRoundedRight: {
			borderRadius: '0 4px 4px 0'
		},
		insideRangeRounded: {
			borderRadius: 4
		},
		picked: {
			background: theme.palette.primary.light,
			color: theme.palette.common.white,
			fontWeight: 700
		},
		pickedStart: {
			borderRadius: '18px 0 0 18px'
		},
		pickedEnd: {
			borderRadius: '0 18px 18px 0'
		}
	}),
	{ name: 'Day' }
);

export default useStyles;
