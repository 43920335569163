import React, { ReactNode } from 'react';

import {
	useSnackbar as useDefaultSnackbar,
	OptionsObject as NOptionsObject,
	SnackbarKey
} from 'notistack';
import { Snackbar } from 'components';

type OptionsObject = NOptionsObject & {
	messageLocaleId?: string;
	messageValues?: Record<string, ReactNode>;
	hideIcon?: boolean;
	message?: ReactNode;
};

export type SnackbarProviderContext = {
	enqueueSnackbar: (options: OptionsObject) => SnackbarKey;
	closeSnackbar: (key?: SnackbarKey) => void;
};

const useSnackbar = (): SnackbarProviderContext => {
	const { enqueueSnackbar, closeSnackbar } = useDefaultSnackbar();

	const pushSnackbar = (options: OptionsObject) =>
		enqueueSnackbar(undefined, {
			...options,
			content: (key) => {
				// destructure the options we need from the extended options
				// object, and provide a default case if we didn't provide any
				const { variant } = options || {};
				return (
					<Snackbar
						id={key}
						messageLocaleId={options.messageLocaleId}
						messageValues={options.messageValues}
						variant={variant}
						close={() => closeSnackbar(key)}
						hideIcon={options?.hideIcon}
						message={options.message}
					/>
				);
			}
		});

	return { enqueueSnackbar: pushSnackbar, closeSnackbar };
};

export default useSnackbar;
