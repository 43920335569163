import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		container: {
			...theme.border({ width: 1, color: theme.palette.body.light, radius: 12 }),
			background: theme.palette.common.white,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			padding: theme.spacing(3)
		},
		pointsAmount: {
			marginBottom: theme.spacing(0.5),
			fontWeight: 600,
			fontSize: 20,
			lineHeight: 30 / 20
		},

		price: {
			marginBottom: theme.spacing(2),
			fontWeight: 600,
			fontSize: 29,
			lineHeight: 43.5 / 29
		},

		button: {
			marginTop: theme.spacing(3)
		}
	}),
	{ name: 'TopUpCard' }
);

export default useStyles;
