import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		popoverContainer: {
			boxShadow: '0px 6px 24px 0px #0000001F',
			borderRadius: 6,
			backgroundColor: theme.palette.common.white
		},
		container: {
			width: 228,
			display: 'flex',
			padding: 4,
			flexDirection: 'column'
		},
		avatarContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 6,
			padding: 12,
			transition: 'background-color 0.1s',

			'&:hover': {
				backgroundColor: '#0000000A'
			}
		},
		avatar: {
			width: 50,
			height: 50,
			padding: 3,
			color: theme.palette.common.white,
			backgroundColor: theme.palette.secondary[700],
			...theme.typography.textMedium
		},
		divider: {
			height: 1,
			backgroundColor: 'rgba(0, 0, 0, 0.08)',
			marginInline: 12,
			marginBlock: 4
		},
		userNameTitle: {
			fontWeight: 700,
			letterSpacing: 0.5,
			textAlign: 'center'
		},
		regularLink: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			gap: theme.spacing(1),
			fontWeight: 400,
			lineHeight: '20px',
			padding: 12,
			cursor: 'pointer',
			transition: 'background-color 0.1s',

			'&:hover': {
				backgroundColor: '#0000000A'
			},
			'&:last-child': {
				marginBottom: 0
			}
		},
		logOutLink: {
			color: theme.palette.error.dark
		},
		centered: {
			alignSelf: 'center'
		},
		companyLogo: {
			width: 24,
			height: 24,
			flexShrink: 0
		},
		linkIcon: {
			width: theme.spacing(3),
			height: theme.spacing(3)
		}
	}),
	{ name: 'UserPopover' }
);

export default useStyles;
