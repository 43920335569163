import React, { useState, useEffect, createContext, useContext, PropsWithChildren } from 'react';

const HydrationContext = createContext({ isHydrated: false });

/**
 * The provider fires on page/component mount and changes _isHydrated_ to `true`
 */
const HydrationContextProvider = ({ children }: PropsWithChildren) => {
	const [isHydrated, setIsHydrated] = useState({ isHydrated: false });
	useEffect(() => {
		setIsHydrated({ isHydrated: true });
	}, []);

	return <HydrationContext.Provider value={isHydrated}>{children}</HydrationContext.Provider>;
};

/**
 * Returns true if the app is hydrated. Returns false if it has not yet been hydrated.
 */
const useHydrationContext = () => useContext(HydrationContext);

export { HydrationContextProvider, useHydrationContext };
