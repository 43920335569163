import { commonLocales } from '../index';

const buildLocaleMessages = (
	locale: Locale | undefined,
	packageMessages: Record<Locale, Record<string, string> | undefined>
): Record<string, string> => {
	const deMessages = { ...commonLocales.de, ...packageMessages['de-DE'] };

	switch (locale) {
		case 'de-AT':
			return { ...deMessages, ...commonLocales.deAT, ...packageMessages['de-AT'] };
		case 'de-CH':
			return { ...deMessages, ...commonLocales.deCH, ...packageMessages['de-CH'] };
		case 'de-DE':
			return deMessages;
		case 'en-GB':
			return { ...deMessages, ...commonLocales.en, ...packageMessages['en-GB'] };
		case 'fr-CH':
			return { ...deMessages, ...commonLocales.frCH, ...packageMessages['fr-CH'] };
		default:
			return deMessages;
	}
};

export default buildLocaleMessages;
