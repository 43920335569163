import type { SelectableOption } from './componentsTypes';

const isOptionTranslationOrTextAvailable = (option: SelectableOption) => {
	return option.id && (option.label || option.localeId);
};

/**
 * This function receives an array of options and return another array containing just the options
 * that have an available translation ("localeId") or a "label" to show.
 */
export const getOptionsWithTranslationsOrLabel = (options: Array<SelectableOption>) => {
	return options.filter(isOptionTranslationOrTextAvailable);
};
