import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
	(theme) => ({
		helperText: {
			marginLeft: 14
		},
		inputWithError: {
			'&::placeholder': {
				color: theme.palette.error.main
			}
		},
		chipRoot: {
			height: 22
		},
		chipLabel: {
			paddingRight: 0,
			color: theme.palette.primary.main,
			fontSize: 14
		},
		chipDeleteIcon: {
			width: 16,
			height: 16,
			// TODO: we needed this important when doing the mui v5 migration, this would be nice
			// to remove it.
			margin: '0 6px 6px 0px!important',

			'&:hover': {
				color: theme.palette.primary.main
			}
		},
		inputRoot: {
			'&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
				paddingTop: 20,
				paddingBottom: 2,
				paddingLeft: 10,

				'& .MuiAutocomplete-input': {
					paddingTop: 5,
					paddingBottom: 5,
					paddingLeft: 14
				}
			}
		}
	}),
	{ name: 'Autocomplete', index: 1 }
);
