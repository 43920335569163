import { Fragments, Types, UserTransformers, useUserFullName } from '../index';
type Maybe<T> = Types.Maybe<T>;
type Address = Types.AddressFormik;
type AddressR = Types.AddressR;

const filterToExcludeByIdsArray = (idsArray: Array<string>) => (item: Address) =>
	!(item.id && idsArray.includes(item.id));

/**
 * Takes updated addresses (any number of them) and merges them with old addresses (any number as well) using address.id to differ new addresses from existing ones
 * @param props
 * @param props.user - Optional user to take old addresses from
 * @param props.userFullName - user full name required to transform oldAddress for request
 * @param props.newAddresses - Array of updated addresses
 * @param props.oldAddresses - Optional array of old addresses. Has more priority than passed user addresses
 * @returns array of addresses for update user request
 */
const createAddressesRequest = (props: {
	user: Types.UserR | Fragments.UserFieldsFragment | null | undefined;
	userFullName: string;
	newAddresses: Array<Address | AddressR>;
	oldAddresses?: Maybe<Array<Maybe<Address>>> | Maybe<Array<Maybe<AddressR>>> | null;
}): Array<Types.AddressRequest> => {
	const {
		user,
		userFullName,
		newAddresses: newAddressesProp,
		oldAddresses: oldAddressesProp
	} = props;
	let resultsTemp: Array<Address>;

	const addressesIdsToDelete = newAddressesProp
		.filter((item): item is { id: Types.UUID } => !!(item as Address).toDelete && !!item.id)
		.map((item) => item.id);

	const newAddresses = newAddressesProp
		.map((newAddress) => UserTransformers.address(newAddress, userFullName))
		.filter(filterToExcludeByIdsArray(addressesIdsToDelete));

	const oldAddresses = (oldAddressesProp || user?.addresses)
		?.map((item) => UserTransformers.address(item, userFullName))
		.filter(filterToExcludeByIdsArray(addressesIdsToDelete));

	/**
	 * if given array of old addresses we perform merge on address-by-address basis
	 * if not - we only use new addresses
	 */
	if (oldAddresses) {
		resultsTemp = [...oldAddresses];

		// for each new address
		newAddresses.forEach((newAddress) => {
			// we look if address with the same id already exists
			const existingAddressIndex = oldAddresses.findIndex(
				(item) => item && item.id === newAddress.id
			);
			const existingAddress = oldAddresses[existingAddressIndex];

			/**
			 * if it exists, we merge old and new addresses
			 * if not - push new address
			 */
			if (existingAddressIndex !== -1) {
				/**
				 * if address already exists it will have the same index in both `oldAddresses` and `result` arrays
				 */
				resultsTemp.splice(existingAddressIndex, 1, {
					...existingAddress,
					...newAddress
				});
			} else {
				resultsTemp.push(newAddress);
			}
		});
	} else {
		resultsTemp = [...newAddresses];
	}

	// We have to manually copy fields because typescript don't have a way to restrict object from having extra fields
	const results = resultsTemp.map((address) => {
		const {
			street,
			postalCode,
			isPersonal,
			fullName,
			country,
			company,
			city,
			apartmentNumber,
			additionalInformation,
			id
		} = address as Types.AddressRequest;
		const shrunkAddress: Types.AddressRequest = {
			street,
			id,
			additionalInformation,
			apartmentNumber,
			city,
			company,
			country,
			fullName,
			isPersonal,
			postalCode
		};

		return shrunkAddress;
	});

	return results;
};

const useCreateAddressesRequest = (user: Fragments.UserFieldsFragment | null | undefined) => {
	const userFullName = useUserFullName(user);
	return (
		newAddresses: Array<Address | AddressR>,
		oldAddresses?: Maybe<Array<Maybe<Address>>> | Maybe<Array<Maybe<AddressR>>> | null
	) => createAddressesRequest({ user, userFullName, newAddresses, oldAddresses });
};

export { createAddressesRequest as PRIVATE_createAddressesRequest };
export default useCreateAddressesRequest;
