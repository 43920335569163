import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from '../';
import { SwitchProps, SwitchClasses } from './Switch.types';

const useStyles = makeStyles<ThemeTypes.Theme, SwitchProps, SwitchClasses>((theme) => ({
	root: {
		width: 48,
		height: 30,
		borderRadius: 24,
		padding: 0
	},
	switchBase: {
		padding: 4,
		color: theme.palette.common.white,

		'&:hover:not($disabled)': {
			background: 'transparent',

			'& + $track': {
				backgroundColor: theme.palette.line.main,
				opacity: 0.5
			}
		},

		'&$checked': {
			color: theme.palette.common.white,
			transform: 'translateX(17px)',

			'& + $track': {
				backgroundColor: theme.palette.secondary.main,
				opacity: 1
			},

			'&:hover:not($disabled)': {
				'& + $track': {
					backgroundColor: theme.palette.secondary.dark,
					opacity: 1
				}
			}
		},

		'&$disabled': {
			cursor: 'not-allowed',
			pointerEvents: 'all'
		}
	},
	thumb: {
		width: 22,
		height: 22,
		borderRadius: 44,
		boxShadow: '0px 5.5px 5px -3px rgba(14, 14, 44, 0.2)'
	},
	track: {
		backgroundColor: theme.palette.accent.main,
		opacity: 1
	},
	checked: {},
	disabled: {}
}));

export default useStyles;
