import { capitalize } from '@mui/material';
import palette from './palette';
import { ThemeTypes } from 'components';

const DEFAULT_BORDER_WIDTH = 1;
const DEFAULT_BORDER_RADIUS = 3;
export const DEFAULT_BORDER_COLOR = palette.body.main;
const DEFAULT_BORDER_STYLE = 'solid';

const border = (props?: ThemeTypes.BorderProps): ThemeTypes.Border => {
	const {
		kind = 'all',
		radius = DEFAULT_BORDER_RADIUS,
		width = DEFAULT_BORDER_WIDTH,
		color = DEFAULT_BORDER_COLOR,
		style = DEFAULT_BORDER_STYLE
	} = props || {};
	const attributeName = kind === 'all' ? '' : capitalize(kind);
	const borderWidthAttribute = `border${attributeName}Width`;
	const borderAttribute = `border${attributeName}`;
	const borderColorAttribute = `border${attributeName}Color`;
	const borderRadiusAttribute = 'borderRadius';
	return {
		[borderWidthAttribute]: width,
		[borderAttribute]: style,
		[borderColorAttribute]: color,
		[borderRadiusAttribute]: radius
	};
};

export default border;
