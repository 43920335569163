import React, { useEffect } from 'react';
import { Container, Divider, useMediaQuery, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import {
	TERMS_AND_CONDITIONS_LINKS,
	PRIVACY_LINKS,
	NextLink,
	useUserContext,
	isFeatureEnabled,
	getLanguageFromLocale,
	getCountryCode
} from '../../';
import getLocalizedLink from '../../utils/getLocalizedLink';
import useStyles from './Footer.styles';
import { Logo, Button, Typography, Icon, Link, FormikTextField, useCustomFormik } from 'components';
import { CATALOG_URLS } from '../../urls';
import * as yup from 'yup';
import { Interweave, Markup } from 'interweave';
import { EmailMatcher } from 'interweave-autolink';
import SimpleFooter from './SimpleFooter';

const Footer = () => {
	const { formatMessage, locale } = useIntl();
	const isCMED = locale === 'en-GB';
	const classes = useStyles({ isCMED });
	const theme = useTheme();
	const { user } = useUserContext();
	const countryCode = getCountryCode(locale);
	const language = getLanguageFromLocale(locale);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
	const router = useRouter();

	const formik = useCustomFormik({
		initialValues: {
			email: user?.email ?? ''
		},
		validationSchema: yup.object({
			email: yup.string().email('common.valid-email').required('common.error.required-email')
		}),
		onSubmit: async (values) => {
			const { email } = values;
			const encodedEmail = encodeURIComponent(email);
			router.push(`${CATALOG_URLS.newsletter}?email=${encodedEmail}`);
		}
	});

	useEffect(() => {
		if (user?.email) {
			formik.setFieldValue('email', user.email);
		}
	}, [user?.email]);

	const isFaxVisible = ['de', 'at'].includes(countryCode);
	const isAddressVisible = isCMED === false;
	const isXingVisible = language === 'de'; // Xing is only available in German
	const isAntibriberyPolicyVisible = countryCode === 'gb';
	const isPartnerLandingVisible = isFeatureEnabled('partnerLandingPage', countryCode);

	return (
		<div className={classes.footer}>
			<div>
				<Container maxWidth="xl">
					<div className={classes.columnsContainer}>
						<div className={classes.logoContainer}>
							<div>
								<Logo
									locale={locale}
									fill="#D6DEE5"
									className={classes.logo}
									type="compact"
								/>
							</div>
						</div>
						<div className={classes.firstColumn}>
							<div className={classes.columnItem}>
								<Icon icon="phone" className={classes.icon} />
								<Typography
									className={classes.columnText}
									localeId="components.footer.phone"
								/>
							</div>
							{isFaxVisible && (
								<div className={classes.columnItem}>
									<Icon icon="printer" className={classes.icon} />
									<Typography
										localeId="components.footer.fax"
										className={classes.columnText}
									/>
								</div>
							)}
							<div className={classes.columnItem}>
								<Icon icon="simpleEnvelope" className={classes.icon} />
								<Interweave
									className={classes.columnText}
									content={formatMessage({
										id: 'components.footer.email'
									})}
									matchers={[new EmailMatcher('email')]}
								/>
							</div>
							{isAddressVisible && (
								<div className={classes.columnItem}>
									<Icon icon="mapPin" className={classes.icon} />
									<Markup
										content={formatMessage({ id: 'components.footer.address' })}
										className={classes.columnText}
									/>
								</div>
							)}
						</div>
						<Divider />
						<div className={classes.secondColumn}>
							<div className={classes.socialIcons}>
								<Link
									href={formatMessage({ id: 'common.footer.facebook' })}
									target="_blank"
									className={classes.socialIconContainer}
								>
									<Icon icon="facebook" className={classes.socialIcon} />
								</Link>
								<Link
									href={formatMessage({ id: 'common.footer.linkedin' })}
									target="_blank"
									className={classes.socialIconContainer}
								>
									<Icon icon="linkedin" className={classes.socialIcon} />
								</Link>
								<Link
									href={formatMessage({ id: 'common.footer.instagram' })}
									target="_blank"
									className={classes.socialIconContainer}
								>
									<Icon icon="instagram" className={classes.socialIcon} />
								</Link>
								{isXingVisible && (
									<Link
										href={formatMessage({ id: 'common.footer.xing' })}
										target="_blank"
										className={classes.socialIconContainer}
									>
										<Icon icon="xing" className={classes.socialIcon} />
									</Link>
								)}
							</div>
							<Divider />
							<div className={classes.contactLinks}>
								<NextLink href={CATALOG_URLS.contact} className={classes.link}>
									<Typography
										className={classes.linkText}
										localeId="components.footer.link.contact"
										variant="textXSmall"
										color="secondary"
									/>
								</NextLink>
								<NextLink
									href={getLocalizedLink(TERMS_AND_CONDITIONS_LINKS, locale)}
									className={classes.link}
								>
									<Typography
										className={classes.linkText}
										localeId="components.footer.link.agb"
										variant="textXSmall"
										color="secondary"
									/>
								</NextLink>
								<NextLink href={CATALOG_URLS.impresum} className={classes.link}>
									<Typography
										className={classes.linkText}
										localeId="components.footer.link.impressum"
										variant="textXSmall"
										color="secondary"
									/>
								</NextLink>
								<NextLink
									href={getLocalizedLink(PRIVACY_LINKS, locale)}
									className={classes.link}
								>
									<Typography
										className={classes.linkText}
										localeId="components.footer.link.privacy-policy"
										variant="textXSmall"
										color="secondary"
									/>
								</NextLink>
								{isAntibriberyPolicyVisible && (
									<NextLink
										href={CATALOG_URLS.antiBriberyPolicy}
										className={classes.link}
									>
										<Typography
											className={classes.linkText}
											localeId="components.footer.link.anti-bribery-policy"
											variant="textXSmall"
											color="secondary"
										/>
									</NextLink>
								)}
								{isPartnerLandingVisible && (
									<NextLink
										href={CATALOG_URLS.partnerLanding}
										className={classes.link}
									>
										<Typography
											className={classes.linkText}
											localeId="common.header.link-partner"
											variant="textXSmall"
											color="secondary"
										/>
									</NextLink>
								)}
							</div>
						</div>
						<Divider
							orientation={isDesktop ? 'vertical' : 'horizontal'}
							flexItem={isDesktop}
							className={classes.verticalDivider}
						/>
						<div>
							<Typography
								localeId="components.footer.newsletter.title"
								variant="textSmall"
								className={classes.newsletterTitle}
								fontFamily="Poppins"
							/>
							<Typography
								localeId="components.footer.newsletter.description"
								color="label"
								variant="textXXSmall"
								className={classes.newsletterDescription}
							/>
							<form className={classes.form} onSubmit={formik.handleSubmit}>
								<FormikTextField
									name="email"
									type="email"
									localeId="catalog.home.newsletter.email"
									formik={formik}
									hideClearIcon
									required={false}
								/>
								<Button
									className={classes.submitButton}
									type="submit"
									disabled={formik.isSubmitting}
									variant="outlined"
									color="secondary"
									localeId="common.footer.newsletter.submit"
								/>
							</form>
						</div>
					</div>
				</Container>
				<SimpleFooter />
			</div>
		</div>
	);
};

export default Footer;
