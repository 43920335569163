import { ClassNameMap, mergeClasses as MUIMergeClasses } from '@mui/styles';

/**
 * @deprecated TODO: remove it because mui "useStyles" function already do this job for us.
 */
const mergeClasses = <T extends ClassNameMap>(
	baseClasses: T,
	newClasses: Partial<T> | undefined
): T =>
	MUIMergeClasses({
		baseClasses,
		newClasses: (newClasses || {}) as T,
		Component: () => null
	}) as T;

export default mergeClasses;
