import dayjs, { ConfigType, Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

/**
 * Date formats. A format entry can either be a string or an object with locale-specific formats.
 *
 * If the format is an object, the key is the locale and the value is the format.
 * If the format is an object, make sure to provide a fallback value with the default `de` locale (make sure for `de` it's a two letter locale code).
 */
export const DateFormat = {
	date: 'DD.MM.YYYY',
	dateTime: 'DD.MM.YYYY HH:mm',
	time: 'HH:mm',
	db: 'YYYY-MM-DDTHH:mm:ss[Z]',
	dbShort: 'YYYY-MM-DD',
	dayAndMonthSingleLine: {
		de: 'DD.[&nbsp;]MMMM',
		'en-gb': 'DD[&nbsp;]MMMM'
	}, // only to use when layout cannot guarantee single-line rendering
	dayAndMonth: {
		de: 'DD. MMMM',
		'en-gb': 'DD MMMM'
	},
	dayAndMonthShort: {
		de: 'DD. MMM',
		'en-gb': 'DD MMM'
	},
	dayMonthYear: {
		de: 'DD. MMMM YYYY',
		'en-gb': 'DD MMMM YYYY'
	},
	monthAndYear: 'MMMM YYYY',
	monthAndWeekday: {
		de: 'dddd, DD. MMMM',
		'en-gb': 'dddd, DD MMMM'
	}
} as const;

export type DateFormat = (typeof DateFormat)[keyof typeof DateFormat] | string;

/**
 * Date range formats. A format entry can either be a string or an object with locale-specific formats.
 *
 * If the format is an object, the key is the locale and the value is the format.
 * If the format is an object, make sure to provide a fallback value with the default `de` locale (make sure for `de` it's a two letter locale code).
 */
export const DateRangeFormat = {
	full: {
		de: 'DD.[&nbsp;]MMM[&nbsp;]YYYY',
		'en-gb': 'DD[&nbsp;]MMM[&nbsp;]YYYY'
	},
	monthNumber: 'DD.[&nbsp;]MM.[&nbsp;]YYYY',
	weekday: {
		de: 'dddd, DD. MMMM YYYY, ',
		'en-gb': 'dddd, DD MMMM YYYY, '
	},
	timeDuration: {
		de: 'dddd, DD. MMMM YYYY - ',
		'en-gb': 'dddd, DD MMMM YYYY - '
	},
	date: {
		de: 'dddd, DD. MMMM YYYY',
		'en-gb': 'dddd, DD MMMM YYYY'
	},

	// same day: DD.MM.YYYY
	// different days: DD.MM – DD.MM.YYYY
	constantDate: 'DD.MM. – DD.MM.YYYY'
};

export type DateRangeFormat = (typeof DateRangeFormat)[keyof typeof DateRangeFormat];

export const parseDBDate = (dateString: ConfigType, keepUTC = false): Dayjs => {
	const date = dayjs.utc(dateString, DateFormat.db);
	const result = keepUTC ? date : date.local();
	return result;
};

export const formatDateForDB = (date: Dayjs, format: DateFormat = DateFormat.db): string => {
	const locale = date.locale() as DayJSLocale;
	const localisedFormat = flattenDateFormat(format, locale);
	return date.utc().format(localisedFormat);
};

export const flattenDateFormat = (
	inputFormat: DateFormat | DateRangeFormat,
	currentLocale: DayJSLocale
): string => {
	let outputFormat;

	if (typeof inputFormat === 'string') {
		outputFormat = inputFormat;
	} else {
		if (currentLocale in inputFormat) {
			outputFormat = inputFormat[currentLocale as keyof typeof outputFormat];
		} else {
			// Fallback values
			outputFormat = inputFormat['de'] || Object.values(inputFormat)[0] || 'DD.MM.YYYY';
		}
	}

	return outputFormat;
};
