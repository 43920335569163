import React from 'react';
import { Box, useTheme } from '@mui/material';
import classNames from 'classnames';

import useStyles from './Icon.styles';
import { IconProps } from './Icon.types';
import * as icons from './icons';
import _get from 'lodash/get';

const Icon = (props: IconProps) => {
	const {
		icon,
		className,
		onClick,
		strokeWidth = 2,
		rounded = false,
		style,
		color,
		colorVariant,
		[`data-testid`]: testId
	} = props;
	const IconComponent = icons[icon];
	const classes = useStyles();

	const theme = useTheme();
	const colorStyle = color && _get(theme.palette, [color, colorVariant || 'main'], '');

	return (
		<Box
			className={classNames(classes.icon, { [classes.rounded]: rounded }, className)}
			onClick={onClick}
			style={{ ...style, strokeWidth, color: colorStyle || style?.color }}
		>
			<IconComponent data-testid={testId} />
		</Box>
	);
};

export default Icon;
