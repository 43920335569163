import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		container: {
			padding: 0,
			paddingTop: '0 !important' // !important because mui has :first-child selector that fits this element, so we can't override it with a selector
		},
		dialogTitle: {
			...theme.border({ kind: 'bottom', color: theme.palette.body.light }),
			padding: theme.spacing(2)
		},
		dialogContent: {
			marginBottom: theme.spacing(0),
			padding: theme.spacing(2)
		},
		dialogActions: {
			padding: theme.spacing(0, 2, 2),
			justifyContent: 'center',
			'&$singleAction': {
				justifyContent: 'flex-end',

				'& $actionButton': {
					flexBasis: 'unset'
				}
			},

			'&$longActionTitles': {
				flexWrap: 'wrap',
				'& $actionButton': {
					flexBasis: '100%',

					'&:first-child': {
						order: 1
					},

					'&:last-child': {
						marginBottom: theme.spacing(2),
						marginLeft: 0
					}
				}
			}
		},
		title: {
			textAlign: 'center'
		},
		actionsContainer: {},
		actionButton: {
			flexBasis: '50%'
		},
		singleAction: {},
		longActionTitles: {}
	}),
	{ name: 'ConfirmationModal' }
);

export default useStyles;
