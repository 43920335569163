export { buildMobileNumber, getMobileNumberAndDialCode } from './formatMobileNumber';

export { default as createApolloClient } from './apollo/createApolloClient';
export { initializeApollo, addApolloState, useApollo } from './apollo/createApolloClient';
export { default as getCookie } from './cookies/getCookie';
export { default as getStateByNonce } from './cookies/getStateByNonce';
export { default as setCookie } from './cookies/setCookie';
export { default as getState } from './cookies/getState';
export { default as getLatestStateData } from './cookies/getLatestStateData';
export { default as setStateInCookies } from './cookies/setStateInCookies';
export { default as getSearchFragment } from './graphql/getSearchFragment';
export { default as withUser, redirectAnonymous } from './withUser';
export { default as env } from './env';
export { default as isFeatureEnabled } from './utils/isFeatureEnabled';
export { default as useDownloadFile } from './utils/useDownloadFile';
export { default as EditPersonalInfo } from './containers/EditPersonalInfo/EditPersonalInfo';

export * as hydra from './hydra/hydra';
export * as Mocks from './test-utils/mocks';
import * as Types from './graphql/generated';
import * as Fragments from './graphql/fragments';
import * as Mutations from './graphql/mutations';
import * as Queries from './graphql/queries';
import * as ContentOptions from './formOptions/content';
import * as MembershipOptions from './formOptions/membership';

export * as ServerQueries from './graphql/server';
export * as UserValidation from './formValidation/user/userFields';
export * as UserValidationSchemas from './formValidation/user/userSchemas';
export * as EventOptions from './formOptions/event';
export * as UserOptions from './formOptions/user';
export * as ParticipantOptions from './formOptions/participants';
export * as UserTransformers from './transformers/user';
export * as ContentTransformers from './transformers/content';
export * as SponsorTransformers from './transformers/sponsor';
export * as PartnershipTransformers from './transformers/partnership';
export * as EventTransformers from './transformers/event';
export * as DrupalEventTransformers from './transformers/drupalEvent';
export * as formatters from './formatters';
export * as SsrAuthQueries from './apollo/ssr/auth';
export * as Datadog from './datadog';

export * from './containers/EditMedicalInfo';
export * from './constants';
export * from './urls';
export * from './contexts';
export * from './hocs';
export * from './hooks';
export * from './segment';
export * from './launchdarkly';
export * from './components';
export * from './utils';
export * from './formatters';
export * from './theme';
export * from './types';
export * from './content/server-errors';
export * from './group-addons-logic';
export * from './http/';

import en from './content/locales/en.json';
import de from './content/locales/de.json';
import deAT from './content/locales/de-AT.json';
import deCH from './content/locales/de-CH.json';
import frCH from './content/locales/fr-CH.json';

export const commonLocales = {
	en,
	de,
	deAT,
	deCH,
	frCH
};

export { Types, Fragments, Mutations, Queries, MembershipOptions, ContentOptions };
