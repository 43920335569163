import React from 'react';
import { FreeBadgeProps } from './FreeBadge.types';
import useStyles from './FreeBadge.styles';
import classNames from 'classnames';
import { Icon } from 'components';

const FreeBadge = (props: FreeBadgeProps) => {
	const { variant } = props;
	const classes = useStyles(props);

	return (
		<Icon
			icon="freeContent"
			data-testid="free-badge"
			className={classNames(classes?.freeBadge, {
				[classes.primary]: variant === 'primary',
				[classes.gray]: variant === 'gray',
				[classes.white]: variant === 'white'
			})}
		/>
	);
};

export default FreeBadge;
