import React, { ReactNode } from 'react';
import { Types, Fragments } from '..';
import { MembershipCycle } from './graphql/generated';

export type EnumType<T extends Record<string, unknown>> = T[keyof T];

export type MessageParams = {
	messageLocaleId?: string;
	message?: ReactNode;
	titleLocaleId?: string;
	titleValues?: Record<string, React.ReactNode>;
	messageValues?: Record<string, React.ReactNode>;
	actionButton?: ReactNode;
	variant?: 'info';
	jsonMessagesArray?: Array<string>;
};

export type UserMembershipItemAmount = {
	total: number;
	used: number;
	remaining: number;
};

export type UserMembershipItem = Pick<
	Fragments.UserMembershipItemFieldsFragment,
	'amountUnit' | 'membershipItem'
> & {
	amount: UserMembershipItemAmount;
};

export type UserMembership = Fragments.UserMembershipFieldsFragment & {
	membershipType: Types.MembershipType | null | undefined;
	items: Array<UserMembershipItem>;
	accreditationPoints: UserMembershipItemAmount;
};

export const MembershipDescriptionIconType = {
	checked: 'checked',
	crossed: 'crossed'
} as const;
export type MembershipDescriptionIconType = EnumType<typeof MembershipDescriptionIconType>;

export type MembershipTranslation = Types.Translation & {
	iconType?: MembershipDescriptionIconType;
	isHighlighted?: boolean;
};

export type Membership = Omit<Types.Membership, 'description'> & {
	description?: Array<MembershipTranslation> | null;
};

/**
 * The PartialBy allows you to make specific properties optional.
 * @example: PartialBy<UserR, 'name'>; to make just the "name" property optional.
 */
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const OfferType = {
	membershipTrial: 'membership-trial',
	mediathekTrial: 'mediathek-trial'
};
export type OfferType = EnumType<typeof OfferType>;

export type UserBookingWithContentId = Omit<Fragments.BookingFieldsFragment, 'event'> & {
	event?:
		| (Fragments.BookingFieldsFragment['event'] & { contentId: string | undefined })
		| null
		| undefined;
};

export type MembershipCycleByCountry = {
	[key: string]: Array<MembershipCycle>;
};

export type MembershipOfferByCountry = {
	[key: string]: [Date, Date] | undefined;
};

export type SponsorshipContent = {
	isSymposium: boolean;
	contentTitle: string;
	sponsorCompanyId: string | null;
	sponsorCompanyName: string;
	keyId: string;
	sponsorLogoUrl: string;
	externalId?: string | null;
} & Fragments.SponsorshipFieldsFragment['content'];
