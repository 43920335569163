const getEnvironmentDomainPrefix = (): string => {
	// on backend always assume production for safety. Later we will need to solve it with the ENV_VARIABLE
	if (typeof window === 'undefined') {
		return '';
	}

	const host = window.location.hostname;

	// localhost always means dev. Later we can adjust this behavior with ENV_VARIABLE
	if (host === 'localhost') {
		return 'staging.';
	}

	const hostDomains = host.split('.');

	// for cases like "http://fomf.de/..."
	if (hostDomains.length < 3) {
		return '';
	}

	// Fore Preview Environment return all the subdomains before "preview" (including "preview")
	const previewDomainIndex = hostDomains.indexOf('preview');
	if (previewDomainIndex > 0) {
		return hostDomains.slice(0, previewDomainIndex + 1).join('.') + '.';
	} else {
		// for the following domains: "www.fomf.de", "staging.fomf.de", "dev.fomf.de",
		// and "www.cmed.org.uk", "staging.cmed.org.uk", "dev.cmed.org.uk"
		const domainPrefix = hostDomains[0];

		const result = `${domainPrefix}.`;

		return result;
	}
};

export default getEnvironmentDomainPrefix;
