import React, { useContext } from 'react';
import { TenantConfig } from '../../../catalog/src/utils/tenant';

export type TenantContextType = TenantConfig | null;

const defaultTenantContext = null;

const TenantContext = React.createContext<TenantContextType>(defaultTenantContext);
const useTenantContext = () => useContext(TenantContext);

type TenantContextProviderProps = {
	children: React.ReactNode;
	tenantConfig: TenantConfig | null;
};

const TenantContextProvider = (props: TenantContextProviderProps) => {
	const { tenantConfig } = props;
	return <TenantContext.Provider value={tenantConfig}>{props.children}</TenantContext.Provider>;
};
export { TenantContextProvider, useTenantContext };
