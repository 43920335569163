import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from '../';
import { RadioProps, RadioClasses } from './Radio.types';

const useStyles = makeStyles<ThemeTypes.Theme, RadioProps, RadioClasses>((theme) => ({
	radio: {
		position: 'relative',
		width: 24,
		height: 24,
		border: `1px solid ${theme.palette.label.main}`,
		borderRadius: '50%',
		background: theme.palette.offWhite.main,
		boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
		'&::before': {
			content: '""',
			display: 'block',
			position: 'relative',
			width: 14,
			height: 14,
			left: 4,
			top: 4,
			borderRadius: '50%',
			transition: theme.transitions.create('background-color')
		},
		transition: theme.transitions.create('border-color')
	},
	radioChecked: {
		borderColor: theme.palette.secondary.dark,
		'&::before': {
			background: theme.palette.secondary.dark
		}
	},
	disabled: {
		borderColor: theme.palette.line.main,

		cursor: 'not-allowed',
		pointerEvents: 'all',
		'&$radioChecked': {
			'&::before': {
				background: theme.palette.line.main
			}
		}
	}
}));

export default useStyles;
