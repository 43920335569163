import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		container: {
			display: 'flex',
			width: '100%'
		},
		option: {
			flexBasis: '50%',
			flexGrow: 1,
			margin: theme.spacing(0, 1),
			padding: theme.spacing(0, 1),
			cursor: 'pointer',
			color: theme.palette.grey.dark,
			textAlign: 'center',

			'&:hover, &$selected': {
				'& $optionText::after': { opacity: 1 }
			},

			[theme.breakpoints.up('md')]: {
				'&:first-child': { textAlign: 'right' },
				'&:last-child': { textAlign: 'left' }
			}
		},
		optionText: {
			display: 'inline-block',
			position: 'relative',
			height: 40,
			lineHeight: '40px',
			'&::after': {
				...theme.border({
					kind: 'all',
					color: theme.palette.secondary[400],
					width: 1,
					radius: 1
				}),
				content: '""',
				position: 'absolute',
				left: '50%',
				bottom: 0,
				width: 26,
				transition: theme.transitions.create('opacity'),
				opacity: 0,
				transform: 'translate(-50%, 0)'
			}
		},
		underlineFullWidth: {
			'&::after': {
				width: '100%'
			}
		},
		selected: {
			color: theme.palette.grey[800]
		}
	}),
	{ name: 'ButtonsSwitch' }
);

export default useStyles;
