import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { Box } from '@mui/material';

import { SnackbarProps, VariantType } from './Snackbar.types';
import useStyles from './Snackbar.styles';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

const Snackbar = forwardRef((props: SnackbarProps, ref) => {
	const { message, messageLocaleId, messageValues, close, hideIcon } = props;
	const classes = useStyles(props);
	let variant: VariantType;

	if (!props.variant || props.variant === 'warning' || props.variant === 'default') {
		variant = 'info';
	} else {
		variant = props.variant;
	}

	return (
		<Box
			className={classnames(classes.root, classes[variant], `test_snackbar_${variant}`)}
			ref={ref}
		>
			{!hideIcon && (
				<Icon icon={variant === 'info' ? 'infoSolid' : variant} className={classes.icon} />
			)}
			<Typography title={message} localeId={messageLocaleId} localeValues={messageValues} />
			<Icon icon="close" className={classes.close} onClick={close} />
		</Box>
	);
});

export default Snackbar;
