import dayjs from 'dayjs';

const SECONDS_IN_1_HOUR = 3600;

/**
 * Receives the duration in seconds and formats it to a string with the shape "HH:mm:ss"
 * or "mm:ss" if the duration is less than 1 hour.
 */
const formatDurationInSeconds = (duration?: number): string | null => {
	if (duration) {
		const hasHours = duration > SECONDS_IN_1_HOUR;
		const durationFormat = hasHours ? 'HH:mm:ss' : 'mm:ss';
		return dayjs.duration(duration, 'seconds').format(durationFormat);
	}
	return null;
};

export default formatDurationInSeconds;
