import { ClassNameMap } from '@mui/styles';
import { Fragments } from '../../';
import useStyles from './Speaker.styles';
type SpeakerClassKey = keyof ReturnType<typeof useStyles>;

export enum SpeakerAvatarSize {
	LARGE,
	MEDIUM,
	SMALL
}

export enum SpeakerVariant {
	FULL,
	BRIEF
}

export type SpeakerProps = {
	renderText?: (speaker: Fragments.SpeakerFieldsFragment | null) => React.ReactNode;
	speaker?: Fragments.SpeakerFieldsFragment | null;
	size?: SpeakerAvatarSize;
	showAvatar?: boolean;
	variant?: SpeakerVariant;
	classes?: Partial<ClassNameMap<SpeakerClassKey>>;
	hideDescription?: boolean;
};
