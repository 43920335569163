import { datadogLogs } from '@datadog/browser-logs';
import env from '../env';

export const initDatadogLogs = () => {
	if (typeof window === 'undefined') {
		return;
	}

	const clientToken = env.DD_CLIENT_TOKEN;
	clientToken &&
		datadogLogs.init({
			clientToken,
			site: env.DD_SITE,
			service: env.DD_SERVICE,
			env: env.DD_ENV,
			forwardConsoleLogs: ['error'],
			forwardErrorsToLogs: true,
			sessionSampleRate: 100,
			allowUntrustedEvents: true
		});
};
