import React from 'react';

import { DateTimePickerProps } from './DateTimePicker.types';

import { getInput } from './utils';
import noop from '../utils/noop';
import { TextField } from '../TextField';

const DateTimePicker = (props: DateTimePickerProps) => {
	const {
		format,
		onChange = noop,
		InputVariant = 'DateTime',
		views,
		openTo,
		value,
		...rest
	} = props;

	const Input = getInput(InputVariant, format, views, openTo);

	return (
		<Input
			renderInput={({ onChange: onChangeEvent, onBlur, ...params }) => (
				<TextField {...params} customOnChange={onChange} {...rest} />
			)}
			onChange={onChange}
			value={value || null}
		/>
	);
};

export default DateTimePicker;
