const breakpoints = {
	values: {
		xs: 0,
		sm: 600,
		md: 960,
		lg: 1280,
		xl: 1500
	}
};

export const isMobileUserAgent = (userAgent?: string): boolean | null => {
	if (!userAgent) {
		return null;
	}
	let userAgentToUse: string = userAgent || '';
	if (!userAgentToUse && typeof window !== 'undefined') {
		userAgentToUse = navigator.userAgent;
	}
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgentToUse);
};

export default breakpoints;
