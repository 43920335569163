import React from 'react';
import useStyles from './TopUpsList.styles';
import { TopUpsListProps } from './TopUpsList.types';
import { Box } from '@mui/material';
import { TopUpCard } from '../TopUpCard';
import { Typography } from 'components';
import { Types } from '../../index';

const TopUpsList = (props: TopUpsListProps) => {
	const { membership, eventId } = props;
	const classes = useStyles();

	const topUpsItems = (membership.membershipItems || [])
		.filter((item) => item.membershipItemType === Types.MembershipItemType.TopUp)
		.sort((a, b) => (a.amount || 0) - (b.amount || 0));

	if (topUpsItems.length === 0) {
		return null;
	}

	return (
		<Box>
			<Typography localeId="common.top-ups.offers" className={classes.title} />
			<Box className={classes.list}>
				{topUpsItems.map((topUp, index) => (
					<Box className={classes.listItem} key={topUp.id}>
						<TopUpCard
							topUpItem={topUp}
							membership={membership}
							eventId={eventId}
							index={index}
						/>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default TopUpsList;
