import React from 'react';
import { FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import FormikInputWrapper from '../FormikInputWrapper/FormikInputWrapper';
import { FormikSwitchProps } from './FormikSwitch.types';
import { Switch, ThemeTypes, useFormikLabels } from '../';

const useStyles = makeStyles<ThemeTypes.Theme, FormikSwitchProps, 'label' | 'switch'>((theme) => ({
	label: {
		marginLeft: 0
	},
	switch: {
		marginRight: theme.spacing(2)
	}
}));

const FormikSwitch = (props: FormikSwitchProps) => {
	const { className, name, formik, gridProps, onClick, gridClassName } = props;
	const classes = useStyles(props);
	const { label, formikValue } = useFormikLabels(props);

	if (typeof formikValue !== 'undefined' && typeof formikValue !== 'boolean') {
		throw new Error(`Field ${name} is not a boolean`);
	}

	return (
		<FormikInputWrapper gridProps={gridProps} className={gridClassName}>
			<FormControlLabel
				control={
					<Switch
						name={name}
						checked={formikValue}
						onChange={formik.handleChange}
						onClick={onClick}
						onBlur={formik.handleBlur}
						className={classes.switch}
					/>
				}
				label={label}
				className={classnames(classes.label, className)}
			/>
		</FormikInputWrapper>
	);
};

const EnhancedFormikSwitch = injectIntl(FormikSwitch);

export { FormikSwitch };

export default EnhancedFormikSwitch;
