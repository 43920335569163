import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes } from '../';
import { BadgeProps, BadgeClasses } from './Badge.types';

const useStyles = makeStyles<ThemeTypes.Theme, BadgeProps, BadgeClasses>((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: 3,
		padding: '2px 4px'
	},
	title: {
		textTransform: 'uppercase',
		...theme.typography.caption
	},
	info: {
		background: theme.palette.body[700],
		color: theme.palette.common.white
	},
	error: {
		background: theme.palette.error.dark,
		color: theme.palette.common.white
	},
	success: {
		background: theme.palette.success.dark,
		color: theme.palette.common.white
	},
	info2: {
		background: theme.palette.secondary[100],
		color: theme.palette.primary[600]
	}
}));

export default useStyles;
