import React from 'react';
import { Box } from '@mui/material';
import { HeaderLinksProps } from './HeaderLinks.types';
import { HeaderLink } from './HeaderLink';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		linksContainer: {
			display: 'flex',
			gap: theme.spacing(1),
			height: theme.spacing(5),
			alignItems: 'center'
		},
		separator: {
			width: 1,
			height: theme.spacing(2.5),
			backgroundColor: theme.palette.body.light
		}
	}),
	{ name: 'HeaderLinks' }
);

const flattenWithSeparator = (array: HeaderLinksProps['menuItems'], separatorText: string) => {
	return ([] as HeaderLinksProps['menuItems'][number]).concat(
		...array.map((innerArray, index) => {
			if (index > 0) {
				return [{ localeId: separatorText, href: '' }, ...innerArray];
			} else {
				return innerArray;
			}
		})
	);
};

const HeaderLinks = (props: HeaderLinksProps) => {
	const classes = useStyles();
	const { menuItems } = props;
	const flatMenuItems = flattenWithSeparator(menuItems, 'separator');

	return (
		<Box className={classes.linksContainer}>
			{flatMenuItems.map((menuItem, index) => {
				if (menuItem.localeId === 'separator') {
					return <div key={index} className={classes.separator} />;
				}

				return (
					<HeaderLink
						{...menuItem}
						key={menuItem.localeId}
						href={'href' in menuItem ? menuItem.href : null}
						childLinks={
							'secondLevelLinks' in menuItem ? menuItem.secondLevelLinks : undefined
						}
					/>
				);
			})}
		</Box>
	);
};

export default HeaderLinks;
