import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import type { ThemeTypes } from '../';

export const useStyles = makeStyles((theme: ThemeTypes.Theme) =>
	createStyles({
		stepperHiddenContainer: {
			alignSelf: 'flex-start'
		},
		container: {
			'& .MuiStepConnector-root': {
				flex: 'unset'
			}
		},
		root: {
			background: 'transparent'
		},
		buttonContainer: {
			marginTop: theme.spacing(2)
		},
		button: {
			margin: theme.spacing(3, 2, 3, 1)
		},
		hidden: {
			display: 'none'
		},
		stepLabelContainer: {
			flexDirection: 'row',
			cursor: 'pointer'
		},
		stepLabelLabelContainer: {
			width: 'auto'
		},
		labelContainer: {
			width: 'auto'
		},
		stepLabel: {
			color: theme.palette.label.main,
			...theme.border({ width: 0 })
		},
		currentStepLabel: {
			color: theme.palette.secondary.main
		},
		ball: {
			fontWeight: 'bold',
			color: theme.palette.primary['500'],
			zIndex: 1,
			width: 24,
			height: 24,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.palette.common.white,
			...theme.border({ radius: 24, width: 0 })
		},
		currentBall: {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.common.white
		},
		step: {
			display: 'flex',
			alignItems: 'center'
		},
		firstStepExtraSpace: {
			marginLeft: theme.spacing(4)
		},
		firstStep: {
			paddingLeft: 0
		},
		wrappedDesktopContainer: {}
	})
);

export const useStepperConnectorClasses = makeStyles((theme: ThemeTypes.Theme) =>
	createStyles({
		alternativeLabel: {
			top: 22
		},
		line: {
			height: 1,
			border: 0,
			backgroundColor: 'rgba(0,0,0,0.12)',
			borderRadius: 1,
			width: theme.spacing(7)
		}
	})
);
