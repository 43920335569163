import { LOCALES, Types } from '../index';

const isLabelValue = (item: Translation): item is Types.LabelValueR =>
	!!(item as Types.LabelValueR).label;

const isLabelWithDescription = (item: Translation): item is Types.DescriptionEntity =>
	!!(item as Types.DescriptionEntity).description;

const isTranslatedTextArray = (item: Translation): item is Array<Types.TranslatedText> =>
	Array.isArray(item) && item.every((item) => item.locale && item.value != null)
		? !!(item as Array<Types.TranslatedText>)
		: false;

const isTranslation = (item: Types.Translation): item is Types.Translation =>
	item.__typename === 'Translation';

type JsonTranslation = Record<string, string>;

export const isJsonTranslation = (item: Types.Scalars['JSON']): item is JsonTranslation =>
	Object.keys(item as Record<string, unknown>).some((locale) =>
		LOCALES.includes(locale as Locale)
	);

// TODO: fix this technical debt by developing a generic solution for translations in backend
// https://fomfsite.atlassian.net/browse/FM-210
type Translation =
	| Types.LabelValueR
	| Types.DescriptionEntity
	| Array<Types.TranslatedText>
	| Types.Translation
	| Types.Scalars['JSON'];

/**
 * This function is useful to get the translation given a JSON translation.
 */
const formatJsonTranslation = (item: Types.Scalars['JSON'], locale: Locale) => {
	const itemJsonTranslation = item as JsonTranslation;
	return itemJsonTranslation[locale];
};

const formatTranslation = (
	item: Translation | null | undefined,
	props: {
		locale: Locale;
	}
): string | undefined => {
	const { locale } = props;
	let translations: Array<Types.TranslatedText | Types.Translation> = [];
	if (!item) {
		return;
	}
	if (isLabelValue(item)) {
		translations = item.label.translations || [];
	} else if (isLabelWithDescription(item)) {
		translations = item.description.translations || [];
	} else if (isTranslatedTextArray(item)) {
		translations = item;
	} else if (isJsonTranslation(item)) {
		return formatJsonTranslation(item as Types.Scalars['JSON'], locale);
	} else if (item && isTranslation(item as Types.Translation)) {
		translations = [item as Types.Translation];
	}

	const translation = translations?.find((item) => item.locale.includes(locale));
	return translation?.value;
};

export default formatTranslation;
