import React from 'react';
import SkeletonMUI from '@mui/material/Skeleton';

import useStyles from './Skeleton.styles';
import { SkeletonProps } from './Skeleton.types';
import classNames from 'classnames';

const Skeleton = (props: SkeletonProps) => {
	const classes = useStyles();
	const { className, variant, ...others } = props;
	const isCircle = variant === 'circular';
	return (
		<SkeletonMUI
			variant={variant}
			className={classNames(classes.container, { [classes.rectText]: !isCircle }, className)}
			{...others}
		/>
	);
};

export default Skeleton;
