import React, { createContext, PropsWithChildren } from 'react';
import useLoadAnalytics from '../segment/useLoadAnalytics';

const SegmentContext = createContext(undefined);

/**
 * Context ensures segment analytic tools are loaded
 * @param children
 * @constructor
 */
const SegmentContextProvider = ({ children }: PropsWithChildren) => {
	useLoadAnalytics();

	return <SegmentContext.Provider value={undefined}>{children}</SegmentContext.Provider>;
};

export { SegmentContextProvider, SegmentContext };
