import makeStyles from '@mui/styles/makeStyles';

export const useTextFieldStyles = makeStyles(
	(theme) => ({
		root: {
			flex: 1
		},
		input: {
			padding: 10,
			'&::placeholder': {
				textOverflow: 'ellipsis !important',
				fontSize: 14,
				lineHeight: 20 / 14,
				fontWeight: 400,
				fontFamily: 'Roboto',
				color: theme.palette.primary['500']
			},
			'&:not(:focus)': {
				'&::placeholder': {
					visibility: 'unset'
				}
			}
		},
		notchedOutline: {
			...theme.border({ width: 0, radius: theme.spacing(3) }),
			boxShadow: `0px 1px 6px 0px ${theme.palette.body.light}`
		},
		startAdornment: {
			pointerEvents: 'none',
			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(0.5),
				borderRadius: '50%',
				background: '#F0F2F5'
			}
		},
		startIcon: {
			color: theme.palette.primary.main
		},
		closeIcon: {
			color: '#14142B'
		},
		inputBaseRoot: {
			height: 48,
			padding: theme.spacing(1),
			width: '100%',
			transition: theme.transitions.create('background'),

			'&.Mui-disabled': {
				color: theme.palette.titleActive.main,
				pointerEvents: 'none',
				background: theme.palette.background.default,

				'& $notchedOutline': {
					borderColor: '#F5F5F5'
				}
			},
			'&:active': {
				backgroundColor: theme.palette.common.white
			},
			'&:hover': {
				'& $notchedOutline': {
					borderColor: '#F5F5F5'
				}
			},

			'&.Mui-focused': {
				backgroundColor: theme.palette.common.white,

				'& $notchedOutline': {
					borderColor: '#F5F5F5',
					borderWidth: 1
				}
			},

			'&.Mui-error': {
				'&, &:hover, &.Mui-focused': {
					'& $notchedOutline': {
						borderColor: '#F5F5F5'
					}
				}
			},
			[theme.breakpoints.up('sm')]: {
				maxWidth: 430
			}
		}
	}),
	// to have more priority over TextField styles
	{ name: 'SearchBox', index: 10 }
);

const useStyles = () => ({});

export default useStyles;
