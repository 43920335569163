import makeStyles from '@mui/styles/makeStyles';
import { BulletPointClasses, BulletPointProps } from './BulletPoint.types';
import type { ThemeTypes } from 'components';

const useStyles = makeStyles<ThemeTypes.Theme, BulletPointProps, BulletPointClasses>(
	(theme) => {
		return {
			container: {
				display: 'flex',
				flexDirection: 'row',
				wordBreak: 'break-word'
			},
			icon: {
				marginRight: theme.spacing(1),
				width: theme.spacing(3),
				height: theme.spacing(3),
				flexShrink: 0
			},
			text: {},
			primary: {
				color: theme.palette.primary.main
			},
			secondary: {
				color: theme.palette.secondary.main
			},
			tertiary: {
				color: theme.palette.tertiary.main
			},
			error: {
				color: theme.palette.error.main
			},
			success: {
				color: theme.palette.success.main
			},
			transparent: {
				color: 'transparent'
			},
			white: {
				color: theme.palette.common.white
			},
			dark: {
				color: theme.palette.titleActive.main
			},
			grey: {
				color: theme.palette.grey.light
			}
		};
	},
	{ name: 'BulletPoint' }
);

export default useStyles;
