import React from 'react';
import _get from 'lodash/get';
import classNames from 'classnames';
import {
	Typography as MUITypography,
	TypographyProps as MUITypographyProps,
	useTheme
} from '@mui/material';
import { injectIntl } from 'react-intl';

import { TypographyProps } from './Typography.types';
import useStyles from './Typography.styles';
import { isTypographyVariant } from './utils';

const hasHtmlElements = (text: string) => /(<\/?[a-z][\s\S]*>|&\w+;)/i.test(text);

const Typography = (props: TypographyProps) => {
	const {
		localeId,
		localeValues,
		title,
		variant = 'body1',
		required,
		color,
		colorVariant = 'main',
		className: propsClassName,
		children,
		fontFamily,
		intl,
		style,
		...rest
	} = props;

	const { formatMessage } = intl;

	const formatMessageOptions = { id: localeId };
	let message;
	if (localeId) {
		try {
			message = formatMessage(formatMessageOptions, localeValues);
		} catch (e) {
			message = localeId;
		}
	} else if (title != null) {
		message = title;
	} else {
		message = children;
	}

	const classes = useStyles(props);
	const theme = useTheme();

	const className = classNames(
		classes.root,
		propsClassName,
		isTypographyVariant(variant) && classes[variant],
		...(fontFamily ? [classes[fontFamily]] : [])
	);

	const colorStyle =
		!color || color === 'inherit'
			? color
			: _get(theme.palette, [color, colorVariant], theme.palette.titleActive.main);

	const commonProps = {
		className,
		variant: variant as MUITypographyProps['variant'],
		style: { color: colorStyle, ...style },
		...rest
	};

	const requiredAsterisk = required && <span dangerouslySetInnerHTML={{ __html: '&nbsp;*' }} />;

	if (message == null) {
		return null;
	}

	return typeof message === 'string' && hasHtmlElements(message) ? (
		<MUITypography
			{...commonProps}
			dangerouslySetInnerHTML={{
				__html: message
			}}
		>
			{requiredAsterisk}
		</MUITypography>
	) : (
		<MUITypography {...commonProps}>
			{message}
			{requiredAsterisk}
		</MUITypography>
	);
};

const EnhancedTypography = injectIntl(Typography);

export { Typography };
export default EnhancedTypography;
