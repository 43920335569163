import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from 'components';
import { EditMedicalInfoProps, EditMedicalInfoClasses } from './EditMedicalInfo.types';

const useStyles = makeStyles<ThemeTypes.Theme, EditMedicalInfoProps, EditMedicalInfoClasses>(
	(theme) => ({
		uploadButton: {
			maxWidth: 200,
			fontSize: 12,
			textTransform: 'none'
		},
		spacing: {
			marginBottom: theme.spacing(2)
		},
		certificateTitle: {
			display: 'flex',
			alignItems: 'center'
		},
		certificateMissingContainer: {
			display: 'flex',
			marginLeft: theme.spacing(2),
			color: theme.palette.error.main
		},
		certificateMissingIcon: {
			width: 24,
			height: 24,
			marginRight: theme.spacing(1)
		},
		certificateMissingTitle: {
			position: 'relative',
			top: -12
		}
	})
);

export default useStyles;
