import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		tooltip: {
			borderRadius: 6,
			backgroundColor: theme.palette.grey[300],
			color: theme.palette.primary.main,
			margin: 0
		},
		popper: {
			'& div': {
				margin: 0,
				padding: theme.spacing(0.5)
			}
		}
	}),
	{ name: 'TitleTooltip' }
);

export default useStyles;
