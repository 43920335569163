import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes } from '../../';
import { MonthProps, MonthClasses } from './Month.types';

const useStyles = makeStyles<ThemeTypes.Theme, MonthProps, MonthClasses>(
	(theme) => ({
		root: {
			width: 7 * 36,
			userSelect: 'none'
		},
		titleContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: theme.spacing(2)
		},
		arrow: {
			width: 20,
			height: 20,
			cursor: 'pointer',
			color: theme.palette.body.main,
			transition: theme.transitions.create('color'),

			'&:hover': {
				color: theme.palette.titleActive.main
			}
		},
		arrowBack: {},
		arrowNext: {},
		title: {},

		grid: {
			display: 'grid',
			gridTemplateColumns: 'repeat(7, 36px)',
			columnGap: 0,
			rowGap: 1
		},
		columnTitle: {
			paddingBottom: theme.spacing(1),
			color: theme.palette.label.main,
			textAlign: 'center',
			'&$current': {
				fontWeight: 700,
				color: theme.palette.titleActive.main
			}
		},
		current: {},
		hide: {
			visibility: 'hidden',
			pointerEvents: 'none'
		}
	}),
	{ name: 'Month' }
);

export default useStyles;
