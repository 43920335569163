import React, { createContext, useState, useCallback, useContext } from 'react';

import { Alert, AlertProps, ConfirmationModal } from 'components';

type StrippedAlertProps = Omit<AlertProps, 'onClose'> & {
	onClose?: () => void;
	onAccept?: () => void;
};

export type AlertContextType = {
	isAlertOpened: boolean;
	openAlert: (props: StrippedAlertProps) => void;
	closeAlert: () => void;
	onAccept?: () => void;
};

const AlertContext = createContext<AlertContextType>({
	isAlertOpened: false,
	openAlert: () => undefined,
	closeAlert: () => undefined,
	onAccept: () => undefined
});

type AlertContextProviderProps = {
	children: React.ReactNode;
};

export const AlertContextProvider = (props: AlertContextProviderProps) => {
	const { children } = props;

	const [isAlertOpened, setIsAlertOpened] = useState(false);
	const [alertProps, setAlertProps] = useState<StrippedAlertProps | undefined>(undefined);

	const openAlert = useCallback((props: StrippedAlertProps) => {
		setIsAlertOpened(true);
		setAlertProps(props);
	}, []);

	const handleResetAlert = () => {
		setIsAlertOpened(false);
		setAlertProps(undefined);
	};

	const closeAlert = useCallback(() => {
		alertProps?.onClose && alertProps.onClose();
		handleResetAlert();
	}, [alertProps]);

	const onAccept = useCallback(() => {
		if (alertProps?.onAccept) {
			alertProps.onAccept();
			handleResetAlert();
		} else {
			closeAlert();
		}
	}, [alertProps]);

	const contextValue = {
		isAlertOpened,
		openAlert,
		closeAlert,
		onAccept
	};

	return (
		<AlertContext.Provider value={contextValue}>
			{children}
			{isAlertOpened &&
				alertProps &&
				(alertProps.variant === 'info' ? (
					<ConfirmationModal
						open
						{...alertProps}
						titleLocaleId={alertProps.titleLocaleId || ''}
						descriptionLocaleId={alertProps.messageLocaleId}
						descriptionLocaleValues={alertProps.messageValues}
						onAccept={onAccept}
					/>
				) : (
					<Alert {...alertProps} onClose={closeAlert} />
				))}
		</AlertContext.Provider>
	);
};

export const useAlert = () => {
	return useContext(AlertContext);
};
