import React from 'react';
import classnames from 'classnames';
import { injectIntl, IntlShape } from 'react-intl';
import { Box, IconButton } from '@mui/material';
import { Icon, ThemeTypes, Typography } from 'components';

import { formatCountry, Fragments, Types, useUserFullName } from '../../';

import useStyles from './UserAddress.styles';

type AddressProps = {
	user: Types.UserR | Fragments.UserFieldsFragment;
	address: Types.AddressR;
	intl: IntlShape;
	className?: string;
	compact?: boolean;
	typographyVariant?: ThemeTypes.AllTypography;
	onEdit?: () => void;
	onDelete?: () => void;
};

const UserAddress = (props: AddressProps) => {
	const { user, address, intl, className, compact, typographyVariant, onEdit, onDelete } = props;
	const { locale } = intl;
	const { postalCode, country, city, apartmentNumber, street } = address;

	const classes = useStyles();

	const userFullName = useUserFullName(user, intl);

	const fullName = address.fullName || userFullName;
	const countryLabel = country && formatCountry(country, { locale });

	return (
		<Box className={classnames(classes.root, className)}>
			{compact ? (
				<>
					<Typography variant={typographyVariant}>{fullName}</Typography>
					<Typography variant={typographyVariant}>
						{street} {apartmentNumber}
						{street || apartmentNumber ? ',' : ''} {postalCode} {city}
						{postalCode || city ? ',' : ''} {countryLabel}
					</Typography>
				</>
			) : (
				<>
					<Typography variant={typographyVariant}>{fullName}</Typography>
					<Typography variant={typographyVariant}>
						{street} {apartmentNumber}
					</Typography>
					<Typography variant={typographyVariant}>
						{postalCode} {city}
					</Typography>
					<Typography variant={typographyVariant}>{countryLabel}</Typography>
				</>
			)}
			{onEdit && (
				<IconButton onClick={onEdit} className={classes.edit}>
					<Icon icon="pencil" />
				</IconButton>
			)}
			{onDelete && (
				<IconButton onClick={onDelete} className={classes.delete}>
					<Icon icon="trash" />
				</IconButton>
			)}
		</Box>
	);
};

const EnhancedAddress = injectIntl(UserAddress);

export { UserAddress };

export default EnhancedAddress;
