import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		dropdownText: {
			display: 'flex',
			fontWeight: 700,
			color: theme.palette.primary.main,
			alignItems: 'center',
			opacity: 1
		},
		dropdownIcon: {
			width: 18,
			height: 18,
			marginRight: theme.spacing(0.5)
		},
		root: {
			margin: theme.spacing('auto', 'auto', 2, 2),
			[theme.breakpoints.up('sm')]: {
				margin: theme.spacing(0, 0, 0, 'auto')
			}
		},
		dropdown: {
			paddingRight: 0
		},
		dropdownInputBaseRoot: {
			minWidth: 'unset',
			paddingRight: 0,
			'& $dropdownSelect$dropdownInput': {
				borderRadius: 10,
				paddingRight: theme.spacing(4),
				transition: theme.transitions.create('background'),

				'&:hover': {
					backgroundColor: theme.palette.body['400']
				}
			}
		},
		dropdownSelect: {},
		dropdownInput: {},
		menuPaper: {
			...theme.border({
				width: 1,
				color: theme.palette.body.light,
				radius: theme.spacing(1)
			}),
			background: theme.palette.common.white
		}
	}),
	{ name: 'LocaleSwitch', index: 100 }
);

export default useStyles;
