import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		root: {
			marginLeft: -9
		},
		radio: {
			marginRight: theme.spacing(1)
		}
	}),
	{ name: 'RadioGroupItem' }
);

export default useStyles;
