import { Types } from '..';

export const creditTransferStatus = [
	{
		id: Types.CreditTransferStatus.NotTransferred,
		localeId: 'common.participants.credit-transfer-status.not-transferred'
	},
	{
		id: Types.CreditTransferStatus.Transferred,
		localeId: 'common.participants.credit-transfer-status.transferred'
	}
];

export const certificateStatus = [
	{
		id: Types.CertificateStatus.NotSent,
		localeId: 'common.participants.certificate-status.not-sent'
	},
	{
		id: Types.CertificateStatus.Sent,
		localeId: 'common.participants.certificate-status.sent'
	},
	{
		id: Types.CertificateStatus.WithError,
		localeId: 'common.participants.certificate-status.with-error'
	}
];
