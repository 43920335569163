import { CookiesState, COOKIES_STATE_KEY } from '.';
import getState from './getState';
import setCookie from './setCookie';

const getLatestStateData = (): CookiesState[string] | null | undefined => {
	const state = getState();
	if (!state || Object.keys(state).length === 0) {
		return null;
	}
	const sortedState = Object.values(state).sort(
		(nonceA, nonceB) => nonceB.timestamp - nonceA.timestamp
	);
	const latestStateData = sortedState[0];
	const nonce = Object.keys(state).find(
		(key) => state[key]?.timestamp === latestStateData?.timestamp
	);
	if (nonce) {
		delete state[nonce];
		setCookie(COOKIES_STATE_KEY, JSON.stringify(state)); // to delete the nonce
		return latestStateData;
	}
	return null;
};

export default getLatestStateData;
