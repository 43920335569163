import React from 'react';
import { UserMedicalNumberModalProps } from './UserMedicalNumberModal.types';
import {
	Button,
	Dialog,
	FormikTextField,
	Loader,
	SchemaValues,
	Typography,
	useCustomFormik
} from 'components';
import { DialogContent } from '@mui/material';
import * as yup from 'yup';
import {
	formatMedicalIdNameByCountry,
	Fragments,
	Mutations,
	Queries,
	Types,
	useExecMutation,
	useUserContext
} from '../../index';
import { medicalIdentificationNumber } from '../../formValidation/user/userFields';
import { useIntl } from 'react-intl';

const getValidationSchema = () =>
	yup.object({
		...medicalIdentificationNumber(false)
	});

type FormValues = SchemaValues<typeof getValidationSchema>;

const getInitialValues = (user: Fragments.UserFieldsFragment | null | undefined): FormValues => ({
	country: user?.country || undefined,
	email: user?.email,
	medicalIdentificationNumber: user?.medicalIdentificationNumber || undefined,
	healthProfessionalConfirmation: user?.healthProfessionalConfirmation || undefined
});

const UserMedicalNumberModal = (props: UserMedicalNumberModalProps) => {
	const { isOpen, onClose } = props;
	const { locale } = useIntl();
	const { user: authUser } = useUserContext();
	const { data, loading: userLoading } = Queries.useGetUserForDashboardQuery({
		variables: {
			userId: authUser?.id || ('' as Types.UUID)
		},
		skip: !authUser
	});
	const [updateUser, { loading: updateUserLoading }] =
		Mutations.useUpdateUserDetailsForUserMutation();
	const execMutation = useExecMutation();

	const user = data?.user;

	const formik = useCustomFormik({
		initialValues: getInitialValues(user),
		validationSchema: getValidationSchema(),
		enableReinitialize: true,
		onSubmit: (values) => {
			if (!user) {
				return;
			}

			execMutation(updateUser, {
				variables: {
					userId: user.id,
					updateUserRequest: {
						medicalIdentificationNumber: values.medicalIdentificationNumber,
						healthProfessionalConfirmation: false
					}
				}
			});
		}
	});

	const medicalIdName = formatMedicalIdNameByCountry(user?.country, locale);

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogContent>
				{userLoading ? (
					<Loader />
				) : (
					<>
						<Typography
							localeId="common.user-medical-number-modal.label"
							localeValues={{ medicalIdName }}
							mb={2}
						/>
						<FormikTextField
							name="medicalIdentificationNumber"
							label={medicalIdName}
							formik={formik}
							disabled={updateUserLoading}
							gridProps={{ mb: 2 }}
						/>
						<Button onClick={formik.submitForm} localeId="common.save" fullWidth />
					</>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default UserMedicalNumberModal;
