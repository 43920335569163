import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import FormikInputWrapper from '../FormikInputWrapper/FormikInputWrapper';
import { useFormikProps } from '../hooks';
import { Autocomplete, AutocompletePropsMultiple, FormikInputProps } from '../index';

type Props = Omit<FormikInputProps<AutocompletePropsMultiple>, 'getOptionSelected'>;

const FormikAutocomplete = (props: Props) => {
	const { formik, gridProps, gridClassName, name, intl, localeId, ...rest } = props;
	const getFormikProps = useFormikProps(formik);
	const { error, hasError, value, required, label, onChange, onBlur } = getFormikProps({
		name,
		...(localeId ? { localeId } : {})
	});

	if (typeof value !== 'undefined' && !Array.isArray(value)) {
		throw new Error(`Field ${name} is not an array`);
	}

	const autocompleteValue = typeof value === 'undefined' || value === null ? [] : value;
	return (
		<FormikInputWrapper gridProps={gridProps} className={gridClassName}>
			<Autocomplete
				fullWidth
				onChange={(e, value) => onChange(value)}
				onBlur={onBlur}
				label={label}
				value={autocompleteValue}
				required={required}
				error={hasError}
				helperText={hasError && <FormattedMessage {...error} />}
				{...rest}
			/>
		</FormikInputWrapper>
	);
};

const EnhancedFormikTextField = injectIntl(FormikAutocomplete);

export { FormikAutocomplete };

export default EnhancedFormikTextField;
