import type { ComponentsTypes } from 'components';

import { formatters, Queries, Types } from '../index';

type Props = {
	locale: string;
	/**
	 * If the gender is not provided, null, then all the academic degrees available will be used,
	 * both male and female titles. On the contrary, when the gender is provided the corresponding
	 * academic degrees for male or female will be used.
	 */
	gender: Types.Gender | null | undefined;
};

const useAcademicDegreesOptions = (props: Props): Array<ComponentsTypes.SelectableOption> => {
	const { locale, gender } = props;
	const { data: allAcademicDegreesData } = Queries.useGetAcademicDegreesQuery({
		variables: {
			locale
		}
	});
	const academicDegreesOptions: Array<ComponentsTypes.SelectableOption> = (
		allAcademicDegreesData?.userAcademicDegrees || []
	)
		.filter((item) => !item.gender || !gender || item.gender === gender)
		.map((item) => {
			const academicDegreeLabel = formatters.formatEntityWithDescription(item, { locale });
			const label = academicDegreeLabel.startsWith(', ')
				? academicDegreeLabel.replace(', ', '')
				: academicDegreeLabel;
			return {
				id: item.code,
				label
			};
		})
		.filter((item) => item.label.length > 0);

	return academicDegreesOptions;
};

export default useAcademicDegreesOptions;
