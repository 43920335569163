import getDomainFromLocation from './utils/getDomainFromLocation';
import * as Types from './graphql/generated';
import { COUNTRY_CODE_3_TO_DOMAIN } from './constants';

type ENV = 'prod' | 'staging';

const FOMF_API_BASE_URL =
	process.env.NEXT_PUBLIC_FOMF_API_BASE_URL || process.env.REACT_APP_FOMF_API_BASE_URL;
const FOMF_SERVICE_API_BASE_URL =
	process.env.NEXT_PUBLIC_FOMF_SERVICE_API_BASE_URL ||
	process.env.REACT_APP_FOMF_SERVICE_API_BASE_URL;
const CMED_API_BASE_URL =
	process.env.NEXT_PUBLIC_CMED_API_BASE_URL || process.env.REACT_APP_CMED_API_BASE_URL;

const SAAS_USER_BASE_URL = process.env.NEXT_PUBLIC_SAAS_USER_BASE_URL;

const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID;

const OAUTH_SERVER_BASE_URL =
	process.env.NEXT_PUBLIC_OAUTH_SERVER_BASE_URL || process.env.REACT_APP_OAUTH_SERVER_BASE_URL;

const OAUTH_CLIENT_ID =
	process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID || process.env.REACT_APP_OAUTH_CLIENT_ID;

export const getApiDomain = () => {
	const domain = getDomainFromLocation();
	const apiDomain =
		domain === 'uk'
			? CMED_API_BASE_URL
			: (FOMF_API_BASE_URL || '').replace('.org', `.${domain}`);
	return apiDomain;
};
export const getServiceApiDomain = () => {
	const domain = getDomainFromLocation();
	const apiDomain =
		domain === 'uk'
			? CMED_API_BASE_URL
			: (FOMF_SERVICE_API_BASE_URL || '').replace('.org', `.${domain}`);
	return apiDomain;
};

// for user-service always stick to the main api
const USER_SERVICE_BASE_URL = () =>
	`${getServiceApiDomain()?.replace('api-vercel', 'api')}/user-service`;

const BOOKING_SERVICE_BASE_URL = () => `${getServiceApiDomain()}/booking-service`;

const APOLLO_CLIENT_URI = () => `${getApiDomain()}/graph`;

const TENANT_ID = (process.env.NEXT_PUBLIC_TENANT_ID ||
	process.env.REACT_APP_TENANT_ID) as Types.UUID;

const STRIPE_PUBLISHABLE_KEY = (country?: CountryCode3) => {
	const domainToKey: Record<Domain, string | undefined> = {
		de:
			process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_DE ||
			process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DE,
		at:
			process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_AT ||
			process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_AT,
		ch:
			process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_CH ||
			process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_CH,
		uk:
			process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_UK ||
			process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_UK
	};
	const domain = country ? COUNTRY_CODE_3_TO_DOMAIN[country] : getDomainFromLocation();
	return domainToKey[domain] || '';
};

const NODE_ENV = process.env.NODE_ENV;
const ENV = process.env.NEXT_PUBLIC_ENV as ENV | undefined;
const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const GOOGLE_MAP_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY;

const SEGMENT_WRITE_KEY_BY_LOCALE = (locale: Locale) => {
	switch (locale) {
		case 'de-DE':
			return process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_DEU;
		case 'de-AT':
			return process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_AUT;
		case 'de-CH':
		case 'fr-CH':
			return process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_CHE;
		case 'en-GB':
			return process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_GBR;
	}
};

const DD_ENV = process.env.NEXT_PUBLIC_DD_ENV || process.env.REACT_APP_DD_ENV;
const DD_VERSION = process.env.NEXT_PUBLIC_DD_VERSION || process.env.REACT_APP_DD_VERSION;
const DD_SERVICE = process.env.NEXT_PUBLIC_DD_SERVICE || process.env.REACT_APP_DD_SERVICE;
const DD_SITE = process.env.NEXT_PUBLIC_DD_SITE || process.env.REACT_APP_DD_SITE;
const DD_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN;

const DATOCMS_READONLY_API_TOKEN = process.env.DATOCMS_READONLY_API_TOKEN;

const LAUNCHDARKLY_SERVER_SIDE_SDK_KEY =
	process.env.NEXT_PUBLIC_LAUNCHDARKLY_SERVER_SIDE_SDK_KEY ||
	process.env.REACT_APP_LAUNCHDARKLY_SERVER_SIDE_SDK_KEY;

const LAUNCHDARKLY_CLIENT_SIDE_ID =
	process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID ||
	process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID;

if (!LAUNCHDARKLY_CLIENT_SIDE_ID) {
	throw new Error('LAUNCHDARKLY_CLIENT_SIDE_ID is not defined');
}

const IS_PREVIEW_ENVIRONMENT =
	process.env.NEXT_PUBLIC_IS_PREVIEW_ENVIRONMENT || process.env.IS_PREVIEW_ENVIRONMENT;

const env = {
	SAAS_USER_BASE_URL,
	OAUTH_SERVER_BASE_URL,
	OAUTH_CLIENT_ID,
	FOMF_API_BASE_URL,
	USER_SERVICE_BASE_URL,
	BOOKING_SERVICE_BASE_URL,
	TENANT_ID,
	APOLLO_CLIENT_URI,
	STRIPE_PUBLISHABLE_KEY,
	NODE_ENV,
	PAYPAL_CLIENT_ID,
	ENV,
	GTM_ID,
	GOOGLE_MAP_API_KEY,
	SEGMENT_WRITE_KEY_BY_LOCALE,
	DD_VERSION,
	DD_SERVICE,
	DD_SITE,
	DD_CLIENT_TOKEN,
	DD_ENV,
	LAUNCHDARKLY_SERVER_SIDE_SDK_KEY,
	LAUNCHDARKLY_CLIENT_SIDE_ID,
	IS_PREVIEW_ENVIRONMENT,
	DATOCMS_READONLY_API_TOKEN
};

export default env;
