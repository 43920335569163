import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { DOMAIN_TO_LOCALE } from '../constants';
import { useHydrationContext } from '../contexts';

export const useRedirectByDomain = () => {
	const { isHydrated } = useHydrationContext();
	const { query, asPath } = useRouter();

	// private function to create the new url
	const _getNewRedirectUrl = useCallback(
		(
			origin: string,
			port: string,
			newDomain: Domain,
			asPath: string,
			search: string
		): string | null => {
			try {
				const asPathWithoutQueryParams = asPath.split('?')[0] || asPath;
				let url;
				if (newDomain === 'uk') {
					url = `https://cmed.org.uk${asPathWithoutQueryParams}`;
				} else if (port) {
					const locale = DOMAIN_TO_LOCALE[newDomain];
					const urlLocale = locale === 'fr-CH' || locale === 'de-CH' ? '/fr-CH' : '';
					url = `${origin}${urlLocale}${asPathWithoutQueryParams}`;
				} else {
					url = `${origin.split('.').slice(0, -1).join('.')}.${newDomain}${asPathWithoutQueryParams}`;
				}
				const params = new URLSearchParams(search);
				params.append('isRedirected', 'true');
				return `${url}?${params.toString()}`;
			} catch (e) {
				console.error(e);
				return null;
			}
		},
		[]
	);

	// public function that returns the new redirect url when ready
	const getNewRedirectUrl = useCallback(
		(newDomain: Domain) => {
			// to ensure window is available as we need it to generate the url
			if (!isHydrated) {
				return null;
			}
			// To prevent entering in a redirection loop
			if (query.isRedirected) {
				return null;
			}
			return _getNewRedirectUrl(
				window.location.origin,
				window.location.port,
				newDomain,
				asPath,
				window.location.search
			);
		},
		[_getNewRedirectUrl, isHydrated, query.isRedirected]
	);

	const redirectToNewDomain = useCallback(
		(newDomain: Domain) => {
			const newUrl = getNewRedirectUrl(newDomain);
			if (newUrl) {
				window.location.href = newUrl;
			}
		},
		[getNewRedirectUrl]
	);

	return { redirectToNewDomain, getNewRedirectUrl };
};
