import Dayjs from 'dayjs';

type NormalPriceListProp = {
	normalPriceExpirationDays: number;
};
type EarlyBirdPriceListProp = {
	earlyBirdExpirationWeeks: number;
};
type ContentProp = {
	startDateTime?: string | null;
} | null;

const getEarlyBirdExpirationDate = (priceList?: EarlyBirdPriceListProp, content?: ContentProp) => {
	if (!priceList || !content) {
		return null;
	}
	return Dayjs(content.startDateTime).subtract(priceList.earlyBirdExpirationWeeks, 'week');
};

const getNormalExpirationDate = (priceList?: NormalPriceListProp, content?: ContentProp) => {
	if (!priceList || !content) {
		return null;
	}
	return Dayjs(content.startDateTime).subtract(priceList.normalPriceExpirationDays, 'day');
};

export default {
	getEarlyBirdExpirationDate,
	getNormalExpirationDate
};
