import { DateFormat, flattenDateFormat, parseDBDate } from 'components';
import { ConfigType, Dayjs, isDayjs } from 'dayjs';
import { dayjsWithTimezone } from '../utils';

const formatDate = (
	dateString: ConfigType,
	outputFormat: DateFormat = DateFormat.dateTime
): string => {
	const dayjsDate: Dayjs = isDayjs(dateString)
		? dayjsWithTimezone(dateString)
		: dayjsWithTimezone(parseDBDate(dateString));
	if (dayjsDate.isValid()) {
		const currentLocale = dayjsDate.locale() as DayJSLocale;
		const format = flattenDateFormat(outputFormat, currentLocale);

		return dayjsDate.format(format);
	}
	return 'Invalid Date';
};

export default formatDate;
