import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from '../';

const useStyles = makeStyles<ThemeTypes.Theme>((theme) => ({
	content: {
		padding: theme.spacing(1, 2)
	}
}));

export default useStyles;
