import classnames from 'classnames';
import React from 'react';
import { DialogActions, DialogContentText, DialogTitle, DialogContent } from '@mui/material';

import { Button, Dialog, Typography } from '../..';
import { ConfirmationModalProps } from './ConfirmationModal.types';
import useStyles from './ConfirmationModal.styles';

const ConfirmationModal = (props: ConfirmationModalProps) => {
	const {
		acceptTextLocaleId = 'common.confirmation-modal.accept',
		cancelLocaleId = 'common.confirmation-modal.cancel',
		descriptionLocaleId,
		descriptionLocaleValues,
		titleLocaleId = 'common.confirmation-modal.title',
		titleValues,
		open,
		onAccept,
		onCancel,
		title,
		longActionTitles,
		disabled: disabledProp,
		children,
		disableAccept = false,
		disableCancel = false,
		...others
	} = props;
	const classes = useStyles(props);
	const disabled = disabledProp || (disableAccept && disableCancel);
	return (
		<Dialog open={open} {...others}>
			<DialogContent className={classes.container}>
				<DialogTitle className={classes.dialogTitle}>
					<Typography
						variant="subtitle1"
						localeId={titleLocaleId}
						localeValues={titleValues}
						className={classes.title}
					/>
				</DialogTitle>
				{(descriptionLocaleId || children) && (
					<DialogContentText className={classes.dialogContent}>
						<Typography
							localeId={descriptionLocaleId}
							align="center"
							localeValues={descriptionLocaleValues}
						/>
						{children}
					</DialogContentText>
				)}
				<DialogActions
					className={classnames(classes.dialogActions, {
						[classes.singleAction]: !onCancel,
						[classes.longActionTitles]: longActionTitles
					})}
				>
					{onCancel && (
						<Button
							onClick={onCancel}
							localeId={cancelLocaleId}
							variant="contained"
							className={classes.actionButton}
							disabled={disabled || disableCancel}
						/>
					)}
					<Button
						onClick={onAccept}
						localeId={acceptTextLocaleId}
						className={classes.actionButton}
						disabled={disabled || disableAccept}
					/>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};

export default ConfirmationModal;
