const DEFAULT_RANDOM_STRING_LENGTH = 24;

const generateRandomString = (length: number = DEFAULT_RANDOM_STRING_LENGTH) => {
	let result = '';
	const availableCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	for (let i = 0; i < length; i++) {
		result += availableCharacters.charAt(
			Math.floor(Math.random() * availableCharacters.length)
		);
	}
	return result;
};

export default generateRandomString;
