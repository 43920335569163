import { ApolloLink, Operation, NextLink } from '@apollo/client';
import { OperationDefinitionNode } from 'graphql';
import formatMessage from './formatMessage';
import logging from './logging';

const loggerLink = new ApolloLink((operation: Operation, forward: NextLink) => {
	const startTime = new Date().getTime();

	return forward(operation).map((result) => {
		if (process.env.NODE_ENV === 'development') {
			const operationNode = operation.query.definitions.find(
				(each) => (each as OperationDefinitionNode).operation
			);
			const operationType = (operationNode as OperationDefinitionNode)?.operation ?? '';
			const ellapsed = new Date().getTime() - startTime;

			const group = formatMessage(operationType, operation, ellapsed);

			logging.groupCollapsed(...group);

			logging.log('INIT', operation);
			logging.log('RESULT', result);

			logging.groupEnd(...group);
		}
		return result;
	});
});

export default loggerLink;
