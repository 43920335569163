export const getSHA256Hash = async (input: string): Promise<string> => {
	if (typeof window === 'undefined') {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const { createHash } = require('crypto');
		const hashString = createHash('sha256').update(input).digest('hex');
		return hashString;
	} else {
		const encoder = new TextEncoder();
		const data = encoder.encode(input);
		const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);

		const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
		const hashString = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
		return hashString;
	}
};
