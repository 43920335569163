import { booleanFilter, ContentTransformers, formatters, initializeApollo, Types } from 'common';
import { GetCompanyConfiguration } from './queries';
import { GetCompanyConfigurationQuery } from './queries.generated';
import getContentById from '../queries/getContentById';
import { ParsedUrlQuery } from 'querystring';

/**
 * List of tenant ids that have a custom version of the platform.
 */
export const companyTenantsIds: Array<Types.UUID> = JSON.parse(
	process.env.NEXT_PUBLIC_TENANT_IDS || '["a3ea5c07-3cfa-4687-b552-4970934fe5d9"]'
);
// eslint-disable-next-line no-console
console.log('process.env.NEXT_PUBLIC_TENANT_IDS', process.env.NEXT_PUBLIC_TENANT_IDS);
// eslint-disable-next-line no-console
console.log('companyTenantsIds', companyTenantsIds);

export type TenantConfig = GetCompanyConfigurationQuery['company'] & {
	contentTitle?: string;
};

export const getTenantsConfiguration = async (): Promise<Array<TenantConfig>> => {
	try {
		const apolloClient = initializeApollo();
		const configurations = await Promise.all(
			companyTenantsIds
				.map(async (tenantId) => {
					const { data } = await apolloClient.query({
						query: GetCompanyConfiguration,
						variables: {
							companyId: tenantId
						}
					});
					return data?.company;
				})
				.filter(booleanFilter)
		);

		return configurations;
	} catch (_error) {
		console.error('Error while fetching tenants configuration', _error);
		return [];
	}
};

export const getTenantConfigFromPathname = async (
	tenantsConfig: Array<TenantConfig>,
	pathname: string,
	query: ParsedUrlQuery,
	messages: Record<string, string>
): Promise<TenantConfig | null> => {
	if (
		!pathname.includes('/mediathek/') &&
		pathname.match(/\/content\/[^/]+/) &&
		typeof query?.id === 'string' &&
		query.id != null
	) {
		const { content } = await getContentById(query.id);
		const currentTenantConfig = tenantsConfig.find(
			(config) => content?.provider?.id === config.id
		);
		if (currentTenantConfig && content) {
			return {
				...currentTenantConfig,
				contentTitle: `${content?.title || ''} - ${formatters.formatEnum(content.brand, {
					options: ContentTransformers.brand,
					messages
				})}`
			};
		}
		return null;
	} else if (pathname.includes('/partner-fortbildungsforum/')) {
		return (
			tenantsConfig.find((config) => pathname.includes(config.companyPage?.slug || '')) ||
			null
		);
	} else if (
		[
			'/user/login',
			'/user/registration',
			'/user/complete-profile',
			'/user/forgot-password',
			'/user/reset-password'
		].some((path) => pathname.includes(path))
	) {
		// Split the pathname into path and search parts
		const [, searchString] = pathname.split('?');
		if (!searchString) {
			return null;
		}

		const searchParams = new URLSearchParams(searchString);
		const tenant = searchParams.get('tenant');
		return tenantsConfig.find((config) => tenant === config.companyPage?.slug) || null;
	}
	return null;
};
