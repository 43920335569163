import { Types } from '..';

export const description = [
	{
		id: Types.SponsorshipType.Gold,
		localeId: 'common.sponsor-description.gold'
	},
	{
		id: Types.SponsorshipType.Head,
		localeId: 'common.sponsor-description.head'
	},
	{
		id: Types.SponsorshipType.Silver,
		localeId: 'common.sponsor-description.silver'
	},
	{
		id: Types.SponsorshipType.Platin,
		localeId: 'common.sponsor-description.platin'
	}
];
