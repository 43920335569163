import makeStyles from '@mui/styles/makeStyles';

const DRAWER_MENU_XS_WIDTH = 296;
const DRAWER_MENU_MD_WIDTH = 296;

const useStyles = makeStyles(
	(theme) => ({
		root: {
			display: 'flex',
			maxWidth: '100vw',

			[theme.breakpoints.up('md')]: {
				'& $appBar': {
					zIndex: theme.zIndex.drawer + 1
				},
				'& $drawerPaper': {
					width: DRAWER_MENU_MD_WIDTH
				},
				'& $drawer': {
					flexShrink: 0,
					whiteSpace: 'nowrap'
				},
				'& $drawerOpen': {
					width: DRAWER_MENU_MD_WIDTH,
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.enteringScreen
					}),

					'& $link': {
						padding: theme.spacing(2)
					}
				},
				'& $drawerClose': {
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen
					}),
					width: DRAWER_MENU_MD_WIDTH
				},
				'& $toolbar': {
					height: theme.headerHeight.lg,
					padding: theme.spacing(3)
				},
				'& $content': {
					paddingTop: theme.headerHeight.lg
				},
				'& $toolbarGutters': {
					padding: theme.spacing(0, 3)
				},

				'& $listItemIcon': {
					marginRight: theme.spacing(3)
				},

				'& $mobileDrawer': {
					display: 'none'
				}
			}
		},
		appBar: {
			backgroundColor: theme.palette.common.white,
			color: theme.palette.primary.main,
			boxShadow: '0px 2px 3px rgb(0 0 0 / 4%)'
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(2),
			color: theme.palette.common.white
		},
		drawerPaper: {
			width: DRAWER_MENU_XS_WIDTH,
			backgroundColor: theme.palette.common.white
		},
		content: {
			flexGrow: 1,
			maxWidth: '100%',
			paddingTop: theme.headerHeight.sm
		},
		toolbarGutters: {
			padding: theme.spacing(0, 2)
		},
		menuItem: {
			padding: 0,
			margin: theme.spacing(1, 0),
			borderRadius: 10,
			height: theme.spacing(6),
			'&:hover, &$activeItem': {
				backgroundColor: theme.palette.secondary['100']
			},
			'&$activeItem $menuItemLinkTitle': {
				fontWeight: 600
			}
		},
		link: {
			color: theme.palette.body['700'],
			height: '100%',
			minHeight: 48,
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(1.5),
			overflow: 'hidden',
			whiteSpace: 'normal',
			transition: theme.transitions.create([
				'background-color',
				'border-color, color',
				'padding'
			]),

			'&:hover': {
				textDecoration: 'none',
				backgroundColor: 'unset'
			}
		},
		menuItemLinkTitle: {
			paddingRight: theme.spacing(3),
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			fontWeight: 400,
			color: theme.palette.primary.main
		},
		menuIcon: {
			width: 24,
			height: 24
		},

		list: {
			padding: theme.spacing(0, 2),
			marginBottom: theme.spacing(3),
			'&:last-child': {
				marginBottom: 0
			}
		},
		listItem: {
			marginBottom: theme.spacing(2),
			padding: theme.spacing(0),

			'&:last-child': {
				marginBottom: 0
			}
		},
		listItemIcon: {
			marginRight: theme.spacing(1),
			width: 24,
			height: 24,
			'& > svg': {
				color: 'currentColor'
			}
		},
		linkIcon: {
			width: 24,
			height: 24
		},
		badge: {
			borderRadius: 16,
			position: 'absolute',
			right: 16,
			height: 32,
			minWidth: 32,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: 18,
			fontWeight: 'bold',
			color: 'white',
			backgroundColor: theme.palette.error.dark
		},
		subMenuIcon: {
			width: 10,
			height: 12
		},
		drawer: {},
		drawerOpen: {},
		drawerClose: {},
		mobileDrawer: {},
		activeItem: {},
		itemsContainer: {}
	}),
	{ name: 'DrawerMenu', index: 1 }
);

export default useStyles;
