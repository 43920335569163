import * as React from 'react';
import { Popover } from '@mui/material';

import useStyles from './UncontrolledPopover.styles';
import type { UncontrolledPopoverProps } from './UncontrolledPopover.types';

const UncontrolledPopover = (props: UncontrolledPopoverProps) => {
	const { children, target, containerClassName, openOnHover, anchorOrigin } = props;
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
	const classes = useStyles(props);

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event?.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const targetComponent = React.cloneElement(target, { onClick: handleClick });

	return (
		<div
			className={containerClassName}
			{...(openOnHover
				? { onMouseEnter: handleClick, onMouseLeave: handleClose }
				: undefined)}
		>
			{targetComponent}
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
					...anchorOrigin
				}}
				{...props}
			>
				<div className={classes.content}>{children}</div>
			</Popover>
		</div>
	);
};

export default UncontrolledPopover;
