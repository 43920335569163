import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		icon: {
			position: 'absolute',
			width: theme.spacing(2.5),
			height: theme.spacing(2.5),
			top: theme.spacing(1),
			right: theme.spacing(1),
			cursor: 'pointer',
			zIndex: 50
		},
		content: {
			width: '100%',
			background: theme.palette.common.white,
			[theme.breakpoints.up('md')]: {
				minWidth: 400
			}
		},
		paper: {}
	}),
	{ name: 'Modal' }
);

export default useStyles;
