import formatCurrency from './formatCurrency';

const prefixSymbols = (['GBP'] as const).map(formatCurrency);
const symbolsWithSpace = (['EUR', 'CHF'] as const).map(formatCurrency);

const formatPrice = (price: number, currencySymbol?: string | null | undefined): string => {
	currencySymbol = currencySymbol || '€';
	const isNegative = price < 0;
	const absolutePrice = Math.abs(price);
	const priceString = (absolutePrice / 100).toFixed(2);
	const [integer, fractional] = priceString.split('.');
	const fractionalFinal = fractional === '00' ? '' : `,${fractional}`;
	const sign = isNegative ? '-' : '';
	const isPrefix = prefixSymbols.includes(currencySymbol);
	const isSpaced = symbolsWithSpace.includes(currencySymbol);

	const currencyString = isSpaced
		? isPrefix
			? `${currencySymbol} `
			: ` ${currencySymbol}`
		: currencySymbol;

	const prefix = isPrefix ? currencyString : '';
	const suffix = isPrefix ? '' : currencyString;
	return `${sign}${prefix}${integer}${fractionalFinal}${suffix}`;
};

export default formatPrice;
