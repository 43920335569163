import React from 'react';
import { Grid } from '@mui/material';
import { FormikInputWrapperProps } from './FormikInputWrapper.types';

const FormikInputWrapper = (props: FormikInputWrapperProps) => {
	const { className, children, gridProps = { xs: 12 } } = props;
	return (
		<Grid item className={className} {...gridProps}>
			{children}
		</Grid>
	);
};

export default FormikInputWrapper;
