import { commonTracking, Mutations, useMembershipContext } from '../index';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

const useUpdateUserDetails = (): ReturnType<
	typeof Mutations.useUpdateUserDetailsForUserMutation
> => {
	const [mutation, mutationData] = Mutations.useUpdateUserDetailsForUserMutation();
	const { userMembership } = useMembershipContext();
	const intl = useIntl();

	const updateUser = useCallback<typeof mutation>(
		async (options) => {
			const response = await mutation(options);
			const updatedUser = response.data?.updateUserDetailsForUser;

			if (updatedUser) {
				commonTracking.identifyUser(updatedUser.id, {
					user: updatedUser,
					userMembership,
					intl
				});
			}

			return response;
		},
		[mutation]
	);

	return [updateUser, mutationData];
};

export default useUpdateUserDetails;
