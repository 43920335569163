import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		container: {
			display: 'flex',
			alignItems: 'center'
		},
		brandName: {
			color: theme.palette.primary.dark
		},
		image: {
			height: 24,
			width: 24,
			margin: theme.spacing(0, 0.5, 0, 0)
		},
		primary: {
			color: theme.palette.primary.main
		},
		secondary: {
			color: theme.palette.secondary.main
		},
		tertiary: {
			color: theme.palette.tertiary.main
		},
		error: {
			color: theme.palette.error.main
		},
		success: {
			color: theme.palette.success.main
		},
		blue: {
			color: theme.palette.secondary.main
		},
		noColor: {
			color: 'unset'
		}
	}),
	{ name: 'BrandIcon' }
);

export default useStyles;
