import React, { useState } from 'react';
import noop from '../utils/noop';

type QueryParamsItem = string | Array<string> | null | undefined;

export type QueryParamsType = {
	[key: string]: QueryParamsItem;
};

export type QueryParamsContextType = {
	query: QueryParamsType;
	setQuery: (newQuery: QueryParamsType) => void;
};

const defaultQueryParamsContext = {
	query: {},
	setQuery: noop
};

const QueryParamsContext = React.createContext<QueryParamsContextType>(defaultQueryParamsContext);

type QueryParamsContextProviderProps = {
	children: React.ReactNode;
};

const QueryParamsContextProvider = (props: QueryParamsContextProviderProps) => {
	const { children } = props;
	const [query, setQuery] = useState({});

	return (
		<QueryParamsContext.Provider value={{ query, setQuery }}>
			{children}
		</QueryParamsContext.Provider>
	);
};

export { QueryParamsContext, QueryParamsContextProvider, defaultQueryParamsContext };
