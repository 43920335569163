import { useEffect, useRef } from 'react';

// to run an effect only on updates (not on the initial mount)
export function useDidMountEffect(effect: () => void, deps: Array<unknown>) {
	const didMount = useRef(false);

	useEffect(() => {
		if (didMount.current) {
			return effect();
		} else {
			didMount.current = true;
		}
	}, deps);
}
