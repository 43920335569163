import { Types } from '..';

export const partnershipType = [
	{
		id: Types.PartnershipType.Sponsorship,
		localeId: 'common.partner-partnership-type.sponsorship'
	},
	{
		id: Types.PartnershipType.MediaPartner,
		localeId: 'common.partner-partnership-type.media-partner'
	},
	{
		id: Types.PartnershipType.CooperationPartner,
		localeId: 'common.partner-partnership-type.cooperation-partner'
	}
];
