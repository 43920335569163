import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center'
	},
	radio: {
		marginRight: theme.spacing(1)
	},
	icon: {
		width: 48,
		height: 30,
		marginRight: theme.spacing(1),

		'&$sepa': {
			color: '#B8C3CE'
		}
	},
	sepa: {},
	title: {
		display: 'flex',
		alignItems: 'center'
	},
	number: {
		display: 'flex',
		alignItems: 'center'
	}
}));

export default useStyles;
