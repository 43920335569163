import useSWRHook from 'swr';

import fetchRequest from './fetchRequest';
import { BaseResponse, RInit } from './http.types';
import parseResponse from './parseResponse';

class HTTP {
	async useSWR(input: string, init?: RInit) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useSWRHook(input, () => this.makeFetchRequest('GET', input, init));
	}

	async get<T extends BaseResponse>(input: RequestInfo, init?: RInit) {
		return this.makeFetchRequest<T>('GET', input, init);
	}

	async post<T extends BaseResponse>(input: RequestInfo, init?: RInit) {
		return this.makeFetchRequest<T>('POST', input, init);
	}

	async put<T extends BaseResponse>(input: RequestInfo, init?: RInit) {
		return this.makeFetchRequest<T>('PUT', input, init);
	}

	async patch<T extends BaseResponse>(input: RequestInfo, init?: RInit) {
		return this.makeFetchRequest<T>('PATCH', input, init);
	}

	async delete<T extends BaseResponse>(input: RequestInfo, init?: RInit) {
		return this.makeFetchRequest<T>('DELETE', input, init);
	}

	private async makeFetchRequest<T extends BaseResponse>(
		method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
		input: RequestInfo,
		{ body: initBody, headers: initHeaders, ...init }: RInit = {}
	) {
		const isFormData = initBody instanceof FormData;

		const body = isFormData ? initBody : initBody ? JSON.stringify(initBody) : null;

		// browser should insert content-type by itself if we pass FormData
		const contentType = isFormData ? null : 'application/json';

		const headers = {
			...(contentType ? { 'Content-Type': contentType } : {}),
			...initHeaders
		};

		const defaultInit = {
			credentials: 'include' as const,
			...(body ? { body } : {})
		};

		const response = await fetchRequest(input, {
			method,
			headers,
			...defaultInit,
			...init
		});
		return parseResponse<T>(response);
	}
}

const http = new HTTP();
export default http;
