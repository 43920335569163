import type { ComponentsTypes } from 'components';
import { ReactNode } from 'react';
import { IntlFormatters } from 'react-intl';
import { Types } from '../';

const getTranslation = (
	messages: Types.Messages | undefined,
	formatMessage: IntlFormatters['formatMessage'] | undefined,
	localeId: string,
	localeValues?: Record<string, ReactNode>
): ReactNode => {
	if (messages) {
		// TODO: investigate what can be inside MessageFormatElement
		return messages[localeId] as string;
	}

	if (formatMessage) {
		const formatMessageProps = { id: localeId };
		return formatMessage(formatMessageProps, localeValues);
	}

	throw new Error(`Either "messages" or "formatMessage" should be passed into "props" argument`);
};

const formatEnum = <T extends ComponentsTypes.SelectableOption>(
	id: T['id'] | null | undefined,
	props: {
		options: Array<T>;
		messages?: Types.Messages;
		formatMessage?: IntlFormatters['formatMessage'];
		localeValues?: Record<string, ReactNode>;
	}
): string => {
	const { options, messages, formatMessage, localeValues } = props;

	if (!id) {
		return '';
	}

	const option = options.find((item) => item.id === id);
	if (!option) {
		throw new Error(
			`Missing option with id "${id}" in the options "${JSON.stringify(options)}"`
		);
	}

	const message = option.localeId
		? getTranslation(messages, formatMessage, option.localeId, localeValues)
		: option.label;

	if (!message) {
		throw new Error(`Missing translation for localeId "${option.localeId}" of id "${id}"`);
	}

	return message as string;
};

export default formatEnum;
