import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		membershipsFeaturesContainer: {
			backgroundColor: '#12385C',
			padding: theme.spacing(3),
			display: 'inline-block',
			width: '100%',
			margin: theme.spacing(1),
			...theme.border({ width: 0, radius: 16 }),
			[theme.breakpoints.up('sm')]: {
				margin: 0,
				width: 'auto'
			},
			'& h5': {
				fontSize: 16
			},
			'& h4': {
				fontSize: 29,
				fontWeight: 600
			}
		},
		bulletPoint: {
			fontWeight: 'unset'
		},
		membershipButtonContainer: {
			width: '100%',
			display: 'flex',
			justifyContent: 'end',
			marginTop: theme.spacing(2),
			'& button': {
				whiteSpace: 'nowrap'
			}
		},
		membershipButtonLink: {
			display: 'flex'
		}
	}),
	{ name: 'MembershipFeaturesBanner' }
);

export default useStyles;
