import { Color } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles/createPalette';

const palette: PaletteOptions = {
	common: {
		white: '#FFFFFF',
		black: '#000000'
	},
	primary: {
		light: '#2E8EE8',
		dark: '#0D2942',
		main: '#12385C',
		300: '#A0AFBE',
		400: '#1A5287',
		500: '#71889D',
		600: '#018AAC'
	},
	grey: {
		100: '#F5F5F5',
		200: '#F9F9F9',
		300: '#E7EBEF',
		light: '#D3D3D3',
		500: '#6E6E6E',
		600: '#545454',
		dark: '#3B3B3B',
		main: '#878787',
		700: '#080808',
		800: '#212121'
	} as Color,
	secondary: {
		100: '#E3F6FC',
		200: '#D9F6FC',
		300: '#B7EEFA',
		400: '#90DCF5',
		500: '#29BFE0',
		600: '#01AED5',
		700: '#0185A2',
		800: '#2A4C6C',
		light: '#E1FEFA',
		dark: '#12385C',
		main: '#019ABC'
	},
	pink: {
		main: '#EF9A9A'
	},
	tertiary: {
		light: '#DAD6CA',
		dark: '#85754E',
		main: '#BB9F60'
	},
	titleActive: {
		main: '#0E0E2C',
		dark: 'rgba(14, 14, 14, 0.6)', // corresponds to #0E0E2C with 60% opacity
		700: 'rgba(14, 14, 14, 0.7)', // corresponds to #0E0E2C with 70% opacity
		light: 'rgba(14, 14, 14, 0.3)' // corresponds to #0E0E2C with 30% opacity
	},
	body: {
		light: 'rgba(0, 0, 0, 0.12)',
		main: '#4A4A68',
		200: 'rgba(0, 0, 0, 0.04)',
		400: 'rgba(0, 0, 0, 0.08)',
		500: 'rgba(0, 0, 0, 0.5)',
		dark: 'rgba(0, 0, 0, 0.6)',
		700: 'rgba(0, 0, 0, 0.78)'
	},
	label: {
		main: '#8C8CA1'
	},
	accent: {
		main: '#ECF1F4'
	},
	line: {
		main: '#D9DBE9',
		100: '#0000001f'
	},
	background: {
		default: '#F5F7F9',
		paper: '#F5F7F9'
	},
	offWhite: {
		main: '#FCFCFC'
	},
	success: {
		light: '#34EAB9',
		dark: '#00966D',
		main: '#00BA88',
		500: '#689F38'
	},
	error: {
		light: '#FFD4CC',
		dark: '#B21C00',
		main: '#FF2800'
	},
	warning: {
		main: '#EFDF8A',
		dark: '#EFDF8A',
		light: '#EFDF8A'
	}
};

export default palette;
