import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from '../';

const useStyles = makeStyles(
	(theme: ThemeTypes.Theme) => ({
		root: {
			'& .iti': {
				width: '100%'
			},

			'& .iti--separate-dial-code .iti__selected-flag': {
				position: 'relative',
				background: 'none',
				paddingLeft: 24,
				paddingRight: 8,

				'&:hover': {
					background: 'none'
				},

				'&::after': {
					content: '""',
					position: 'absolute',
					top: '50%',
					right: 0,
					transform: 'translate(0, -50%)',
					width: 1,
					height: 20,
					background: theme.palette.titleActive.main
				}
			},

			'& .iti--allow-dropdown input': {
				width: 'auto'
			},

			'& .iti__flag-container': {
				top: 21
			}
		}
	}),
	{ name: 'PhoneInput' }
);

export default useStyles;
