import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		padding: theme.spacing(1),
		gap: theme.spacing(1.5),
		textDecoration: 'none',
		transition: theme.transitions.create('background-color'),

		[theme.breakpoints.up('sm')]: {
			gap: theme.spacing(2)
		},

		'&:hover': {
			backgroundColor: theme.palette.body[200]
		}
	},
	thumbnailContainer: {
		position: 'relative',
		width: 80,
		height: 45,
		backgroundColor: '#F0F2F5',
		borderRadius: 4,
		overflow: 'hidden',

		[theme.breakpoints.up('sm')]: {
			width: 140,
			height: 80
		}
	},
	thumbnailProgress: {
		position: 'absolute',
		left: 0,
		bottom: 0,
		height: 2,
		backgroundColor: theme.palette.secondary.main
	},
	thumbnailDuration: {
		display: 'none',
		position: 'absolute',
		right: 10,
		bottom: 8,
		padding: '2px 4px',
		color: theme.palette.common.white,
		background: theme.palette.body[700],
		borderRadius: 3,
		[theme.breakpoints.up('sm')]: {
			display: 'block'
		}
	},
	notification: {
		marginTop: theme.spacing(0.5)
	}
}));

export default useStyles;
