import { Grid } from '@mui/material';
import { Button, FormikCountriesSelect, FormikTextField } from 'components';
import React from 'react';
import { useIntl } from 'react-intl';
import { AddressFormProps } from './AddressForm.types';

const AddressForm = (props: AddressFormProps) => {
	const { addressFormik, onClose, addressId, className, 'data-test': dataTest } = props;
	const { locale } = useIntl();
	return (
		<Grid container spacing={2} className={className}>
			{addressId && <input type="hidden" value={addressId} name="id" />}
			<FormikTextField
				name="fullName"
				formik={addressFormik}
				gridProps={{ xs: 12 }}
				data-test={`${dataTest}_full-name`}
			/>
			<FormikTextField
				name="company"
				formik={addressFormik}
				gridProps={{ xs: 12 }}
				data-test={`${dataTest}_company`}
			/>
			<FormikTextField
				name="additionalInformation"
				formik={addressFormik}
				gridProps={{ xs: 12 }}
				data-test={`${dataTest}_additional-information`}
			/>
			<FormikTextField name="street" formik={addressFormik} gridProps={{ xs: 12, sm: 8 }} />
			<FormikTextField
				name="apartmentNumber"
				formik={addressFormik}
				gridProps={{ xs: 12, sm: 4 }}
			/>
			<FormikTextField
				name="postalCode"
				formik={addressFormik}
				gridProps={{ xs: 12, sm: 6 }}
			/>
			<FormikTextField name="city" formik={addressFormik} gridProps={{ xs: 12, sm: 6 }} />
			<FormikCountriesSelect name="country" formik={addressFormik} locale={locale} />

			<Grid item xs={12} md={6}>
				<Button localeId="common.save" type="submit" fullWidth />
			</Grid>

			<Grid item xs={12} md={6}>
				<Button localeId="common.discard" onClick={onClose} variant="contained" fullWidth />
			</Grid>
		</Grid>
	);
};

export default AddressForm;
