import React, { useState } from 'react';
import * as yup from 'yup';
import { EditMedicalInfoProps } from './EditMedicalInfo.types';
import {
	getLanguageFromLocale,
	UserValidation,
	UserOptions,
	UserTransformers,
	Queries,
	Types,
	useExecMutation,
	getSpecialitiesByLocale,
	useRouter,
	formatMedicalIdNameByCountry,
	useUpdateUserDetails,
	Fragments
} from '../..';
import { Box, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {
	FormikAutocomplete,
	Button,
	FormikSelect,
	FormikSwitch,
	FormikTextField,
	UploadButton,
	Typography,
	Icon,
	SchemaValues,
	useCustomFormik,
	FormikCountriesSelect
} from 'components';
import useStyles from './EditMedicalInfo.styles';
import classnames from 'classnames';

const getEditMedicalValidationSchema = () =>
	yup.object({
		...UserValidation.medicalIdentificationNumber(),
		...UserValidation.fieldOfActivity(),
		...UserValidation.specialities(),
		...UserValidation.occupationType()
	});

type EditMedicalFormikValues = SchemaValues<typeof getEditMedicalValidationSchema>;

const fillUserForFormik = (
	user: Fragments.UserFieldsFragment,
	shortLocale: string
): EditMedicalFormikValues => {
	return {
		email: user.email,
		country: user.country || undefined,
		occupationType: user.occupationType || undefined,
		medicalIdentificationNumber: user.medicalIdentificationNumber || undefined,
		healthProfessionalConfirmation: user.healthProfessionalConfirmation || false,
		fieldOfActivity: user.fieldOfActivity || undefined,
		specialities: UserTransformers.specialities(user.specialities, shortLocale)
	};
};

const EditMedicalInfo = (props: EditMedicalInfoProps) => {
	const { user } = props;
	const classes = useStyles(props);
	const { locale } = useRouter();
	const shortLocale = getLanguageFromLocale(locale);
	const execMutation = useExecMutation();
	const [updateUser, { loading }] = useUpdateUserDetails();
	const { data } = Queries.useGetSpecialitiesQuery();
	const [files, setFiles] = useState<Array<File>>([]);

	const allSpecialities = data?.specialities || [];

	const formik = useCustomFormik({
		initialValues: fillUserForFormik(user, shortLocale),
		validationSchema: getEditMedicalValidationSchema(),
		onSubmit: async (values) => {
			const { specialities, ...rest } = values;
			const updateUserRequest = {
				...rest,
				specialityCodes: UserTransformers.specialityCodes(specialities, (item) => item.id)
			};

			const optimisticResponse = {
				...user,
				...updateUserRequest,
				__typename: 'UserR',
				id: user.id,
				specialities: UserTransformers.specialitiesFromSpecialtyCodes(
					updateUserRequest.specialityCodes,
					allSpecialities
				),
				specialityCodes: undefined
			} as const;

			await execMutation(
				updateUser,
				{
					variables: {
						userId: user.id,
						updateUserRequest
					},
					optimisticResponse: {
						updateUserDetailsForUser: optimisticResponse
					}
				},
				{ messageLocaleId: 'common.complete-profile.success-message' }
			);
		}
	});

	// TODO: remove `false` when have the functionality done
	const showCertificateSection =
		false && user.occupationType === Types.OccupationType.AssistantDoctor;

	const [hasMedicalId, setHasMedicalId] = useState(!formik.values.healthProfessionalConfirmation);

	const specialitiesOptions = getSpecialitiesByLocale(allSpecialities, shortLocale);

	const handleToggleHasMedicalId = (event: React.FormEvent) => {
		event.preventDefault();
		setHasMedicalId(!hasMedicalId);
		if (hasMedicalId) {
			formik.setFieldValue('medicalIdentificationNumber', '');
			formik.setFieldError('medicalIdentificationNumber', undefined);
		}
		formik.setFieldValue(
			'healthProfessionalConfirmation',
			!formik.values.healthProfessionalConfirmation
		);
	};

	const countryHasMedicalNumber = UserValidation.countryHasMedicalNumber(formik.values.country);

	return (
		<form noValidate onSubmit={formik.handleSubmit}>
			<Grid container spacing={9}>
				<Grid item xs={12} lg={6}>
					<Typography
						localeId="common.dashboard.profile.medical-profile"
						variant="displayXSmallBold"
						color="body"
						className={classes.spacing}
					/>
					<Grid container spacing={2}>
						<FormikSelect
							name="occupationType"
							formik={formik}
							options={UserOptions.occupationType}
							gridProps={{ xs: 12 }}
						/>

						<FormikSelect
							name="fieldOfActivity"
							formik={formik}
							options={UserOptions.fieldOfActivity}
							gridProps={{ xs: 12 }}
						/>

						<FormikCountriesSelect
							name="country"
							formik={formik}
							locale={locale as Locale}
						/>

						{countryHasMedicalNumber && (
							<FormikTextField
								name="medicalIdentificationNumber"
								label={formatMedicalIdNameByCountry(formik.values.country, locale)}
								formik={formik}
								gridProps={{ xs: 12 }}
								disabled={formik.values.healthProfessionalConfirmation}
							/>
						)}

						<FormikSwitch
							name="healthProfessionalConfirmation"
							formik={formik}
							gridProps={{ xs: 12 }}
							label={
								<>
									<FormattedMessage id="common.profile.without-medical-identification-number" />
									<FormattedMessage
										id="common.profile.health-professional-confirmation"
										tagName="div"
									/>
								</>
							}
							onClick={handleToggleHasMedicalId}
						/>
					</Grid>
				</Grid>

				<Grid item xs={12} lg={6}>
					<Typography
						localeId="common.specialities"
						variant="displayXSmallBold"
						color="primary"
						className={classes.spacing}
					/>
					<Grid container spacing={2}>
						<FormikAutocomplete
							name="specialities"
							options={specialitiesOptions}
							formik={formik}
							localeId="common.speciality"
						/>
					</Grid>
				</Grid>

				<Grid item xs={12} lg={4} />
				<Grid item xs={12} lg={4}>
					<Button
						type="submit"
						disabled={loading}
						localeId="common.save-changes"
						fullWidth
						size="large"
					/>
				</Grid>
				<Grid item xs={12} lg={4} />

				{showCertificateSection && (
					<Grid item xs={12}>
						<Box className={classnames(classes.certificateTitle, classes.spacing)}>
							<Typography
								localeId="common.profile.certificate-title"
								variant="displayXSmallBold"
								color="primary"
							/>
							<Box className={classes.certificateMissingContainer}>
								<Icon
									icon="exclamation"
									strokeWidth={1.5}
									className={classes.certificateMissingIcon}
								/>
								<Typography
									localeId="common.profile.certificate-is-missing"
									variant="textXSmall"
									className={classes.certificateMissingTitle}
								/>
							</Box>
						</Box>
						<Box className={classes.spacing}>
							<Typography
								localeId="common.profile.certificate-subtitle"
								variant="textMedium"
								color="body"
							/>
						</Box>
						<UploadButton onChange={setFiles} accept={'image/*'} />

						{files.map((file) => (
							<Box key={file.name}>{file.name}</Box>
						))}
					</Grid>
				)}
			</Grid>
		</form>
	);
};

export default EditMedicalInfo;
