import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { Box } from '@mui/material';

import { UploadButtonProps } from './UploadButton.types';
import useStyles from './UploadButton.styles';
import { Button, Icon, Typography } from '..';

const UploadButton = (props: UploadButtonProps) => {
	const {
		onChange,
		accept,
		single,
		className,
		labelLocaleId = 'common.upload-files.button-label',
		descriptionLocaleId = 'common.upload-files.description',
		showFileName,
		disabled
	} = props;
	const classes = useStyles(props);

	const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
		useDropzone({ accept, maxFiles: single ? 1 : undefined });

	useEffect(() => {
		onChange(acceptedFiles);
	}, [acceptedFiles, onChange]);

	const containerClassName = classNames(
		classes.container,
		isDragActive && classes.active,
		isDragAccept && classes.accept,
		isDragReject && classes.reject,
		disabled && classes.disabled,
		className
	);

	return (
		<Box {...getRootProps({ className: containerClassName })}>
			<input {...getInputProps()} />
			<Icon icon="arrowUp" className={classes.icon} />
			<Button localeId={labelLocaleId} className={classes.button} variant="contained" />
			<Typography localeId={descriptionLocaleId} color="label" variant="textXSmall" />
			{showFileName && (
				<Typography
					className={classes.fileName}
					title={acceptedFiles.map((each) => each.name).join(', ')}
					color="label"
					variant="textXSmall"
				/>
			)}
		</Box>
	);
};

export default UploadButton;
