import React from 'react';
import env from '../../env';

const GTMBody = () => {
	const GTM_ID = env.GTM_ID;
	return (
		<noscript>
			<iframe
				src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
				height="0"
				width="0"
				style={{ display: 'none', visibility: 'hidden' }}
			/>
		</noscript>
	);
};

export default GTMBody;
