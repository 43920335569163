import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	root: {
		...theme.border({ radius: 12 }),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: 170,
		boxShadow: 'box-shadow: 0px 4px 12px 0px #0000000A',
		padding: theme.spacing(2, 3),
		userSelect: 'none'
	},
	clickable: {
		cursor: 'pointer'
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	body: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	footer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	radio: {
		padding: 0
	},
	brand: {
		textTransform: 'capitalize'
	},
	icon: {
		width: 48,
		height: 32,

		'&$sepa': {
			color: '#B8C3CE'
		}
	},
	sepa: {}
}));

export default useStyles;
