import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
	(theme) => ({
		container: {
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'flex-start',
			flexDirection: 'row',
			width: '100%',
			textAlign: 'start',
			borderRadius: 0,
			padding: theme.spacing(2)
		},
		selectedItem: {
			backgroundColor: 'rgba(222, 249, 247, 50%)',
			borderRadius: 6,
			'& > title': {
				fontWeight: 600
			}
		},
		title: {
			...theme.typography.body2,
			color: theme.palette.primary.main
		},
		disabledItem: {
			cursor: 'wait'
		},
		checkboxContainer: {
			marginTop: theme.spacing(0.5),
			marginRight: theme.spacing(2)
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			gap: theme.spacing(1)
		},
		icon: {
			width: 19,
			height: 19,
			color: theme.palette.primary[500]
		},
		checkedIcon: {
			width: 20,
			height: 20,
			color: theme.palette.success[500]
		},
		speakerName: {
			color: theme.palette.label.main,
			...theme.typography['caption'],
			fontWeight: 400
		}
	}),
	{ name: 'ContentPlaylistSyrotItem' }
);

export default useStyles;
