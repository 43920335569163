import React from 'react';
import { BadgeProps } from './Badge.types';
import useStyles from './Badge.styles';
import { Box } from '@mui/material';
import classnames from 'classnames';
import { Typography } from '../index';

const Badge = (props: BadgeProps) => {
	const { title, titleLocaleId, kind, className } = props;
	const classes = useStyles(props);
	return (
		<Box className={classnames(classes.root, classes[kind], className)}>
			<Typography
				title={title}
				localeId={titleLocaleId}
				className={classes.title}
				variant="linkXSmall"
			/>
		</Box>
	);
};

export default Badge;
