import { useEffect } from 'react';
import { loadAnalyticsWithConsent, setGoogleAnalyticsConsent } from './loadAnalyticsWithConsent';
import { useIntl } from 'react-intl';
import { useUserContext } from '../contexts/UserContext';

function useLoadAnalytics() {
	const { locale } = useIntl();
	const { isLoggedIn, isLoading, user } = useUserContext();

	useEffect(() => {
		// set default consent settings if they are not existing yet
		if (
			!window.dataLayer ||
			!window.dataLayer.find((item) => item[0] === 'consent' && item[1] === 'default')
		) {
			setGoogleAnalyticsConsent('default', {
				ad_storage: 'denied',
				ad_user_data: 'denied',
				ad_personalization: 'denied',
				analytics_storage: 'denied'
			});
		}
	}, []);

	useEffect(() => {
		if (isLoading) {
			return;
		}
		loadAnalyticsWithConsent({ locale, isLoggedIn, email: user?.email });
	}, [isLoggedIn, isLoading]);
}

export default useLoadAnalytics;
