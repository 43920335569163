import { SchemaValues } from 'components';
import {
	booleanFilter,
	Fragments,
	getLanguageFromLocale,
	getSpecialitiesByLocale,
	noop,
	Types,
	UserTransformers,
	UserValidation
} from '..';

export const specialities = (
	specialities: Array<Types.SpecialityR | null>,
	locale: string
): Array<{ id: string; label: string | undefined }> => {
	const strictSpecialities = specialities ? specialities.filter(booleanFilter) : [];

	const specialitiesOptions = getSpecialitiesByLocale(strictSpecialities, locale);

	return specialitiesOptions;
};

export const specialityCodes = <T>(
	specialities: Array<T>,
	iteratee: (item: T) => string | null | undefined
): Array<string> => specialities.map(iteratee).filter(booleanFilter);

export const specialitiesFromSpecialtyCodes = (
	codes: Array<string>,
	specialitiesDictionary: Array<Types.SpecialityR | null>
): Array<Types.SpecialityR> => {
	return codes
		.map((code) => specialitiesDictionary.find((speciality) => speciality?.code === code))
		.filter(booleanFilter);
};

export const address = (
	address: Types.AddressR | null | undefined,
	fullNameProp: string,
	isPersonalProp?: boolean
): Types.AddressFormik => {
	const isPersonal =
		typeof isPersonalProp === 'boolean' ? isPersonalProp : address?.isPersonal || false;
	const fullName = isPersonal ? null : address?.fullName || fullNameProp;
	const company = isPersonal ? null : address?.company || null;
	return {
		id: address?.id || undefined,
		city: address?.city || null,
		country: address?.country || null,
		apartmentNumber: address?.apartmentNumber || null,
		isPersonal,
		postalCode: address?.postalCode || null,
		street: address?.street || null,
		fullName,
		company,
		additionalInformation: address?.additionalInformation || null,
		toDelete: undefined
	};
};

export const transformUserForFormik = (
	user: Fragments.UserFieldsFragment,
	locale: Locale
): SchemaValues<typeof UserValidation.getCompleteProfileValidationSchema> => {
	const shortLocale = getLanguageFromLocale(locale);
	const {
		firstName,
		lastName,
		gender,
		academicDegree,
		country,
		mobile,
		occupationType,
		fieldOfActivity,
		specialities,
		medicalIdentificationNumber,
		healthProfessionalConfirmation,
		email
	} = user;
	return {
		email,
		firstName: firstName || undefined,
		lastName: lastName || undefined,
		gender: gender || undefined,
		academicDegreeCode: academicDegree?.code || undefined,
		country: country || undefined,
		mobile: {
			value: mobile || undefined,
			validate: noop,
			setCountry: undefined
		},
		occupationType: occupationType || undefined,
		fieldOfActivity: fieldOfActivity || undefined,
		specialities: UserTransformers.specialities(specialities, shortLocale),
		medicalIdentificationNumber: medicalIdentificationNumber || undefined,
		healthProfessionalConfirmation: healthProfessionalConfirmation || undefined
	};
};
