import React, { ReactNode } from 'react';
import { FormatXMLElementFn } from 'intl-messageformat/src/formatters';
import { IntlConfig } from 'react-intl';
export type ValueFunctionPropsType = [parts: Array<ReactNode>];

function makeBold(...chunks: ValueFunctionPropsType) {
	return `<b>${chunks}</b>`;
}

export type RichTextElementFn = FormatXMLElementFn<ReactNode>;

const defaultRichTextElements: Record<string, RichTextElementFn> = {
	strong: makeBold,
	b: makeBold,
	br: () => <br />
};

const onWarn: IntlConfig['onWarn'] = (w) => {
	// we are ignoring this log because we are not using `defaultMessage->extract->compile` flow
	if (
		w.includes(
			'[@formatjs/intl] "defaultRichTextElements" was specified but "message" was not pre-compiled.'
		)
	) {
		return;
	}

	// eslint-disable-next-line no-console
	console.log(w);
};

export const defaultIntlProviderProps: Partial<IntlConfig> = {
	defaultRichTextElements,
	onWarn
};
