import { useAlert } from '../contexts';
import { Dispatch } from 'react';
import { http } from '../http';
import { getMessageParamsFromError } from '../content/server-errors';
import { ApolloError } from '@apollo/client';
import { useIntl } from 'react-intl';
import { RInit } from '../http/http.types';

const useDownloadFile = () => {
	const { openAlert } = useAlert();
	const { locale } = useIntl();

	const downloadFile = async (
		url: string,
		fileName: string,
		onActionInProgressChange?: Dispatch<boolean>,
		init?: RInit
	) => {
		if (onActionInProgressChange) {
			onActionInProgressChange(true);
		}
		try {
			const { fetchResponse } = await http.get(url, init);

			const responseBlob = await fetchResponse.blob();

			const file = window.URL.createObjectURL(responseBlob);
			const a = document.createElement('a');
			a.href = file;
			a.download = fileName;
			document.body.appendChild(a); // needed for firefox
			a.click();
			a.remove();
		} catch (error) {
			openAlert({
				variant: 'error',
				...getMessageParamsFromError(error as ApolloError, locale)
			});
		} finally {
			if (onActionInProgressChange) {
				onActionInProgressChange(false);
			}
		}
	};

	return downloadFile;
};

export default useDownloadFile;
