/**
 * This function is useful to get the language given a locale. For example, for "en-GB" it
 * returns "en".
 */
import { DOMAIN_TO_LOCALE, LOCALE_TO_DOMAIN } from '../constants';

export const getLanguageFromLocale = (locale?: string) => locale?.substring(0, 2) || 'de';

export const getLocaleFromDomain = (domain: Domain): Locale => {
	return DOMAIN_TO_LOCALE[domain];
};

export const getDomainFromLocale = (locale: Locale): Domain => {
	return LOCALE_TO_DOMAIN[locale];
};
