import { Fragments } from '../..';

export const userMock: Fragments.UserFieldsFragment = {
	__typename: 'UserR',
	academicDegree: {
		code: 'BSCN',
		description: [
			{
				locale: 'en-GB',
				value: 'BScN',
				isSuffix: true
			},
			{
				locale: 'de-DE',
				value: 'BScN',
				isSuffix: true
			},
			{
				locale: 'de-AT',
				value: 'BScN',
				isSuffix: true
			},
			{
				locale: 'de-CH',
				value: 'BScN',
				isSuffix: true
			}
		],
		gender: null,
		id: '9ec13bd6-5a1f-42f6-a01a-81d8e070fbfc'
	},
	id: '9307b4a4-0d3a-4677-9549-2997026b5681',
	ubAccountCode: '00101482',
	email: 'v.shapoval@fomf.org',
	firstName: 'Viktor',
	gender: 'MALE',
	lastName: 'Shapoval',
	country: 'DEU',
	healthProfessionalConfirmation: false,
	mobile: '+4915123454678',
	medicalIdentificationNumber: '111111111111113',
	newsletterSubscription: false,
	fieldOfActivity: 'CLINIC_DOCTOR',
	occupationType: 'DOCTOR',
	lastLogin: '2023-08-08T11:42:08.246427Z',
	roles: ['USER'],
	totalPointCertified: [],
	totalMembershipPointsEffectedCertified: [],
	specialities: [
		{
			id: '4741fe9d-15d2-4415-a003-479c790747d4',
			code: 'AKI',
			description: {
				translations: [
					{
						locale: 'de-DE',
						value: 'Allergologie und klinische Immunologie'
					},
					{
						locale: 'de-AT',
						value: 'Allergologie und klinische Immunologie'
					},
					{
						locale: 'fr-CH',
						value: 'Allergologie et immunologie clinique '
					},
					{
						locale: 'en-GB',
						value: 'Allergy'
					}
				]
			}
		}
	],
	addresses: [
		{
			__typename: 'AddressR',
			apartmentNumber: '12',
			city: 'City2',
			country: 'DEU',
			id: 'fe800946-e2fb-4ddb-903f-674a9b37dbff',
			postalCode: '12334',
			street: 'street 1',
			isPersonal: true,
			fullName: null,
			company: null,
			additionalInformation: 'PersonalAddressss'
		}
	],
	isActivated: true,
	hasValidActivationToken: false,
	dataSharingConsent: false,
	accountType: 'PHA',
	company: {
		id: 'f0e0c1e7-3e0e-4e7c-8f3d-3a5e3b2b0c9f',
		name: 'FOMF',
		companyPage: {
			slug: 'fomf'
		},
		logoUrl: 'https://fomf.com/logo.png'
	},
	privacyPolicyConfirmation: true
};
