import { createTheme } from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import border from './border';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import spacing from './spacing';
import breakpoints from './breakpoints';

declare module '@mui/material/styles/overrides' {
	export interface ComponentNameToClassKey {
		MuiPickersToolbarText: Partial<ClassNameMap<'toolbarBtnSelected' | 'toolbarTxt'>>;
		MuiPickersYearSelection: Partial<ClassNameMap>;
		PrivateNotchedOutline: Partial<ClassNameMap<'legendNotched'>>;
	}
}
declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		secondary: true;
	}
}

const theme = {
	...createTheme({
		palette,
		spacing,
		typography,
		components: {
			...overrides
		},
		breakpoints
	}),
	border,
	headerHeight: {
		lg: 137,
		sm: 80
	},
	footer: {
		height: 316,
		marginTop: spacing(12)
	}
};

export default theme;
